import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../../../App.context';
import PrivateRoute from './PrivateRoute';

export default function ProtectedRoute({ children, ...rest }) {
  const { appState } = useContext(AppContext);
  return (
    <Route
      {...rest}
      render={() =>
        appState.role == 'user' || appState.role == 'guest' ? (
          <Redirect to="/cross-sell/cross-sells" />
        ) : (
          <PrivateRoute {...rest}>
            {children}
          </PrivateRoute>
        )
      }
    />
  );
  // return <Route {...rest} render={() => children} />;
}

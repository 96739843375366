import axios from 'axios';
import apiConfig from '../api.config';

export default function deleteUserRequest({ userId }) {
  const {
    host,
    URIs: { deleteUser },
  } = apiConfig;

  const uri = `${host}${deleteUser}`.replace(':userId', userId);

  return axios
    .delete(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}

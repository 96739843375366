import React, { useContext } from 'react';
import useValidateLoginHook from './ValidateLogin.hook';
import TwoFactorInput from './components/TwoFactorInput/TwoFactorInput';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import Loading from 'Components/common/Loader/Loading';

import './ValidateLogin.css';

export default function ValidateLogin() {
  const { state, actions } = useValidateLoginHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <WelcomeLayout title={translate('validateLogin.header')}>
      <div className="validate-login-description">{translate('validateLogin.description')}</div>
      {state.loading ? (
        <div className="loader-container">
          <Loading />
        </div>
      ) : (
        <>
          <TwoFactorInput onValidCode={actions.onCodeChange} />
          <WelcomeButton
            style={{ backgroundColor: Colors.blue, margin: '1.5rem 1rem' }}
            text={translate('validateLogin.button')}
            disabled={true}
            onClick={() => {
              actions.validateLogin({ code: state.code });
            }}
          />
        </>
      )}
    </WelcomeLayout>
  );
}

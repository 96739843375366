import axios from 'axios';
import apiConfig from '../api.config';

export default function updateUserRequest({ id, ownerId, name, email, hotel, role }) {
  const {
    host,
    URIs: { updateUser },
  } = apiConfig;

  const uri = `${host}${updateUser}/${id}`;

  return axios
    .put(uri, JSON.stringify({ ownerId, name, email, hotel, role }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}

import { useEffect, useState } from 'react';
import * as Translations from './modules/localization/Translations';
import constants from './utils/constants';
import storage from './utils/storage';
import history from './Routes/history';
import SupportedLanguages from './modules/localization/SupportedLanguages';
import * as GtmUtils from './utils/GtmUtils';
import allHotelsRequest from 'utils/networking/api/allHotels.service';
import LayoutScreens from 'Components/DefaultLayout/LayoutScreens';
import getPaymentMethodsRequest from 'utils/networking/api/getPaymentMethods.service';
import AppStorage from 'utils/AppStorage';

const useAppHook = () => {
  const [appState, setState] = useState(initialState());

  useEffect(() => {
    appActions.initGTM();
    loadResourcesAsync();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    switch (window.location.pathname) {
      case `/${constants.BOOKINGDOTCOM.REDIRECT_PATH}`:
        history.push({
          pathname: '/channels',
          search: `?callback=${constants.BOOKINGDOTCOM.REDIRECT_PATH}&code=${params.get(
            'code'
          )}&hotelId=${params.get('hotelId')}`,
        });
        break;
      case `/${constants.AUTH.VALIDATE_USER_PATH}`:
        history.push({
          pathname: '/login',
          search: `?callback=${constants.AUTH.VALIDATE_USER_PATH}&user_id=${params.get(
            'user_id'
          )}&token=${params.get('token')}`,
        });
        break;
      default:
        break;
    }
  }, [appState.resourcesLoaded]);

  useEffect(() => {
    if (appState.sessionTimeLeft === 0) {
      return;
    }

    const timer = setInterval(() => {
      updateState({ sessionTimeLeft: Math.max(appState.sessionTimeLeft - 1, 0) });
    }, 60000); 

    return () => clearInterval(timer);
  }, [appState.sessionTimeLeft]);

  const appActions = {
    saveAuthToken: ({ email, accessToken, superUser, userId, ownerId, hasSeenQuickguide, userType, role }) => {
      updateState({
        email,
        accessToken,
        superUser,
        userId,
        ownerId,
        showQuickguide: false,
        userType,
        role,
        sessionTimeLeft: constants.MAX_INACTIVITY_MINUTES,
        inactivityMessageClosed: false
      });
      storage.add(constants.AUTH.USER_KEY, { email, accessToken, superUser, userId, ownerId, userType, role });
      AppStorage.removeItem(AppStorage.KEYS.SELECTED_HOTEL);
    },

    logout: () => {
      updateState({
        email: null,
        accessToken: null,
        superUser: false,
        userId: null,
        selectedHotel: null,
        hotelList: null,
      });
      storage.remove(constants.AUTH.USER_KEY);
      AppStorage.removeItem(AppStorage.KEYS.SELECTED_HOTEL);
      history.push('/login');
    },

    refreshSessionTimeLeft: () => {
      updateState({ sessionTimeLeft: constants.MAX_INACTIVITY_MINUTES, inactivityMessageClosed: false });
    },

    closeInactivityMessage: () => {
      updateState({ inactivityMessageClosed: true });
    },

    setExportingInProgress: exporting => {
      updateState({ exporting });
    },

    translate: key => {
      return Translations.translate(appState.literals, key);
    },

    getSelectedLanguage: () => {
      return appState.currentLanguage;
    },

    setSelectedLanguage: language => {
      const selectedLanguage = SupportedLanguages.find(lang => lang.id === language);
      updateState({ literals: selectedLanguage.translations, currentLanguage: selectedLanguage });
    },

    setHotelList: hoteList => {
      updateState({ hotelList: hoteList });
    },

    setShowQuickguide: (show, hideSkip = false) => {
      updateState({ showQuickguide: show, hideQuickguideSkip: hideSkip });
    },

    setBreadCrumb: (route, subMenu) => {
      const text = `${appActions.translate(`layout.${route.name}`)} / ${appActions.translate(
        `layout.${subMenu.name}`
      )}`;

      const showAllHotels =
        subMenu?.name === 'stats' ||
        subMenu?.name === 'pms' ||
        subMenu?.name === 'sales' ||
        subMenu?.name === 'checkin' ||
        subMenu?.name === 'booking';

      updateState({ breadCrumb: text, currentScreen: subMenu, showAllHotels });
    },

    initGTM: () => {
      if (process.env.REACT_APP_ENV == 'production') {
        if (!appState.isAnalyticsSetup) {
          GtmUtils.initialize();
          updateState({ isAnalyticsSetup: true });
        }
      }
    },

    superUserChangeChain: () => {
      updateState({ selectedHotel: null, hotelList: null });
      history.push({
        pathname: '/superuser',
        state: { token: appState.token, userId: appState.userId, ownerId: appState.ownerId },
      });
    },

    loadAllHotels: () => {
      allHotelsRequest()
        .then(res => {
          appActions.setHotelList(res);
        })
        .catch(err => {
          console.warn(err);
        });
    },

    getFirstHotel: () => {
      if (appState?.hotelList && appState?.hotelList.length) {
        const firstHotel = {
          key: appState?.hotelList[0]?.id,
          name: appState?.hotelList[0]?.name,
        };

        if (firstHotel.key) {
          return firstHotel;
        } else {
          return null;
        }
      }
    },

    setChain: chain => {
      updateState({ chain });
    },

    setSelectedHotel: hotel => {
      updateState({ selectedHotel: hotel });
      AppStorage.saveItem(AppStorage.KEYS.SELECTED_HOTEL, hotel);
    },

    getSelectedHotel: () => {
      if (appState.selectedHotel && appState.selectedHotel?.key) {
        return appState.selectedHotel;
      } else {
        return AppStorage.getItem(AppStorage.KEYS.SELECTED_HOTEL);
      }
    },

    setQuickguideImages: images => {
      updateState({ quickguideImages: images });
    },

    getPaymentMethods: userId => {
      getPaymentMethodsRequest(userId)
        .then(res => updateState({ paymentMethods: res.data }))
        .catch(error => console.error(error));
    },

    setPoliceReportFilters: filters => {
      updateState({ policeReportFilters: filters });
    },

    setHotelLanguages: languages => {
      updateState({ hotelLanguages: languages })
    },

    isUserAdmin: () => {
      const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
      return storedCredentials.userType === 'admin' ? true : false;
    },
  };

  async function loadResourcesAsync() {
    updateState({ resourcesLoaded: true });
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { appState, appActions };
};

function initialState() {
  const storedCredentials = storage.get(constants.AUTH.USER_KEY);
  let initialState = {
    breadCrumb: null,
    literals: SupportedLanguages[0].translations,
    currentLanguage: SupportedLanguages[0],
    chain: null,
    selectedHotel: null,
    showQuickguide: false,
    currentScreen: LayoutScreens[0].children[0],
    quickguideImages: null,
    hideQuickguideSkip: false,
    policeReportFilters: null,
    role: null,
    sessionTimeLeft: constants.MAX_INACTIVITY_MINUTES,
    inactivityMessageClosed: false,
  };
  if (storedCredentials) {
    const { email, accessToken, superUser, userId, ownerId, userType, role } = JSON.parse(storedCredentials);
    initialState = {
      ...initialState,
      email: email,
      accessToken: accessToken,
      superUser: superUser,
      userId: userId,
      ownerId: ownerId,
      userType: userType,
      role: role,
    };
  }
  return initialState;
}

export default useAppHook;

import axios from 'axios';
import apiConfig from '../api.config';

export default function registerDashboardAction(action, subaction, data) {
  const {
    host,
    URIs: { registerDashboardAction },
  } = apiConfig;

  const uri = `${host}${registerDashboardAction}`;

  return axios
    .put(uri, {
        data: data,
        action: action,
        subaction: subaction,
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(res => {
      return res.data;
    });
}

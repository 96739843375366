import React, { useContext } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import moment from 'moment';
import constants from 'utils/constants';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import TableActions from 'Sell/Components/TableActions';
import useUpsellListHook from './UpsellList.hook';
import styles from './UpsellList.styles';
import { AppContext } from 'App.context';
import FilterComponent from 'Sell/Components/FilterComponent/FilterComponent';
import RangePicker from 'Sell/Components/RangePicker';

export default function RoomTypeTable() {
  const { dataSource, dates, isLoading, actions } = useUpsellListHook();
  const getSelectors = elementStatus => STATUS_MAPPING[elementStatus] || [];

  const {
    appState,
    appActions: { translate, getSelectedLanguage },
  } = useContext(AppContext);

  const STATUS_MAPPING = {
    pending: [
      {
        label: <span style={styles.toApproved}>{translate('upsell.changeToApproved')}</span>,
        key: 'status_approved',
        icon: <CheckOutlined style={styles.toApproved} />,
      },
      {
        label: <span style={styles.toRejected}>{translate('upsell.changeToRejected')}</span>,
        key: 'status_rejected',
        icon: <CloseOutlined style={styles.toRejected} />,
      },
    ],
    approved: [
      {
        label: <span style={styles.toRejected}>{translate('upsell.changeToRejected')}</span>,
        key: 'status_rejected',
        icon: <CloseOutlined style={styles.toRejected} />,
      },
      ,
    ],
    rejected: [
      {
        label: <span style={styles.toApproved}>{translate('upsell.changeToApproved')}</span>,
        key: 'status_approved',
        icon: <CheckOutlined style={styles.toApproved} />,
      },
    ],
  };

  const columns = [
    {
      title: translate('upsell.dealId'),
      dataIndex: ['id'],
      key: 'id',
      searchable: false,
      sorter: false,
      render: id => <div style={styles.id}>{id}</div>,
    },
    {
      title: translate('upsell.status'),
      dataIndex: ['status'],
      key: 'status',
      searchable: false,
      sorter: false,
      render: status => <div style={styles.status(status)}>{translate(`upsell.${status}`)}</div>,
    },
    {
      title: translate('upsell.hotelName'),
      dataIndex: ['hotel', 'name'],
      key: 'hotel.name',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('upsell.locator'),
      dataIndex: ['booking', 'pmsIdentifier'],
      key: ['booking', 'pmsIdentifier'],
      searchable: false,
      sorter: false,
    },
    {
      title: translate('upsell.guestName'),
      dataIndex: ['booking', 'guest'],
      key: ['booking', 'guest'],
      searchable: false,
      sorter: false,
    },
    {
      title: translate('upsell.paymentStatus'),
      dataIndex: ['booking'],
      key: ['booking'],
      render: ({bookingCharges, purchases}) => {
        const state = bookingCharges?.[0] != null && bookingCharges?.[0]?.type == 'pms' && purchases?.[0] != null;
        const label = state ? `upsell.approved` : `upsell.pending`;
        return <div style={styles.status(state)}>{translate(label)}</div>;
      },

      searchable: false,
      sorter: false,
    },
    {
      title: translate('upsell.transactionCode'),
      dataIndex: ['booking'],
      key: ['booking'],
      render: ({bookingCharges, purchases}) => {
        const state = bookingCharges?.[0] != null && bookingCharges?.[0]?.type == 'pms' && purchases?.[0] != null;
        if (state) return purchases?.[0]?.token; 
        else return null
      },
      searchable: false,
      sorter: false,
    },
    {
      title: translate('upsell.fromRoom'),
      dataIndex: ['PhysicalRoomUpsellToOption'],
      key: ['PhysicalRoomUpsellToOption.from'],
      searchable: false,
      sorter: false,
      render: physicalRoomUpsellToOption => {
        const room = physicalRoomUpsellToOption?.fromPhysicalRoom;
        let text = room?.texts?.find(t => t.type == 'name' && t.lang == getSelectedLanguage().id);
        if (!text) {
          text = room?.texts?.find(t => t.type == 'name' && t.lang == 'en');
        } else if (!text) {
          text = room?.texts?.find(t => t.type == 'name');
        }
        return <div>{text?.value || '-'}</div>;
      },
    },
    {
      title: translate('upsell.toRoom'),
      dataIndex: ['PhysicalRoomUpsellToOption'],
      key: ['PhysicalRoomUpsellToOption.to'],
      searchable: false,
      sorter: false,
      render: physicalRoomUpsellToOption => {
        const room = physicalRoomUpsellToOption?.toPhysicalRoom;
        let text = room?.texts?.find(t => t.type == 'name' && t.lang == getSelectedLanguage().id);
        if (!text) {
          text = room?.texts?.find(t => t.type == 'name' && t.lang == 'en');
        } else if (!text) {
          text = room?.texts?.find(t => t.type == 'name');
        }
        return <div>{text?.value || '-'}</div>;
      },
    },
    {
      title: translate('upsell.date'),
      dataIndex: ['created_at'],
      key: 'created_at',
      searchable: false,
      sorter: false,
      render: date => {
        return moment(date).format(constants.dateFormat);
      },
    },
    Table.SELECTION_COLUMN,
    {
      title: ({ selectedKeys, confirm }) => (
        <TableActions
          title="Bulk Actions" // texto para añadir a los ficheros de traducciones
          items={STATUS_MAPPING['pending']}
          onItemClick={actions.handleBulkActionClick}
          disabled={appState.role === 'guest'}
        />
      ),
      dataIndex: 'icons',
      key: 'icons',
      searchable: false,
      sorter: false,
      render: (_, row) => {
        const items = [...getSelectors(row.status)];

        if (row.status !== 'removed') {
          return (
            <TableActions
              title={translate('salesChannels.actionCol')}
              items={items}
              onItemClick={actions.handleActionClick(row)}
              disabled={appState.role === 'guest'}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          <div style={styles.buttonsContainer}>
            <RangePicker
              onChange={actions.onDateChange}
              defaultValue={dates}
              format={constants.dateFormat}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <FilterComponent
            onFilterChange={actions.onFilterChange}
            items={dataSource.filterData?.data}
            counters={dataSource.counters}
          />
          <BaseTable
            data={dataSource}
            columns={columns}
            loading={isLoading}
            rowSelection={{
              selectedRowKeys: dataSource.selectedRowKeys,
              onChange: actions.onSelectChange,
            }}
            onChange={filters => actions.onChange(filters)}
            onRowClicked={actions.onRowClicked}
          />
        </div>
      </div>
    </>
  );
}

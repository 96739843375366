import React, { useContext } from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import ContentBox from '../../common/ContentBox/ContentBox';
import useUsersHook from './Users.hook';
import { Table, Modal, Button, Popover, Select, Tag } from 'antd';
import { AppContext } from 'App.context';
import { capitalize } from 'utils/TextUtils';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import styles from './Users.styles';
import Icon, {
  CrownFilled,
  CrownTwoTone,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeFilled
} from '@ant-design/icons';
import { ReactComponent as activeDot } from "assets/images/activeDot.svg";
import { ReactComponent as inactiveDot } from "assets/images/inactiveDot.svg";

export default function Users() {
  const { Option } = Select;
  const { state, actions } = useUsersHook();

  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('team.usersDetail.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        return <div>{capitalize(row.name)}</div>;
      },
    },
    {
      title: translate('team.usersDetail.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: translate('team.usersDetail.permission'),
      key: 'role',
      dataIndex: 'role',
      render: (_, row) => {
        const iconStyle = { marginRight: '4px' }
        if (row.role == 'owner') {
          return <div><CrownTwoTone style={iconStyle} />{capitalize(row.role)}</div>;
        }
        if (row.role == 'admin') {
          return <div><CrownFilled style={iconStyle} />{capitalize(row.role)}</div>;
        }
        if (row.role == 'user') {
          return <div><EditFilled style={iconStyle} />{capitalize(row.role)}</div>;
        }
        if (row.role == 'guest') {
          return <div><EyeFilled style={iconStyle} />{capitalize(row.role)}</div>;
        }
      },
    },
    {
      title: translate('team.usersDetail.status'),
      key: 'role',
      dataIndex: 'role',
      render: (_, row) => {
        if (row.status == 'active') {
          return <Tag style={styles.activeStatus} icon={<Icon component={activeDot} />}>
            {translate('team.usersDetail.activeStatus')}
          </Tag>
        }
        if (row.status == 'disabled') {
          return <Tag style={styles.pendingStatus} icon={<Icon component={inactiveDot} />}>
            {translate('team.usersDetail.pendingStatus')}
          </Tag>
        }
      },
    },
  ];

  columns.push({
    title: translate('team.usersDetail.actions'),
    dataIndex: null,
    key: null,
    align: 'center',
    width: '5%',
    render: (_, row) => {
      const deleteContent = <div>{translate('team.usersDetail.deletePop')}</div>;
      const editContent = <div>{translate('team.usersDetail.editPop')}</div>;

      return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
          <Popover content={deleteContent} trigger="hover">
            <Button
              type="secondary"
              icon={<DeleteOutlined />}
              disabled={!(appState.superUser || (appState.role == 'owner' && row.role != 'owner'))}
              onClick={() => actions.openDeleteUserModal(row.id)}
            />
          </Popover>

          <Popover content={editContent} trigger="hover">
            <Button
              type="secondary"
              icon={<EditOutlined />}
              disabled={!(appState.superUser || (appState.role == 'owner' && row.role != 'owner'))}
              onClick={() => actions.openEditUserModal(row)}
            />
          </Popover>
        </div>
      );
    },
  });

  return (
    <DefaultLayout title={translate('layout.users')} showHotelSelector={false}>
      <ContentBox title={translate('team.newUser')} customMinSize={11}>
        <AppTextInput
          value={state.name}
          name={translate('team.newUserDetail.name')}
          onChange={value => actions.setField('name', value)}
        />
        <AppTextInput
          value={state.email}
          name={translate('team.newUserDetail.email')}
          onChange={value => actions.setField('email', value)}
        />

        <div style={{ marginBottom: '16px' }}>
          <label style={styles.labelTitle}>{translate('team.newUserDetail.hotel')}</label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            value={state.hotel || []}
            onSelect={actions.onSelectNewHotel}
            onDeselect={actions.onDeselectNewHotel}
            onClear={actions.onClearNewHotel}
            optionFilterProp='children'
          >
            <Option value="all">{translate('hotelSelector.allHotels')}</Option>
            {state.hotels.map(hotel => (
              <Option key={hotel.id} value={hotel.id}>
                {hotel.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={styles.labelTitle}>{translate('team.newUserDetail.permission')}</label>
          {/* <div styles={styles.inputContainer}> */}
            <Select
              value={state.role}
              onChange={value => actions.setField('role', value)}
              style={{ width: '100%' }}
            >
              <Option value="admin">
                <CrownFilled style={{ marginRight: '6px' }} />{translate('team.newUserDetail.permissions.admin')}
                <p style={styles.descriptionText}>{translate('team.newUserDetail.permissions.adminDescription')}</p>
              </Option>
              <Option value="user">
                <EditFilled style={{ marginRight: '6px' }} />{translate('team.newUserDetail.permissions.user')}
                <p style={styles.descriptionText}>{translate('team.newUserDetail.permissions.userDescription')}</p>
              </Option>
              <Option value="guest">
                <EyeFilled style={{ marginRight: '6px' }} />{translate('team.newUserDetail.permissions.guest')}
                <p style={styles.descriptionText}>{translate('team.newUserDetail.permissions.guestDescription')}</p>
              </Option>
            </Select>
          {/* </div> */}
        </div>

        <div style={styles.buttonContainer}>
          <AppButton text={translate('team.newUserDetail.invite')} action={() => actions.validateFieldsAndSend()} />
        </div>
      </ContentBox>
      <ContentBox noPadding title={translate('team.users')} customMinSize={13}>
        <Table
          isLoading={state.isLoading}
          dataSource={state.users}
          columns={columns}
          pagination={4}
        />
      </ContentBox>

      <Modal
        open={state.isDeleteModalVisible}
        onCancel={actions.closeDeleteUserModal}
        footer={null}
        width={521}
        height={391}
      >
        <div style={styles.textContainerModal}>
          <div style={styles.iconModal}>
            <ExclamationCircleOutlined />
          </div>
          <div style={styles.titleModal}>
            <strong>{translate('team.usersDetail.deleteConfirmTitle')}</strong>
          </div>
          <div style={styles.textModal}>
            <p>{translate('team.usersDetail.deleteConfirmDescription')}</p>
          </div>
        </div>
        <div style={styles.modalButton}>
          <Button key="delete" type="primary" danger onClick={() => actions.delete(state.userToDelete)} style={styles.deleteButton}>
            {translate('team.usersDetail.delete')}
          </Button>
        </div>
        <div style={styles.modalButton}>
          <Button key="cancel" onClick={actions.closeDeleteUserModal} style={styles.cancelButton}>
            {translate('team.usersDetail.cancel')}
          </Button>
        </div>
      </Modal>
      <Modal
        open={state.isEditModalVisible}
        onCancel={actions.closeEditUserModal}
        footer={null}
      >
        <div style={styles.editIcon}>
          <EditOutlined />
        </div>
        <div style={styles.editTitle}>
          {translate('team.usersDetail.edit')}
        </div>
        <div style={styles.editDescription}>
          {translate('team.usersDetail.editDescription')}
        </div>
        <AppTextInput
          style={styles.textContainerModal}
          value={state.userToEdit ? state.userToEdit.name : ''}
          name={translate('team.usersDetail.name')}
          onChange={value => actions.setUserToEditField('name', value)}
        />
        <AppTextInput
          style={styles.textContainerModal}
          value={state.userToEdit ? state.userToEdit.email : ''}
          name={translate('team.usersDetail.email')}
          onChange={value => actions.setUserToEditField('email', value)}
        />
        <div style={styles.separator}/>
        <div style={{ marginBottom: '16px' }}>
          <label style={styles.labelTitle}>{translate('team.usersDetail.hotel')}</label>
          <Select
            mode='multiple'
            allowClear
            style={styles.hotelSelector}
            value={state.userToEdit ? state.userToEdit.hotel : []}
            onSelect={actions.onSelectEditHotel}
            onDeselect={actions.onDeselectEditHotel}
            onClear={actions.onClearEditHotel}
            optionFilterProp='children'
          >
            <Option value="all">{translate('hotelSelector.allHotels')}</Option>
            {state.hotels.map(hotel => (
              <Option key={hotel.id} value={hotel.id}>
                {hotel.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginBottom: '24px' }}>
          <label style={styles.labelTitle}>{translate('team.usersDetail.permission')}</label>
            <Select
              value={state.userToEdit ? state.userToEdit.role : ''}
              onChange={value => actions.setUserToEditField('role', value)}
              style={styles.textContainerModal}
            >
              <Option value="admin"><CrownFilled style={{ marginRight: '6px' }} />
                {translate('team.newUserDetail.permissions.admin')}
              </Option>
              <Option value="user"><EditFilled style={{ marginRight: '6px' }} />
                {translate('team.newUserDetail.permissions.user')}
              </Option>
              <Option value="guest"><EyeFilled style={{ marginRight: '6px' }} />
                {translate('team.newUserDetail.permissions.guest')}
              </Option>
            </Select>
        </div>
        <div style={styles.modalButton}>
          <Button key="save" type="primary" danger onClick={() => actions.update(state.userToEdit.id, state.userToEdit.name, state.userToEdit.email, state.userToEdit.hotel, state.userToEdit.role)} style={styles.editButton}>
            {translate('team.usersDetail.save')}
          </Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
}

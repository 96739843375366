import { notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import activateOtaAccountRequest from 'utils/networking/api/auth/activateOtaAccount.service';
import paymentMethodConfiguredRequest from 'utils/networking/api/auth/paymentMethodConfigured.service';
import { AppContext } from '../../../../App.context';
import loginRequest from '../../../../utils/networking/api/auth/login.service';
import validateUserRequest from '../../../../utils/networking/api/auth/validateUser.service';
import storage from '../../../../utils/storage';

export default function useLoginHook() {
  const { appState, appActions } = useContext(AppContext);
  const history = useHistory();

  const [state, setState] = useState({
    email: null,
    password: null,
    loading: false,
    blockedAccount: false,
    failedAttempts: 0,
  });

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params.get('user_id') && params.get('token')) {
      actions.validateUser(params.get('user_id'), params.get('token'));
      params.delete('user_id');
      params.delete('token');
      history.replace({
        user_id: params.toString(),
        token: params.toString(),
      });
    }
    if (params.get('email')) updateState({ email: params.get('email') });
    if (params.get('registerPaymentMethod')) {
      const registerPaymentMethod = params.get('registerPaymentMethod');
      if (registerPaymentMethod === 'success') {
        actions.paymentMethodConfigured(params.get('email'));
      } else {
        notification.warn({
          message: appActions.translate('register.registerKO'),
        });
      }
      params.delete('registerPaymentMethod');
      params.delete('email');
      history.replace({
        registerPaymentMethod: params.toString(),
        email: params.toString(),
      });
    }
  }, []);

  const actions = {
    setEmail: value => {
      updateState({ email: value });
    },

    setPassword: value => {
      updateState({ password: value });
    },

    handleLoginClick: () => {
      if (params.get('ota')) {
        activateOtaAccountRequest({ email: state.email, hotelSlug: params.get('hotelSlug') })
          .then(_ => {
            actions.login();
          })
          .catch(err => {
            console.warn(err);
          });
      } else {
        actions.login();
      }
    },

    login: () => {
      updateState({ loading: true });
      loginRequest(state.email, state.password, appState.currentLanguage?.id)
        .then(data => {
          updateState({ loading: false });
          if (data.sessionId) {
            history.push(`/validate-login/${data.sessionId}`);
          } else {
            notification.error({
              message: appActions.translate('login.error'),
            });
          }
        })
        .catch(error => {
          updateState({ loading: false });
          
          if (error?.response?.data?.error === 'Blocked account') {
            updateState({blockedAccount: true, failedAttempts: 0});
          }

          if (error?.response?.data?.error === 'Invalid credentials') {
            updateState({failedAttempts: error.response.data.failedAttempts});
          }

          notification.error({
            message: error?.response?.data?.message,
          });
        });

    },

    goToRegister: () => {
      history.push('/register');
    },

    validateUser: (userId, token) => {
      updateState({ loading: true });
      validateUserRequest(userId, token, appState.currentLanguage?.id)
        .then(data => {
          updateState({ loading: false, email: data.email });
          notification.success({
            message: appActions.translate('validateUser.success').replace('#', data.name),
          });
        })
        .catch(error => {
          updateState({ loading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },

    goToRecoverPassword: () => {
      history.push('/recover');
    },

    paymentMethodConfigured: email => {
      updateState({ loading: true });
      paymentMethodConfiguredRequest({ email, lang: appState.currentLanguage?.id })
        .then(_data => {
          updateState({ loading: false, email });
          notification.success({
            message: appActions.translate('paymentMethods.addedSuccess'),
          });
        })
        .catch(error => {
          updateState({ loading: false });
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

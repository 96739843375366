import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import Space from '../../Space/Space';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import WelcomeInput from '../WelcomeLayout/WelcomeInput/WelcomeInput';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import useLoginHook from './Login.hook';
import styles from './Login.module.css';
import Loading from '../../../common/Loader/Loading';
import { AppContext } from '../../../../App.context';

export default function Login() {
  const { state, actions } = useLoginHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const maxFailedAttempts = 5;
  const maxFailedAttemptsMessage = translate('login.maxFailedAttempts').replace('#', maxFailedAttempts - state.failedAttempts);

  return (
    <WelcomeLayout title={state.blockedAccount ? translate('login.blockedAccount') : translate('login.header')}>
      {
        state.blockedAccount
        ? <></>
        : <>
          <WelcomeInput
            icon={<MailOutlined />}
            value={state.email}
            placeholder={translate('login.emailPlaceholder')}
            onChange={value => {
              actions.setEmail(value);
            }}
          />
          <Space height={16} />
          <WelcomeInput
            icon={<EyeOutlined />}
            value={state.password}
            placeholder={translate('login.passwordPlaceholder')}
            onChange={value => {
              actions.setPassword(value);
            }}
            type={'password'}
          />
          <Space height={52} />
          <WelcomeButton
            text={translate('login.loginButton')}
            onClick={() => actions.handleLoginClick()}
          />
          <Space height={8} />
          <div className={styles.forgotPasswordContainer} onClick={() => actions.goToRecoverPassword()}>
            <p>{translate('login.forgotPassword')}</p>
          </div>
          <Space height={16} />
          <div className={styles.separator} />
          <Space height={32} />
          <div className={styles.register} onClick={() => actions.goToRegister()}>
            {translate('login.registerButton')}
          </div>
          <Space height={32} /> 
          { state.failedAttempts > 2
            ? <div className={styles.failedAttemptsWarning}>{maxFailedAttemptsMessage}</div>
            : undefined }
        </>
      }
      {state.loading && <Loading></Loading>}
    </WelcomeLayout>
  );
}

// Importa las librerías necesarias
import React, { useState, useRef } from 'react';
import { Input } from 'antd';
import './TwoFactorInput.css';

export default function TwoFactorInput({ onValidCode }) {
  const CODE_LENGTH = 6;
  const [code, setCode] = useState(Array(CODE_LENGTH).fill(''));
  const inputsRef = useRef([]);

  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < CODE_LENGTH - 1) {
      if (inputsRef.current[index + 1]) {
        inputsRef.current[index + 1].focus();
      }
    }

    if (newCode.every(digit => digit !== '')) {
      onValidCode(newCode.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
      if (inputsRef.current[index - 1]) {
        inputsRef.current[index - 1].focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      if (inputsRef.current[index - 1]) {
        inputsRef.current[index - 1].focus();
      }
    } else if (e.key === 'ArrowRight' && index < CODE_LENGTH - 1) {
      if (inputsRef.current[index + 1]) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handlePaste = e => {
    const pasteData = e.clipboardData.getData('Text').slice(0, CODE_LENGTH);
    const newCode = pasteData.split('').concat(Array(CODE_LENGTH).fill(''));
    setCode(newCode.slice(0, CODE_LENGTH));
    if (pasteData.length >= CODE_LENGTH) {
      if (inputsRef.current[CODE_LENGTH - 1]) {
        inputsRef.current[CODE_LENGTH - 1].focus();
      }
      onValidCode(newCode.join(''));
    } else {
      inputsRef.current[pasteData.length - 1].focus();
    }
  };

  return (
    <div className="two-factor-container">
      {code.map((digit, index) => (
        <Input
          key={index}
          value={digit}
          maxLength={1}
          ref={el => (inputsRef.current[index] = el)}
          onChange={e => handleChange(e.target.value, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          onPaste={handlePaste}
          className="two-factor-input"
        />
      ))}
    </div>
  );
}

import apiConfig from '../../../utils/networking/api.config';

export const HOST = 'https://cross-sell-api.civitfun.com';

export const dashboardRequest = (action, subaction, data) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));

  const requestBody = {
    action,
    subaction,
    data: { hotelId: selectedHotel.id, ...data },
  };

  return fetch(`${apiConfig.host}${apiConfig.URIs.registerDashboardAction}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.accessToken}`,
    },
    method: 'PUT',
    body: JSON.stringify(requestBody),
  })
};

export const request = (uri, options = { headers: {} }, queryParams = {}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  const params = new URLSearchParams({ ...queryParams, hotelId: selectedHotel.id });
  return fetch(`${HOST}/${uri}?${params}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.accessToken}`,
      ...options.headers,
    },
    ...options,
  }).then(res => {
    if (res.status === 422) {
      return res.json().then(data => {
        const { message, path } = data.message[0];
        throw new Error(`error.${path.join('.')}.${message}`.toLowerCase());
      });
    }

    if (options.method !== 'DELETE') {
      return res.json();
    }
  });
};

export const deleteResource = (resource, data) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_delete_resource', data);

  return request(`${resource}/${data}`, {
    method: 'DELETE',
  });
};

export const findAll = (resource, filters = {}) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_list_resources', filters);

  return request(
    resource,
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

export const upsert = (resource, data) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_upsert', data);

  if (data.id) {
    return request(`${resource}/${data.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }
  return request(`${resource}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const findById = (resource, data) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_find_by_id', data);

  return request(`${resource}/${data.id}`, {
    method: 'GET',
  });
}

export const findHotelConfig = () => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_find_hotel_config', {});

  return request(`hotels/config`, {
    method: 'GET',
  });
}

export const createItem = (resource, data) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_create_item', data);

  return request(`${resource}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const updateItem = (resource, data) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_update_item', data);

  return request(`${resource}/${data.id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
};

export const uploadImages = async (resource, formData, queryParams = {}) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_upload_images', {});

  const headers = new Headers();
  const user = JSON.parse(localStorage.getItem('user'));
  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  headers.append('Authorization', `Bearer ${user.accessToken}`);
  const params = new URLSearchParams({ ...queryParams, hotelId: selectedHotel.id });
  const response = await fetch(`${HOST}/${resource}?${params}`, {
    method: 'POST',
    body: formData,
    headers,
  });
  return response;
};

export const reports = (filters = {}) => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_list_reports', filters);

  return request(
    `cross-sells/report`,
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

export const topSells = filters => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_top_sells', filters);

  return request(
    'cross-sells/report/top',
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

export const counters = filters => {
  // Register action
  dashboardRequest('cross_sell','cross_sell_counters', filters);

  return request(
    'cross-sells/counters',
    {
      method: 'GET',
    },
    { filters: JSON.stringify(filters) }
  );
};

import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../App.context';
import LayoutScreens from './LayoutScreens';

const useDefaultLayoutHook = () => {
  const { appState, appActions } = useContext(AppContext);
  const location = useLocation();
  const [state, setState] = useState({
    sliderCollapsed: false,
    layoutHeight: 0,
  });

  useEffect(() => {
    actions.setBreadcrumb();
  }, []);

  const actions = {
    setSliderCollapsed: isCollapsed => {
      updateState({ sliderCollapsed: isCollapsed });
    },

    getLocation: () => {
      return location;
    },

    setBreadcrumb: () => {
      for (const menu of LayoutScreens) {
        for (const menuItem of menu.children) {
          if (menuItem.path === location.pathname) {
            appActions.setBreadCrumb(menu, menuItem);
          }
        }
      }
    },

    closeInactivityMessage: () => {
      appActions.closeInactivityMessage();

      if (appState.sessionTimeLeft === 0) {
        appActions.logout();
      }
    },

    setLayoutHeight: height => {
      updateState({ layoutHeight: height });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useDefaultLayoutHook;

import upsell from './upsell/es';
import crossSell from './cross-sell/es';
import description from 'Sell/Upsell/fixtures/description';

export default {
  layout: {
    configuration: 'Configuración',
    dashboard: 'Dashboard',
    hotels: 'Hoteles',
    forms: 'Formularios',
    appearence: 'Apariencia',
    pms: 'PMS',
    sales: 'Canales de checkin',
    payment: 'Pasarelas de pago',
    documents: 'Documentos Legales',
    language: 'Idiomas y literales',
    users: 'Equipo',
    checkin: 'Check-in',
    checkout: 'Check-out',
    booking: 'Reservas',
    paymentMethods: 'Métodos de Pago',
    billing: 'Datos de facturación',
    subscriptions: 'Suscripciones',
    doorOpening: 'Apertura de puertas',
    doorOpeningConfig: 'Configuración de plataforma',
    doorMapping: 'Mapeo de puertas',
    doorOpeningMaintenance: 'Mantenimiento de puertas',
    stats: 'Resumen',
    account: 'Mi Cuenta',
    communicationChannels: 'Plantillas de mail',
    accountDetail: 'Detalles de la cuenta',
    marketing: 'Marketing',
    support: 'Soporte',
    quickguide: 'Ver guía',
    guestlink: 'Guestlink',
    mailTemplates: 'Plantillas de mail',
    smtpConfig: 'Configuración SMTP',
    paymentList: 'Pagos',
    paperlessPush: 'Paperless push',
    cardex: 'Texto legal del parte de entrada',
    custom_cardex_pdf: 'Parte de Entrada',
    terms: 'Términos y Condiciones',
    'data-privacy-policy': 'Política de privacidad',
    'loyalty-program': 'Programa de fidelización',
    'data-commercial-use': 'Uso comercial',
    policeReport: 'Reporte policial',
    policeReportConfig: 'Configuración reporte policial',
    billingConfig: 'Facturación',
    upsell: 'Upselling',
    crossSell: 'Cross-selling',
    moduleActivation: 'Activación de módulos',
    sessionExpired: "Tu sesión ha expirado por inactividad.",
    sessionWillExpire: "La sesión expirará en # minutos. Los cambios no guardados se perderán.",
    closeInactivityMessageButton: "Volver para guardar cambios",
    login: "Iniciar sesión"
  },
  save: 'Guardar',
  cancel: 'Cancelar',
  search: 'Buscar',
  add: 'Añadir',
  yes: 'Sí',
  no: 'No',
  setup: 'Conectar',
  checkinPreview: 'Vista Previa Checkin',
  logout: 'Cerrar sesión',
  state: 'Estado',
  hotel: 'Hotel',
  pms: 'PMS',
  id: 'ID',
  family_relationship:
    'Si se marca como obligatorio, el campo solo será requerido para el titular de la reserva cuando haya niños en la misma.',
  document_support_number:
    'Si se marca, el campo solo será requerido para DNI o Permiso de residencia; para otros documentos, será visible pero opcional.',
  pmsPopup: {
    title: '¡Perfecto!',
    subtitle: 'Acabas de dar de alta tu propiedad',
    legalTextNewHotel:
      'Se ha guardado correctamente el Parte de entrada y el Texto legal del parte de entrada en Inglés y Español',
    text: 'Ahora puedes optimizar el proceso conectando tu PMS',
    button: 'Configurar PMS',
  },
  hotelSelector: {
    onlyTo: 'Solo a:',
    applyToAllHotels: 'Todos los hoteles',
    allHotels: 'Todos los hoteles',
    hint: 'Introduce el nombre del hotel',
    info: 'Selecciona Hotel:',
  },
  channel: 'Canal',
  checkins: 'Checkins',
  appearence: {
    checkInTitle: 'Apariencia Check-in Online',
    logo: 'Logo',
    mainColor: 'Color principal',
    secondaryColor: 'Color secundario',
    bgColor: 'Color de fondo',
    kioskTitle: 'Apariencia Kiosk',
    mainColorKiosk: 'Color principal Kiosk',
    kioskBg: 'Color de fondo Kiosk',
    languageTitle: 'Selecciona los idiomas que necesites',
    kioskLogo: 'Logo de Kiosk',
    success: 'Apariencia actualizada',
    languageSuccess: 'Idiomas actualizados',
    apply: 'Aplicar estilos',
    chainTitle: 'Apariencia Cadena',
    imageSliderTitle: 'Carrusel de reposo t-paperless',
    upload: 'Añadir',
    imageSliderDesc:
      'Aquí puedes configurar las imágenes que se mostrarán en el carrusel, puedes añadir hasta 5 imágenes',
  },
  login: {
    header: 'Introducir los datos de acceso',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Contraseña',
    loginButton: 'Entrar',
    registerButton: '¿Todavía no tienes tus credenciales? CREA UNA CUENTA',
    forgotPassword: '¿Has olvidado tu contraseña?',
    blockedAccount: 'Tu cuenta ha sido bloqueada debido a que se ha superado el número máximo de intentos de inicio de sesión. Para desbloquearla, ponte en contacto con support@civitfun.com.',
    maxFailedAttempts: 'Usuario o contraseña incorrectos. Quedan # intentos antes de que tu cuenta se bloquee.',
    error: 'Error al iniciar sesión',
  },
  validateLogin: {
    header: 'Verificación en dos pasos',
    description: 'Introduce el código de verificación que has recibido en tu correo electrónico',
    button: 'Enviar',
    error: 'Error al validar el código',
  },
  register: {
    title: 'Información de la cuenta',
    chain: 'Nombre Cadena Hotelera',
    name: 'Nombre',
    phone: 'Teléfono',
    mail: 'Email',
    password: 'Contraseña',
    repeatPassword: 'Repite contraseña',
    termsConditions: 'He leído y acepto la política de privacidad y los términos y condiciones',
    button: 'Crear cuenta',
    emptyFields: 'Debe rellenar todos los datos del formulario',
    matchPasswords: 'Las contraseñas no coiniciden',
    invalidMail: 'La dirección de correo no es válida',
    termsError: 'Debe aceptar los términos y condiciones',
    registerOk:
      'Bienvenido a Civitfun, te hemos enviado un mail a # para que valides tu registro. Pulsando sobre "Añadir método de pago" serás redirigido para poder configurar un método de pago.',
    registerKO: 'No se ha podido añadir el método de pago correctamente',
    goBack: 'Volver',
    goLogin: 'Ir al login',
    addPaymentMethod: 'Añadir método de pago',
  },
  recover: {
    header:
      'Introduce la dirección de correo asociada a la cuenta y te enviaremos un correo para recuperar la contraseña',
    invalidEmail: 'Introduce una dirección de correo válida',
    send: 'Enviar',
    success: 'Hemos enviado por correo electrónico el enlace para restablecer su contraseña.',
  },
  changePassword: {
    header: 'Introduce tu nueva contraseña',
    success: 'Se ha cambiado la contraseña correctamente',
  },
  validateUser: {
    success: '# se ha completado el registro correctamente!',
  },
  bookingList: {
    nameAndSurnameCol: 'Nombre y apellidos',
    locatorCol: 'Localizador',
    nameCol: 'Nombre',
    surnameCol: 'Apellidos',
    checkinCol: 'Entrada',
    checkoutCol: 'Salida',
    adultCol: 'Adultos',
    childrenCol: 'Niños',
    documentCol: 'Documento',
    export: 'Exportar datos',
    import: 'Importar reservas',
    title: 'Reservas',
    statusCol: 'Estado',
    actionPop: 'Ver Checkins',
  },
  errorMessage: {
    dateRangeExport: 'Debe seleccionar un rango de fechas para realizar la exportación',
    guestDetail: 'No es posible visualizar la información en detalle',
  },
  bookingStatus: {
    BOOKED: 'Reservado',
    UNCOMPLETED: 'No completado',
    COMPLETED: 'Completado',
    CHECKEDOUT: 'Checked Out',
    CHECKEDIN: 'Checked In',
  },
  setUp: {
    searchFieldsTitle: 'Campos formulario de búsqueda',
    searchFieldInfo:
      'Si el proceso de check-in está integrado con tu PMS estos campos se configurarán automáticamente',
    checkInRequirement: 'Requisitos del proceso de check-in',
    bookingSummary: 'Detalles mostrados en el resumen de la reserva',
  },
  bookingSummary: {
    showBookingNumber: 'Número de reserva',
    showHolderName: 'Nombre del titular',
    showArrivalDate: 'Fecha de entrada',
    showDepartureDate: 'Fecha de salida',
    showRoomName: 'Habitación',
    infoText:
      'El campo "habitación" mostrará tanto el número como el tipo de habitación si están disponibles en el PMS al momento del check-in. Si solo uno está disponible, solo ese se mostrará.',
  },
  guestTable: {
    fieldCol: 'Campo',
    visibleCol: 'Visible',
    mandatoryCol: 'Obligatorio',
    success: 'Se ha actualizado la configuración de formulario de húesped',
    error: 'Error al actualizar  la configuración de formulario de húesped',
    applyToAllWarning: 'La configuración se va aplicar a todos los hoteles',
    languageSelector: 'Selecciona el idioma de los campos',
    italyDesc:
      'Este hotel está situado en Italia, este campo se enseñará si el país de nacimiento es Italia',
    italyResidencyDesc:
      'Este hotel está situado en Italia, este campo se enseñará si el país de residencia es Italia',
    translationMissing: 'Falta la traducción de este campo para otros idiomas',
  },
  dynamicForms: {
    group: {
      document_data: 'Datos del documento',
      contact_data: 'Datos personales',
      other: 'Campos',
      uploadDocument: 'Documentos adicionales',
    },
    guestType: {
      holder_1: 'Titular doméstico',
      holder_0: 'Titular no doméstico',
      adult_1: 'Adulto doméstico',
      adult_0: 'Adulto no doméstico',
      child_1: 'Niño doméstico',
      child_0: 'Niño no doméstico',
    },
    visible: 'Visible',
    required: 'Requerido',
    field: 'Campo',
  },
  checkOutModule: {
    locatorCol: 'Localizador',
    nameCol: 'Nombre',
    surnameCol: 'Apellidos',
    entranceDate: 'Entrada',
    departureDate: 'Salida',
    title: 'Check-Out',
    export: 'Exportar datos',
    actions: 'Detalles',
    channelCol: 'Canal',
    date: 'Check-out',
    exportOk: 'Exportación de ckeckouts completada!',
    missingSubscription: {
      title: 'Obtener licencia',
      description:
        "Puede configurar Check-Out para el hotel activando la licencia de 'Check-Out'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    },
  },
  checkInList: {
    stateCol: 'Estado',
    locatorCol: 'Localizador',
    typeCol: 'Tipo',
    nameCol: 'Nombre',
    surnameCol: 'Apellidos',
    checkinCol: 'Entrada',
    checkoutCol: 'Salida',
    documentCol: 'Documento',
    title: 'Check-In',
    export: 'Exportar datos',
    statePending: 'Pendiente',
    stateCheckin: 'Check In',
    stateCheckout: 'Check Out',
    actions: 'Detalle',
    channelCol: 'Canal',
    checkinDate: 'Checkin',
    popUp: {
      processingTitle: 'Procesando',
      processingMessage: 'Estamos procesando su solicitud. Por favor, espere.',
    },
    detail: {
      bookingDetail: 'Detalles de la reserva',
      informationTitle: 'Información',
      documentsTitle: 'Documentos',
      locator: 'Localizador',
      checkin: 'Entrada',
      checkout: 'Salida',
      holder: 'Titular',
      signedDocuments: 'Documentos firmados',
      termsConditions: 'Términos y Condiciones',
      cardex: 'Parte de Entrada',
      guestDetail: 'Datos del titular',
      paymentDetail: 'Datos de pagos',
      payment: {
        charges: 'Cargos',
        operations: 'Operaciones',
        locator: 'Localizador',
        amount: 'Cuantía',
        operation: 'Tipo',
        concept: 'Concepto',
        paymentStatus: 'Estado de pago:',
        warrantyStatus: 'Estado de garantía:',
        statusDivider: 'Estado de los pagos',
        totalBalance: 'Balance total',
        operationPayment: 'Pago',
        operationTokenize: 'Garantía',
        operationPaymentTokenize: 'Pago y garantía',
      },
    },
    missingSubscription: {
      title: 'Obtener licencia',
      description:
        "Puede configurar Check-In para el hotel activando la licencia de 'Check-In'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    },
  },
  ihClient: {
    CIVITFUN: 'Civitfun',
    MTS: 'MTS',
    ROOMMATIK: 'Roommatik',
    BOOKINGDOTCOM: 'Booking.com',
    KIOSK: 'Terminal Auto Check-In',
    RECEPTION: 'Terminal Paperless',
    ['BOOKING.COM']: 'Booking.com',
  },
  salesChannels: {
    stateCol: 'Estado',
    hotelCol: 'Hotel',
    channelCol: 'Canal',
    actionCol: 'Acción',
    identifierCol: 'Identificador',
    bookingIdHint: 'Id. hotel de booking.com',
    bookingTooltip:
      '¿Qué es esto? Puede encontrar el ID de su propiedad en Booking.com en su panel de administración',
    tryFreeButton: 'Pruébalo gratis',
    success: '¡Canal de check-in actualizado correctamente!',
    otaPopup: {
      title: '¡Perfecto!',
      text: 'Solo falta activar el canal $OTA para empezar a recibir los check-ins',
      button: 'Activar $OTA',
    },
    activeGuestlink1: 'Para poder habilitar Booking.com ',
    activeGuestlink2: 'es necesario que configures tu SMTP',
  },
  guestForms: {
    holder: 'Titular',
    adults: 'Adultos',
    children: 'Niños',
    applyToAllHotels: 'Aplicar a todos los hoteles',
  },
  hotels: {
    addNewHotel: 'Añadir un nuevo hotel',
    title: 'Hoteles',
    nameCol: 'Nombre',
    editCol: 'Editar',
    deleteCol: 'Borrar',
    deleteWarningTitle: 'Eliminar',
    deleteWarning: '¿Quiere eliminar el hotel?',
    mapHint: 'Busca tu Hotel en el Mapa',
    country: 'País',
    address: 'Dirección',
    province: 'Provincia',
    addSuccess: 'Se ha añadido el hotel #',
    updateSuccess: 'El hotel # se ha actualizado correctamente',
    deleteSuccess: 'El hotel # se ha eliminado',
    previewCheckin: 'Mi Check-in Online',
    integrated: 'Integrado',
    noIntegrated: 'No integrado',
    emptyTitle: 'Añade tus hoteles',
    emptyDescription:
      'Para hacerlo basta rellenar el campo nombre y buscar tu dirección en el mapa.',
    emptyName: 'Debes añadir un nombre para el hotel',
    emptyLocation: 'Debes añadir una localización para el hotel',
    nif: 'NIF',
    establishmentType: 'Tipo de establecimiento',
    roomCount: 'Número habitaciones',
    hasInternet: 'Tiene internet',
    city: 'Ciudad',
    postalCode: 'Código Postal',
    phone: 'Teléfono / Móvil',
    email: 'E-mail',
    website: 'Web',
    designation: 'Nombre fiscal',
    adUrl: 'Url para identificar el anuncio',
    hotelStatusId: 'Estado (sólo admins)',
    statusId: {
      label_1: 'Creado',
      label_2: 'Onboarding',
      label_3: 'Live',
      label_4: 'Sin licencia',
      label_5: 'Perdido',
      label_6: 'Demo',
      label_7: 'Pendiente',
    },
  },
  legalDocuments: {
    termsConditions: 'términos y condiciones',
    checkIn: 'parte de entrada',
    commercialUse: 'Texto check-box uso comercial de datos',
    preview: 'Previsualización',
    previewBtn: 'Previsualizar',
    success: 'Texto actualizado correctamente',
    error: 'Ha sucedido un error al actualizar el texto',
    warning: 'Plantillas cargadas por defecto. No se registrarán hasta que las guardes',
    previewPlaceholder: 'Pulsa sobre "previsualizar" para ver la previsualización del documento',
    langSelectorHint: 'Selecciona el idioma de los documentos',
    terms: 'Términos y condiciones',
    restoreDefault: 'Restablecer',
    restoreDefaultWarning: 'Se van a perder los datos que no haya guardado. Desea continuar?',
    cardex: 'Texto legal del parte de entrada',
    custom_cardex_pdf: 'Parte de entrada',
    labelHint: 'La etiqueta o frase al lado del checkbox',
    'data-privacy-policy': 'política de privacidad',
    'loyalty-program': 'programa de fidelización',
    'data-commercial-use': 'uso comercial',
    checkin_template: 'Plantilla mail confirmación del check-in',
    checkout_template: 'Plantilla mail confirmación de check-out',
    fileNamePrefix: 'Nombre de archivo',
    tags: {
      chain: 'Cadena',
      chain_logo: 'Logo de la cadena',
      chain_name: 'Nombre de la cadena',
      hotel: 'Hotel',
      hotel_logo: 'Logo del hotel',
      hotel_name: 'Nombre del hotel',
      address: 'Dirección',
      hotel_phone: 'Teléfono del hotel',
      CIF: 'CIF sociedad del hotel',
      CIF_postal_code: 'Código postal sociedad del hotel',
      CIF_address: 'Dirección sociedad del hotel',
      CIF_city: 'Ciudad sociedad del hotel',
      CIF_province: 'Provincia sociedad del hotel',
      CIF_country: 'País sociedad del hotel',
      CIF_business_name: 'Razón social del hotel',
      CIF_business_email: 'Email de contacto de la sociedad del hotel',
      legal_cardex: 'Texto legal sobre el parte de entrada',
      legal_terms_and_conditions: 'Texto legal sobre el contrato de la propiedad',
      legal_data_privacy_policy: 'Texto legal sobre las políticas de privacidad de datos',
      legal_data_commercial_use: 'Texto legal sobre el uso comercial de los datos',
      legal_loyalty_program: 'Texto legal sobre la adhesión al programa de fidelización',
      booking: 'Reservas',
      reference_number: 'Localizador/bono/voucher',
      booking_identifier: 'Identificador único en el PMS',
      entrance_date: 'Fecha de entrada',
      departure_date: 'Fecha de salida',
      entrance_time: 'Hora de entrada',
      departure_time: 'Hora de salida',
      nights: 'Número de noches',
      adults: 'Número de adultos',
      children: 'Número de niños',
      num_guests: 'Total de huéspedes',
      email: 'Email de la reserva',
      balance: 'Precio',
      agreed_price: 'Precio acordado',
      booking_room: 'Número de habitaciones',
      room_number: 'Nombre de la habitación asignada',
      room_number_name: 'Nombre de la habitación',
      customer_notes: 'Notas del cliente',
      agency_code: 'Código de la agencia',
      regime_stay: 'Estancia de régimen',
      guest_name: 'Nombre del titular',
      guest_nationality: 'Nacionalidad del titular',
      document_number: 'Número de documento del titular',
      holder_signature: 'Firma del titular',
      police_report_number: 'Reporte policial',
      guest: 'Húesped',
      guest_first_name: 'Nombre',
      guest_surname: 'Apellido',
      guest_second_surname: 'Segundo Apellido',
      guest_gender: 'Género',
      guest_birth_date: 'Fecha de nacimiento',
      guest_nationality: 'Nacionalidad',
      guest_document_type: 'Tipo de documento',
      guest_document_number: 'Número de documento',
      guest_expedition_date: 'Fecha de expedición',
      guest_expiration_date: 'Fecha de expiración',
      guest_email: 'Email',
      guest_phone: 'Teléfono',
      guest_address: 'Dirección',
      guest_postal_code: 'Código Postal',
      guest_city: 'Ciudad',
      guest_country: 'País',
      guest_province: 'Província',
      guest_signature: 'Firma',
      data_privacy_policy: 'Política de privacidad',
      data_commercial_use: 'Uso de datos',
      loyalty_program: 'Programa de fidelidad',
      credit_card: 'Tarjeta de crédito',
      card_name: 'Nombre de la tarjeta',
      card_type: 'Tipo de tarjeta',
      card_number: 'Número de la tarjeta (4 últimos dígitos)',
      card_expiration_date: 'Fecha de expiración',
      link_ios: 'Enlace iOS',
      link_android: 'Enlace Android',
      guest_business_name: 'Nombre empresa',
      guest_business_number: 'Número empresa',
      guest_business_address: 'Dirección empresa',
      bracelet_id: 'Brazalete UDF',
      checkin_url: 'Enlace Checkin',
      establishment_type: 'Tipo de establecimiento',
      hotel_designation: 'Nombre fiscal',
      hotel_room_count: 'Número de habitaciones',
      hotel_has_internet: 'Tiene internet',
      guest_landline_phone: 'Teléfono fijo',
      guest_document_support_number: 'Número de soporte del documento',
      guest_family_relationship: 'Relación de parentesco',
      guest_payment_type: 'Tipo de pago',
      hotel_web: 'Web del hotel',
      hotel_ad_url: 'Url del anuncio',
      hotel_slug: 'Slug del hotel',
      checkin_date: 'Fecha de check-in',
      confirmation_number: 'Número de confirmación',
      signer: 'Nombre del tutor legal',
      payment_url: 'Enlace de pagos',
      guest_allow_telephone_contact: 'Consentimiento de contacto telefónico',
      door_opening_url: 'Enlace generación llave',
      upsell_id: 'ID de upsell',
      upsell_request_date: 'Fecha de solicitud de upsell',
      upsell_state: 'Estado del upsell',
      original_room_type: 'Tipo de habitación original',
      requested_room_type: 'Tipo de habitación solicitada',
      upsell_price: 'Precio del upsell',
      payment_status: 'Estado del pago',
      payment_url: 'Enlace de pago',
      upsell_url: 'Enlace de upsell',
      guest_signature_date: 'Fecha de la firma',
      cross_sell_url: 'Enlace de cross-sell',
      cross_sell_id: 'Cross-sell ID',
      cross_sell_request_date: 'Fecha de solicitud de cross-sell',
      cross_sell_state: 'Estado del cross-sell',
      item_name: 'Nombre del item',
      cross_sell_price: 'Precio del cross-sell',
      confirmation_number: 'Identificador de reserva',
    },
  },
  literals: {
    title: 'Rellena estos campos para personalizar tus textos',
  },
  stats: {
    total: 'Total',
    avgDuration: {
      title: 'Tiempo medio del proceso',
      subtitle: 'Media del tiempo que tarda el huésped en hacer el checkin',
      noData: 'No hay datos',
    },
    conversion: {
      title: 'Conversión',
      subtitle: 'Checkin empezados VS checkin terminados',
      noData: 'No hay datos',
    },
    mostCheckins: {
      title: 'Día y hora con más checkins',
      subtitle: 'Los usuarios hacen el checkin con más frecuencia en los dias y horas',
      noData: 'No hay datos',
    },
    advance: {
      title: 'Antelación de Checkin',
      subtitle:
        'Los usuarios hacen el checkin con una media de antelación respecto a la fecha de entrada',
      noData: 'No hay datos',
      days: 'Dias',
      hours: 'Horas',
    },
    devices: {
      title: 'Dispositivos principales',
      phone: 'Móvil',
      tablet: 'Tablet',
      desktop: 'Ordenador',
      unknown: 'N/A',
    },
  },
  pmsScreen: {
    title: 'CONFIGURACIÓN PMS',
    defaultSelectorValue: 'Selecciona un PMS',
    connectSuccess: 'La conexión con el PMS se ha realizado correctamente',
    connectError: 'Error al realizar la conexión con el PMS',
    testPms: 'Comprobar conexión',
    testPmsDesc: 'Puedes comprobar el estado del PMS mediante el botón de Test PMS',
    testBooking: 'Test booking',
    testBookingDesc: 'Introduce los datos para poder comprobar la reserva',
  },
  states: {
    active: 'Activo',
    inactive: 'Inactivo',
    enabled: 'Habilitado',
    disabled: 'Desactivado',
    pending: 'Pendiente',
  },
  view: 'Ver',
  status: 'Estado',
  checkinConfig: {
    allowed_since:
      '¿Desde cuantas horas antes de la fecha de entrada se permite hacer el check-in online?',
    allowed_since_desc: 'Déjalo a 0 si no quieres limitarlo.',
    allowed_until:
      '¿Hasta cuantas horas antes de la fecha de entrada se permite hacer el check-in online?',
    allowed_until_desc: 'Déjalo a 0 si no quieres limitarlo.',
    required_documents: '¿A quién se requiere la captura de datos de forma obligatoria?',
    required_signatures: '¿Quién tiene que firmar el contrato de la propiedad?',
    required_cardex_signatures: '¿Quién tiene que firmar el parte de entrada?',
    capture_docs: 'Configuración escaner de documentos: ',
    success: 'Configuración actualizada correctamente',
    checkinWarning: 'Se va a actualizar la configuración de checkin',
    chainCheckinWarning: 'Se va a actualizar la configuración para todos los hoteles de la cadena',
    bookingCode: 'Localizador reserva',
    bookingHolder: 'Titular de la reserva',
    entranceDate: 'Fecha de entrada',
    departureDate: 'Fecha de salida',
    nights: 'Noches',
    paperless_start_checkin_timeout_seconds:
      'Tiempo máximo (en segundos) de visualización en la pantalla de reserva en T-Paperless',
    paperless_start_checkin_timeout_seconds_desc: 'Déjalo a 0 si no quieres limitarlo',
    paperless_finalize_checkin_timeout_seconds:
      'Tiempo máximo (en segundos) de visualización al finalizar la reserva en T-Paperless',
    paperless_finalize_checkin_timeout_seconds_desc: 'Déjalo a 0 si no quieres limitarlo',
    save_cardex: '¿Se tiene que guardar el parte de entrada?',
    send_cardex:
      '¿Para quién se tiene que enviar el parte de entrada en el correo de confirmación?',
    save_contract: '¿Se tiene que guardar el contrato de la propiedad?',
    send_contract:
      '¿Para quién se tiene que enviar el contrato de la propiedad en el correo de confirmación?',
    paperless_images_slider_timeout:
      '¿Cuánto tiempo (segundos) tardará en activarse el "carrusel de reposo T-Paperless" después de no interactuar con la tablet? (WIP)',
    paperless_version: '¿Qué versión de Paperless se usará?',
    paperless_show_signature_documents:
      '¿Es obligatorio que el huésped lea los documentos legales antes de firmar?',
    paperless_auto_next_checkin:
      '¿Activar el avance automático al siguiente huésped cuando se finalice el check-in de un huésped?',
    options: {
      o_v1: 'Versión actual',
      o_v2: 'Versión nueva',
      o_true: 'Si',
      o_false: 'No',
      o_none: 'Ninguno',
      o_adults: 'Todos los adultos',
      o_all: 'Todos los huéspedes',
      o_0: 'Ninguno',
      o_1: 'Uno',
      o_1_in_last_process: 'Uno, en el último proceso',
      o_holder: 'Titular',
      o_num_adults: 'Todos los adultos',
      o_num_guests: 'Todos los huéspedes',
      o_guest_over_2: 'Todos los adultos y niños mayores de 2 años',
      o_older_than_10: 'Todos los adultos y niños mayores de 10 años',
      o_guest_over_12: 'Todos los adultos y niños mayores de 12 años',
      o_guest_over_14: 'Todos los adultos y niños mayores de 14 años',
      o_guest_over_16: 'Todos los adultos y niños mayores de 16 años',
      o_optional: 'Opcional',
      o_required: 'Obligatorio',
      o_payment_warranty: 'Pago y garantía',
      o_payment: 'Pago',
      o_warranty: 'Grantía',
      o_1_taxes: 'Tasas',
      o_optional_taxes: 'Opcional con tasas',
      o_active: 'Sí',
      o_disabled: 'No',
      capture_docs_optional: 'Opcional',
      capture_docs_disabled: 'Desactivado',
      capture_docs_mandatory: 'Obligatorio',
    },
  },
  superUser: {
    selectAccount: 'Selecciona la cuenta',
    changeAccount: 'Cambiar de cuenta',
    accountName: 'Nombre de la cuenta',
    hotelName: 'Nombre del hotel',
    access: 'Entrar',
  },
  view: 'Ver',
  status: 'Estado',
  communicationChannels: {
    documentsBoxTitle: 'Puedes personalizar tus mails de una manera muy sencilla',
    emailsSuccess: 'Mailing addresses have been updated',
    senderCheckinEmailHeader: 'Remitente:',
    emailCheckinHeader: 'Enviar en copia a:',
    addNewMail: 'Añadir correo',
  },
  emptyHotelListError:
    'Para utilizar esta funcionalidad debes tener un hotel. Puedes añadirlos desde la sección "Hoteles"',
  user: {
    updateSuccess: 'Los datos del usuario se han actulizado correctamente',
    updateError: 'Error al actualizar los datos del usuario',
    newPassword: 'Contraseña nueva',
    currentPassword: 'Contraseña actual',
    emptyFields: 'Los campos no pueden estar vacíos',
    passwordSuccess: 'La contraseña se ha cambiado',
    sendInvitationWarning: 'Se enviará un email de invitación a #. ¿Quieres continuar?',
    sendInviteSuccess: 'Se ha enviado un correo para cambiar la contraseña al nuevo usuario',
    sendInviteError: 'Ya existe un usuario con este correo electrónico',
    emptyPhone: 'El número de teléfono no puede estar vacío',
    accountDetailTitle: 'Propietario de la cuenta',
    changePasswordTitle: 'Cambiar contraseña',
  },
  marketing: {
    buttonGenerator: 'Generador botón web',
    hotelCheckin: 'Check-In Hotel',
    chainCheckin: 'Check-In Cadena',
    links: 'Enlaces',
    selectHotel: 'Seleccionar hotel',
    example: 'Ejemplo de botón:',
    buttonText: 'Check-in Online',
    deeplink: 'DEEPLINK: Aumenta la conversión de tus Online Check-ins',
    option: 'Opción',
    comingSoon: 'Próximamente',
    deeplink1desc:
      'Utiliza parámetros básicos en la URL de los mails de Online Check-in de tus huéspedes para evitar acciones de búsqueda. Tan pronto como tu hotel este activo, tus invitados pueden realizar el check-in online sin buscar la reserva. Puede integrar el enlace en las plantillas de pre-estancia añadiendo los parámetros necesario para realizar la búsqueda.',
    deeplink2desc:
      'Utiliza el [BOOKING_IDENTIFIER] para generar un enlace único para cada reserva.',
    bookingCodeTitle: '¿Qué es el BOOKING CODE?',
    bookingCodeDesc: 'Es el localizador que ha recibido tu húesped en el momento de la reserva',
    entryDateTitle: '¿Qué es el ENTRANCE DATE?',
    entryDateDesc: 'Es la fecha de entrada de la reserva. Formato: YYYY-MM-DD',
    bookingIdentifierTitle: '¿Qué es el BOOKING IDENTIFIER?',
    bookingIdentifierDesc:
      'El booking identifier es el identificador único de una determinada reserva en tu PMS. Usalo como campo dinámico en tus mail de pre-instancia y aumente la conversión de tus Check-in online.',
    bookingIdentifierFindTitle: '¿Dónde encuentro el BOOKING IDENTIFIER?',
    bookingIdentifierFindDesc:
      'El booking identifier es un campo de tu PMS. Tu CRM debería poder obtenerlo de tu PMS, de la misma forma que obtiene el nombre del titular de la reserva,la fecha de entrada y otros detalles de que emplean en las comunicacinoes de reserva o pre-instancia.',

    // Booking.com
    deeplinkBooking: 'DEEPLINK: Aumenta la conversión de tus Online Check-ins con booking.com',
    deeplink1descBooking:
      'Añade la URL proporcionada a tus comunicaciones internas de Booking.com para impactar en el proceso de check-in de las reservas realizadas por los huéspedes a través de la plataforma',
    bookingCodeTitleBooking: '¿Dónde debo añadir el DEEPLINK de BOOKING.COM?',
    bookingCodeDescBookingLink: 'Sigue las instrucciones detalladas en el siguiente artículo',
    bookingCodeDescBooking:
      ' para configurar las comunicaciones internas de Booking.com y agregar el deeplink.',
  },
  selectPMS: {
    header: 'Selecciona tu PMS',
    search: 'Busca tu PMS...',
    button: 'Seleccionar',
    createDescription:
      'Puedes añadir el PMS de tu Hotel de manera personalizada si no lo encuentras en el listado de PMS',
    yourPMS: 'Tu PMS:',
    pmsInput: 'Introduce el nombre del PMS',
    create: 'Añadir PMS',
  },
  steps: {
    title: 'Embudo de conversión:',
    guestData: 'Formulario húespedes',
    surveys: 'Encuestas',
    upgrade: 'Upgrade',
    upsell: 'Upsell',
    payments: 'Pago',
    confirmPayment: 'Confirmación Pago',
    contracts: 'Contratos + Firma',
    confirm: 'Confirmación Checkin',
  },
  quickguide: {
    next: 'Siguiente',
    back: 'Atrás',
    skip: 'Omitir Guía',
    end: 'Terminar',
  },
  registerPaperless: {
    title: 'Información Cuenta',
    createAccount: 'Continuar',
    registerOk: 'El usuario se ha registrado correctamente',
    createButton: 'Añadir usuario',
  },
  paymentMethods: {
    emptyTitle: 'Añade tus médodos de pago',
    emptyDescription:
      'Puedes añadir tu tarjeta de crédito o débito o bien la información de tu cuenta bancaria (SEPA)',
    addedSuccess: 'Método de pago añadido correctamente',
    credit_card: 'Tarjeta de crédito',
    sepa: 'Adeudo directo SEPA',
    expires: 'Caduca',
  },
  pmsCategory: {
    pms_api: 'Configuración API',
    pms_hotel_data: 'Configuración datos del hotel',
    payments: 'Configuración pagos',
    address: 'Configuración dirección',
    checkin: 'Configuración checkin',
    checkout: 'Configuración checkout',
  },
  pmsName: {
    avalon2: 'Antfor (Canal Avalon)',
    avalon: 'Antfor (Canal Civitfun)',
    guestportal: 'Quohotel',
    navision: 'Suitech',
    timon: 'Timon Hotel',
    noray_new: 'Noray New',
  },
  paymentScreen: {
    connectSuccess: 'La conexión con la pasarela de pagos se ha realizado correctamente',
    connectError: 'Error al realizar la conexión con la pasarela de pagos',
    defaultSelector: 'Seleccionar',
    platform: 'Plataforma',
  },
  paymentName: {
    stripe: 'Stripe',
    trustpayments: 'Trust Payments',
    mercadopago: 'Mercado Pago',
    redsys: 'Redsys',
    addonpayments: 'Addon Payments',
    sipay: 'Sipay',
    paytef: 'Paytef',
    planet: 'Planet',
    paycomet: 'Paycomet',
    adyen: 'Adyen',
    montevideocomm: 'Montevideocomm',
    transbankwebpay: 'Transbank Webpay',
  },
  doorOpening: {
    add: 'Añadir mapeo de puertas',
    list: 'Lista de mapeo de puertas',
    remove: '¿Quieres borrar la apertura de puerta seleccionada?',
    roomName: 'Nombre de la habitación',
    pmsId: 'ID PMS',
    idOpening: 'ID sistema de apertura',
    import: 'Subir excel',
    importSuccess: '',
    importError: '',
    downloadTemplate: 'Descargar excel de muestra',
    templateFileName: 'Excel de muestra de mapeo de puertas',
    defaultSelector: 'Seleccionar',
    mantaintenanceTitle: 'Mantenimiento de puertas',
    mantaintenanceButton: 'Configurar',
    platform: 'Plataforma',
    connectionData: 'Datos de conexión',
    configuration: 'Configuración',
    checkinStatusOptions: {
      indifferent: 'Indiferente',
      checkedIn: 'Checkin realizado',
    },
    paymentStatusOptions: {
      indifferent: 'Indiferente',
      paid: 'Pagada',
    },
    keyType: 'Flujo de cerradura',
    keyTypes: {
      app: 'Aplicación',
      pin: 'Pin',
      qr: 'Código QR',
      link: 'Enlace',
    },
    checkinStatusLabel: 'Estado del check-in',
    checkinStatusPlaceholder: 'Selecciona el estado del check-in',
    paymentStatusLabel: 'Estado del pago',
    paymentStatusPlaceholder: 'Selecciona el estado del pago',
    checkinHourLabel: 'Hora del check-in',
    checkoutHourLabel: 'Hora del check-out',
    missingSuscription: {
      title: 'Obtener licencia',
      description:
        "Puede configurar Apertura de puertas para el hotel activando la licencia de 'Apertura de puertas'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    },
  },
  paymentCategory: {
    platform: 'Datos de conexión',
    payment_config: 'Configuración',
  },
  testOK: 'TEST OK',
  testKO: 'TEST KO',
  accountDetail: {
    functionality: 'Funcionalidad',
    requested: 'Pedido',
    active: 'Activo',
    activateDeactivate: 'Activar/Desactivar',
    billing: 'Funcionalidades',
    contract: 'Solicitar',
    activate: 'Activar',
    deactivate: 'Desactivar',
    available: 'Disponible',
    o_billing: {
      checkin: 'Check-in',
      tpaperless: 'T-Paperless',
      paperless_push: 'Paperless Push',
      upgrade: 'Upselling (Legacy)',
      upsell_module: 'Upselling 🆕',
      upsell: 'Cross-Selling (Legacy)',
      'cross-sell_module': 'Cross-Selling 🆕',
      payment: 'Protección de Pagos',
      door_opening: 'Apertura de Puertas',
      communication_tool: 'Guestlink',
      police_report: 'Registro de Viajeros',
      checkout: 'Check-out',
      pms: 'Integración con PMS',
      booking: 'Canal Booking.com',
      roomAssignment: 'Asignación de habitaciones',
      softCheckout: 'Soft checkout',
      kiosk: 'Auto Check-in',
      mts: 'Canal MTS',
      hbx: 'Canal HBX',
    },
    emailWarningTitle: 'Contratar funcionalidad',
    emailWarning:
      'Se notificará la solicitud y será gestionada lo antes posible por nuestro equipo de Customer Success',
    emailSuccess:
      'Hemos recibido la solicitud correctamente. Nos pondremos en contacto con usted lo antes posible.',
    dashboardWarning:
      'Nuestro equipo de Customer Success ha sido informado de su solicitud y en breve se pondrá en contacto con ustedes. Haciendo click en “OK” va a ser re-dirigido a la pantalla de ajustes de la funcionalidad seleccionada para su configuración y activación.',
  },
  mailTemplates: {
    template: 'Plantilla de email',
    enabled: 'Activa',
    type: 'Tipo de plantilla',
    templateName: 'Nombre de la plantilla',
    subject: 'Asunto',
    scheduling: 'Cuándo enviar',
    createTemplate: 'Crear plantilla',
    matchingTypeWarningTitle: 'Ya hay una plantilla de este tipo activa',
    matchingTypeWarning:
      'Sólo puede haber una plantilla activa para cada tipo de plantilla. ¿Sigue queriendo activar la plantilla seleccionada?',
    emailType: 'Tipo de email',
    marketing: 'Marketing',
    transactional: 'Transaccional',
    templateType: {
      ['pre-stay']: 'Pre-estancia',
      ['intra-stay']: 'Intra-estancia',
      ['post-stay']: 'Post-estancia',

      ['pre-stay-checkin-partial']: 'Check-in parcial (a cada huésped)',
      ['pre-stay-checkin-final']: 'Check-in finalizado (al titular)',
      ['pre-stay-before-entrance-24-hour']: '24h antes de la fecha de entrada',
      ['pre-stay-before-entrance-48-hour']: '48h antes de la fecha de entrada',
      ['pre-stay-before-entrance-72-hour']: '72h antes de la fecha de entrada',
      ['pre-stay-before-entrance-5-day']: '5 días antes de la fecha de entrada',
      ['pre-stay-before-entrance-1-week']: '1 semana antes de la fecha de entrada',
      ['pre-stay-before-entrance-2-week']: '2 semanas antes de la fecha de entrada',
      ['pre-stay-before-entrance-30-day']: '30 días antes de la fecha de entrada',
      ['pre-stay-police-report-notify-report-error']: 'Resumen reporte policial',

      ['intra-stay-checkout-partial']: 'Check-out parcial (a cada huésped)',
      ['intra-stay-checkout-final']: 'Check-out finalizado',
      ['intra-stay-on-entrance-0-hour']: 'En la fecha de entrada',
      ['intra-stay-after-entrance-24-hour']: '24h después de la fecha de entrada',
      ['intra-stay-before-departure-24-hour']: '24h antes de la fecha de salida',
      ['intra-stay-on-departure-0-hour']: 'En la fecha de salida',

      ['post-stay-after-departure-24-hour']: '24h después de la fecha de salida',
      ['post-stay-after-departure-48-hour']: '48h después de la fecha de salida',
      ['post-stay-after-departure-72-hour']: '72h después de la fecha de salida',

      ['pre-stay-payment-partial']: 'Confirmación de pago',
      ['pre-stay-payment-before-entrance-48-hour']: 'Pago 48h antes de la fecha de entrada',

      ['pre-stay-door-opening-on-checkin']: 'Mail de generación de llave al completar checkin',
      ['pre-stay-door-opening-0-hour']: 'Mail de generación de llave el día de la entrada',

      ['pre-stay-upsell-24-hour']: 'Upselling 24h antes de la fecha de entrada',
      ['pre-stay-upsell-48-hour']: 'Upselling 48h antes de la fecha de entrada',
      ['pre-stay-upsell-hotel-requested']: 'Solicitud de Upsell para el Hotelero',
      ['pre-stay-upsell-hotel-accepted']: 'Confirmación de Upsell para el Hotelero',
      ['pre-stay-upsell-hotel-rejected']: 'Rechazo de Upsell para el Hotelero',
      ['pre-stay-upsell-guest-accepted']: 'Confirmación de Upsell para el Huésped',
      ['pre-stay-upsell-guest-rejected']: 'Rechazo de Upsell para el Huésped',

      ['pre-stay-cross-sell-24-hour']: 'Cross-sell 24h antes de la fecha de entrada',
      ['pre-stay-cross-sell-48-hour']: 'Cross-sell 48h antes de la fecha de entrada',
      ['pre-stay-cross-sell-hotel-requested']: 'Solicitud de Cross-sell para el Hotelero',
      ['pre-stay-cross-sell-hotel-accepted']: 'Confirmación de Cross-sell para el Hotelero',
      ['pre-stay-cross-sell-hotel-rejected']: 'Rechazo de Cross-sell para el Hotelero',
      ['pre-stay-cross-sell-guest-accepted']: 'Confirmación de Cross-sell para el Huésped',
      ['pre-stay-cross-sell-guest-rejected']: 'Rechazo de Cross-sell para el Huésped',
    },
    suscriptionDisabledTitle: 'Obtener licencia de Guestlink',
    suscriptionDisabled:
      'Puede habilitar la comunicación de pre-estancia con el huésped activando la licencia de Guestlink. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted',
    suscriptionCTA: 'Contactarme sobre Guestlink',
    suscriptionRequestedTitle: 'Funcionalidad pendiente de activación',
    suscriptionRequested:
      'Ya ha hecho la petición para activar la funcionalidad. Nuestro equipo de Customer Success ha sido informado de su solicitud y en breve se pondrá en contacto con usted',
    errorSetState: 'Ha ocurrido un error al actualizar el estado de la plantilla',
    successSetState: 'El estado de la plantilla ha sido actualizado',
    sendFrom: 'Remitente',
    ccList: 'Lista CC',
    bccList: 'Lista BCC',
    recipient: 'Destinatario',
    language: 'Idioma',
    autofillLanguages: 'Autocompletar a todos los idiomas',
    back: 'Atrás',
    errorSendFrom: 'El remitente no es válido',
    errorCCList:
      'Error en la lista de correos en CC. Las direcciones deben ser válidas y estar separadas por comas',
    errorBCCList:
      'Error en la lista de correos en BCC. Las direcciones deben ser válidas y estar separadas por comas',
    addAttachment: 'Adjuntar archivo',
    testButton: 'Enviar mail de prueba',
    testMailDesc:
      'Introduce la dirección donde quieres enviar el mail de prueba (puedes enviar a más de uno separando los correos por comas)',
    sendButton: 'Enviar',
    errorTestEmailValidation: 'La dirección de envío no es válida',
    errorTestEmail: 'Error al enviar correo de prueba',
    successTestEmail: 'Se ha enviado el correo de prueba',
    invalidSize: 'El archivo supera el tamño máximo permitido de ',
    invalidFormat: 'Extensión del archivo no soportada',
    saveSuccess: 'Se han guardado los cambios de la plantilla',
    requestGuestlinkSuccess:
      'Nuestro equipo de Customer Success ha sido informado de su solicitud y en breve se pondrá en contacto con usted.',
    saveError: 'No se han podido guardar los datos',
    emailListHint: 'Inserte los correos separados por comas',
    sendFromHint: 'Dirección de correo',
    createSuccess: 'Se ha creado la nueva plantilla',
    createError: 'Error al crear la plantilla',
    deleteGuestlinkItemTitle: '¿Está seguro de querer eliminar la plantilla?',
    deleteGuestlinkItemDescription: 'Se eliminará la plantilla para el hotel seleccionado',
    deleteGuestlinkItemCTA: 'Eliminar plantilla',
    nameError: 'El nombre de la plantilla no es válido',
    scheduleError:
      'No es posible cambiar al tipo de plantilla solicitado sin una licencia de Guestlink activa',
    saveErrorSameTypeTitle: 'Ya existe una plantilla activa del mismo tipo',
    saveErrorSameTypeInfo:
      'No es posible cambiar al tipo de plantilla solicitado ya que actualmente existe una plantilla activa del mismo tipo',
    scheduleEmptyError: 'Debe seleccionar cuando quiere que se envíe el correo',
  },
  tagInserter: {
    hint: 'Seleccione un tag para la plantilla',
    button: 'Insertar',
    subjectHint: 'Seleccione un tag para el asunto',
    nameTagHint: 'Seleccione un tag',
    documentAddon: '.pdf',
  },
  paymentList: {
    locatorCol: 'Localizador del pago',
    bookingLocator: 'Localizador de la reserva',
    purchaseState: 'Estado',
    dateCol: 'Fecha del pago',
    tokenCol: 'Token del pago',
    amountCol: 'Cuantía',
  },
  paperlessPush: {
    title: 'Configuración pushes',
    pmsId: 'Id en el PMS',
    ipWhitelist: "IP's permitidas",
    apiKey: 'Clave API',
    pmsIdHint: 'Inserte el ID del hotel en el PMS',
    ipWhitelistHint: 'Inserte las direcciones IP separadas por comas',
    example: 'Ejemplo de enlace',
    errorWhitelist: 'Error de validación de las direcciones IP',
    disabledSuscription:
      "Este hotel no tiene paperless push activado, puedes activarlo desde la sección de funcionalidades en la pantalla 'Detalles de la cuenta'",
    activateSuscription: 'Activar funcionalidad',
    updateSuccess: 'Se ha actualizado la configuración correctamente',
    updateError: 'Error al actualizar la configuración de paperless push',
  },
  requestSuscription: {
    requestSent:
      'Hemos recibido la solicitud correctamente. Nos pondremos en contacto con usted lo antes posible.',
    send: 'Enviar solicitud',
  },
  payment: {
    missingSuscription: {
      title: 'Obtener licencia',
      description:
        "Puede configurar Protección de pagos para el hotel activando la licencia de 'Protección de pagos'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    },
  },
  policeReport: {
    extension: 'Extensión',
    completeFileName: 'Nombre completo del fichero',
    retrySendingMessage:
      'Para los mensajes de "Error en la plataforma de destino" pruebe a lanzar ese reporte de nuevo dentro de cada registro',
    captionError: 'Error detectado',
    captionOk: 'Procesado correctamente',
    captionPending: 'Enviado y pendiente del SES',
    dateCol: 'Fecha de check-in',
    statusCol: 'Estado / Error',
    hotelCol: 'Nombre de la propiedad',
    contractCol: 'Número de contrato',
    guestNameCol: 'Nombre del huésped',
    guestSurnameCol: 'Apellido del huésped',
    batchCol: 'Lote',
    actionCol: 'Acción',
    contractData: 'Datos del contrato',
    paymentData: 'Datos de pago',
    guestData: 'Datos del huésped',
    addressData: 'Datos de dirección',
    sendData: 'Guardar y enviar',
    showSendWarningTitle: 'Enviar',
    fileNotFound: 'No se ha encontrado el fichero en el SFTP para la fecha indicada',
    showSendWarning: 'Se van a enviar los datos definitivamente. ¿Desea continuar?',
    mandatoryFieldsError: 'Debe rellenar todos los datos del formulario',
    download: 'Descargar archivo SES',
    downloadSuccess: 'Se ha inicializado la descarga de los archivos',
    errorOnTargetPlatform: 'Error en plataforma de destino',
    downloadError: 'Error al descargar los archivos',
    noReportsErrorInFileValidation:
      'Tu archivo contiene errores que no permiten que se analicen los reportes',
    back: 'Atrás',
    sftpConfig: 'Configuración SFTP',
    ftpHost: 'URL',
    ftpUser: 'Usuario',
    ftpPass: 'Contraseña',
    ftpPort: 'Puerto',
    ftpFilePrefix: 'Prefijo',
    ftpFileSufix: 'Sufijo',
    fileFormat: 'Formato del archivo',
    ftpDirPath: 'Directorio del archivo',
    ftpFiles: 'Ficheros del directorio',
    explainRedColorFileValidation:
      'Si alguna línea está de {color rojo}, no se procesará ningún registro de ese fichero',
    ftpResult: 'Resultado de validación del file export',
    testFile: 'Fichero para validar',
    validateFileExport: 'Validar',
    validateSFTPWithoutErrors: 'El fichero validado no contiene ningún error',
    validateFileExportSuccess: 'La validación del fichero test.txt finalizó correctamente',
    validateFileExportError: 'La validación del fichero test.txt falló',
    ftpSuccess: 'Se ha guardado la configuración de SFTP correctamente',
    ftpError: 'La configuración de SFTP no se ha guardado correctamente',
    save: 'Guardar',
    sesConfig: 'Configuración SES',
    sesUser: 'Usuario',
    sesPass: 'Contraseña',
    lessorCode: 'Código de arrendador',
    establishmentCode: 'Código de establecimiento',
    sesSuccess: 'Se ha guardado la configuración de SES correctamente',
    sesError: 'La configuración de SES no se ha guardado correctamente',
    checkConnection: 'Comprobar conexión',
    checkConnectionSuccess: 'La conexión SFTP se ha realizado correctamente',
    checkConnectionError: 'Error al realizar la conexión SFTP',
    checkSESConnectionSuccess: 'La conexión con SES se ha realizado correctamente',
    checkSESConnectionError: 'Error al realizar la conexión con SES',
    forceDownloadTrigger: 'Forzar envío',
    forcedDownloadTrigger: 'Envío realizado',
    forceDownloadTriggerInfo:
      'La solicitud para enviar los datos de los huéspedes a SES ha sido lanzada',
    forceDownloadTriggerWarning: 'No puedes forzar el envío si hay registros de este día',
    forceDownloadTriggerError: 'Error al enviar la solicitud',
    erronInTheReport: 'Error en algún huésped de la reserva o en el contrato',
    missingSuscription: {
      title: 'Obtener licencia reporte policial',
      description:
        "Puede configurar el reporte policial para el hotel activando la licencia de 'Módulo reporte policial'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
      requestSent:
        'Hemos recibido la solicitud correctamente. Nos pondremos en contacto con usted lo antes posible.',
      send: 'Enviar solicitud',
    },
    edit: {
      error: 'Ha ocurrido un error al enviar los datos',
      success: 'Los datos se han enviado correctamente',
    },
    status: {
      ok: 'Procesado correctamente',
      error: 'Error',
      pending: 'Enviado y pendiente del SES',
    },
    detailFields: {
      reference: 'Referencia del contrato / reserva',
      date: 'Fecha del contrato',
      contractDate: 'Fecha del contrato',
      entrance: 'Fecha de entrada',
      departure: 'Fecha de salida',
      guests: 'Número de personas',
      numGuests: 'Número de personas',
      paymentType: 'Tipo de pago',
      role: 'Rol',
      roleType: 'Rol',
      name: 'Nombre',
      surname: 'Primer apellido',
      secondSurname: 'Segundo apellido',
      contactType: 'Tipo de dato de contacto',
      contact: 'Contacto',
      contactValue: 'Contacto',
      documentType: 'Tipo de documento',
      documentNumber: 'Número del documento',
      supportDocument: 'Número de soporte',
      country: 'País',
      address: 'Dirección',
      municipality: 'Municipio',
      municipalityName: 'Nombre del municipio',
      municipalityCode: 'Código del municipio',
      zipCode: 'Código postal',
      documentSupport: 'Número de soporte',
      familyRelationship: 'Relación parentesco',
      relationship: 'Relación parentesco',
      birthDate: 'Fecha de nacimiento',
      childrenCount: 'Número de niños',
      requiredFields: 'Falta el campo requerido:',
      fieldValidation: 'Error de validación del campo:',
      nationality: 'Nacionalidad',
    },
    error: {
      zipCode: 'El formato del código postal no es correcto',
      documentNumber: 'El formato del número de documento no es correcto',
      emailError: 'El formato de la dirección de e-mail no es correcto',
      birthDate: 'La fecha de nacimiento no es correcta',
      documentSupport: 'El formato del número de soporte no es correcto',
    },
    paymentType: {
      EFECT: 'Efectivo',
      TARJT: 'Tarjeta de crédito',
      TRANS: 'Transacción',
      PLATF: 'Plataforma de pago',
      MOVIL: 'Pago por móvil',
      TREG: 'Tarjeta regalo',
      DESTI: 'Pago en destino',
      OTRO: 'Otros medios de pago',
    },
    role: {
      TI: 'Titular del contrato',
      VI: 'Viajero',
    },
    contactType: {
      telefono: 'Móvil / Teléfono fijo',
      email: 'E-mail',
    },
    documentType: {
      passport: 'PASSPORT',
      dni: 'DNI',
      idCard: 'ID CARD',
      residencePermit: 'PERMISO RESIDENCIA',
      drivingLicence: 'PERMISO DE CONDUCIR',
      other: 'OTHER',
    },
    sideItem: {
      send: 'Enviar',
      booking: {
        title: 'Estado de la reserva',
        header: 'Check-ins',
        desc: 'Número de huéspedes que han realizado el check-in',
      },
      checkin: {
        title: 'Informe Policial',
        header: 'Enviado a la Policía',
        desc: 'Número de check-ins en línea enviados a la policía',
      },
      send: {
        header: 'Enviar datos manualmente',
        desc: 'Puede enviar manualmente esta información a SES después de editar los campos faltantes',
      },
    },
  },
  missingSuscription: {
    title: 'Obtener licencia',
    description:
      "Puede configurar $1 para el hotel activando la licencia de 'Módulo $1'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    requestSent:
      'Hemos recibido la solicitud correctamente. Nos pondremos en contacto con usted lo antes posible.',
    send: 'Enviar solicitud',
  },
  upsell,
  crossSell,
  guestlink: {
    host: 'SMTP host',
    hostPlaceholder: 'Introduce la url del servidor SMTP example.domain.com',
    port: 'Puerto',
    securityLayer: 'Cifrado',
    smtpAuth: 'Autenticación SMTP',
    user: 'Usuario',
    userPlaceholder: 'Usuario del SMTP',
    password: 'Contraseña',
    passwordPlaceholder: 'Introduce la contraseña del SMTP',
    defaultSender: 'Remitente por defecto',
    defaultSenderPlaceholder: 'Introduce tu email example@email.com',
    enabled: 'Activado',
    disabled: 'Desactivado',
    save: 'Guardar',
    sendTestEmail: 'Enviar mail de prueba',
    checkConnection: 'Comprobar conexión',
    smtpSuccess: 'Se ha guardado la configuración de SMTP correctamente',
    smtpError: 'La configuración de SMTP no se ha guardado correctamente',
    checkConnectionSuccess: 'La conexión SMTP se ha realizado correctamente',
    checkConnectionError: 'Error al realizar la conexión SMTP',
    testEmailSuccess: 'Se ha enviado el email de prueba correctamente',
    testEmailError: 'Error al enviar el email de prueba',
    requiredFieldsError: 'Debe rellenar todos los campos',
    smtpNotConfigured: 'Debe guardar la configuración SMTP',
    missingSuscription: {
      title: 'Obtener licencia',
      description:
        "Puede configurar Guestlink para el hotel activando la licencia de 'Guestlink'. Haga clic en el botón de abajo y el equipo de Customer Success se pondrá en contacto con usted",
    },
  },
  billingConfig: {
    billingInfo: {
      title: 'Información de facturación',
      companyLegalName: 'Nombre legal de la empresa',
      companyCommercialName: 'Nombre comercial de la empresa',
      companyCountryCode: 'País',
      billingAddress: 'Dirección',
      billingMunicipality: 'Municipio',
      billingProvince: 'Provincia',
      taxIdentificationNumber: 'Número de identificación fiscal',
      billingContactName: 'Nombre del contacto de facturación',
      billingContactEmail: 'Email del contacto de facturación',
      billingPhoneNumber: 'Número de teléfono de facturación',
      companyLegalName_hint: 'Nombre legal de la empresa',
      companyCommercialName_hint: 'Nombre comercial de la empresa',
      companyCountryCode_hint: 'País de la empresa',
      billingAddress_hint: 'Dirección, zip/código postal',
      billingMunicipality_hint: 'Municipio',
      billingProvince_hint: 'Provincia',
      taxIdentificationNumber_hint: 'Número de identificación fiscal',
      billingContactName_hint: 'Nombre y apellidos',
      billingContactEmail_hint: 'mail@email.com',
      billingPhoneNumber_hint: '+34 666888999',
      saveButton: 'Guardar',
      editButton: 'Editar',
      cancelButton: 'Cancelar',
      saveMessageOK: 'La información de facturación se ha cambiado correctamente',
      invalidEmailError: 'La dirección de correo electrónico no es válida',
      formHasErrors: 'El formulario tiene campos vacíos o incorrectos',
    },
    billingStatus: {
      title: {
        OK: 'Información de facturación completado',
        KO: 'Completa la información de facturación',
      },
      text: {
        OK: 'Gracias por proporcionar la información de facturación necesaria. Tus detalles han sido agregados exitosamente. Una vez que se necesite generar una nueva factura, tu información será incluida y la factura será enviada de inmediato al contacto designado.',
        KO: 'Para poder garantizar la precisión de vuestra información en nuestras facturas y asegurarnos de enviarlas al contacto adecuado, es imprescindible que completes la información de facturación.',
      },
    },
    billingContract: {
      title: 'Gestión de contratos',
      active: 'Activo',
      inactive: 'Inactivo',
      uploadButton: 'Carga de Facturación',
      modal: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        title: 'Selecciona la categoría',
        subtitle: 'Selecciona la categoria que quieres crear',
        title_contract: 'Contrato',
        title_invoice: 'Factura',
        title_license: 'Licencia',
        option: {
          contract: 'Contrato',
          license: 'Licencia',
          invoice: 'Factura',
        },
        contract: {
          save: 'Guardar',
          delete: 'Borrar',
        },
      },
      table: {
        id: 'ID contrato',
        name: 'Nombre del contrato',
        signatureDate: 'Fecha de firma',
        status: 'Estado',
        actions: 'Acciones',
        empty_contract: 'No tienes ningún contrato',
        contact_support: 'Por favor, contacta con nuestro equipo de soporte',
      },
      uploadContract: 'Subir contrato',
      contractName: 'Nombre del contrato',
      contractName_hint: 'Añade el nombre del contrato',
      signatureDate: 'Fecha de firma',
      signatureDate_hint: 'dd-mm-YYYY',
      orderNumber: 'Número de pedido',
      orderNumber_hint: 'ORD-123456Z',
      fileUpload: {
        title: 'Selecciona un archivo',
        hint: 'JPG, PNG o PDF, tamaño del archivo no más de 10MB',
        invalid: 'El archivo no es válido',
        OK: 'Archivo subido correctamente',
        KO: 'Error al subir, por favor inténtalo de nuevo',
      },
      saveContractMessageOK: 'El contrato se ha creado correctamente',
      formHasErrors: 'El formulario contiene campos vacíos o incorrectos',
      deleteHotelContractMessageOK: 'El contrato se ha eliminado correctamente',
      editContractMessageOK: 'El contrato se ha actualizado correctamente',
    },
    licence: {
      title: 'Gestión de licencias',
      inactive: 'Inactivo',
      active: 'Activo',
      deleteHotelLicenceMessageOK: 'La licencia se ha eliminado correctamente',
      editLicenceMessageOK: 'La licencia se ha actualizado correctamente',
      saveLicenceMessageOK: 'La licencia se ha creado correctamente',
      table: {
        id: 'ID licencia',
        name: 'Licencia',
        contractId: 'ID contrato',
        hotelName: 'Hotel',
        type: 'Tipo',
        type_annual: 'Anual',
        type_monthly: 'Mensual',
        type_bimonthly: 'Bimensual',
        type_quarterly: 'Trimestral',
        type_four_monthly: 'Cuatrimestral',
        type_biannual: 'Semestral',
        type_two_yearly: 'Dos años',
        type_oneshot: 'Única',
        startDate: 'Inicio',
        renewalDate: 'Renovación',
        endDate: 'Finalización',
        status: 'Estado',
        price: 'Precio',
        actions: 'Acciones',
        empty_licence: 'No tienes ninguna licencia',
        contact_support: 'Por favor, contacta con nuestro equipo de soporte',
      },
      modal: {
        title: 'Licencia',
        delete: 'Borrar',
        save: 'Guardar',
        cancel: 'Cancelar',
      },
      types: {
        setup: 'Set Up',
        integration_cost: 'Coste de la Integración',
        checkin: 'Check-in',
        upselling: 'Upselling',
        crossselling: 'Cross-Selling',
        payment_protection: 'Protección de Pagos',
        door_opening: 'Apertura de Puertas',
        guestlink: 'Guestlink',
        guest_registration: 'Registro de Viajeros',
        checkout: 'Check-out',
        push: 'Push Paperless',
        paperless: 'T-Paperless',
        ftp: 'FTP',
        multitablet_option: 'Multitablet',
        grs: 'Group Reservation System',
        booking_engine: 'Booking Engine',
        hcn: 'Hotel Confirmation Number',
        document_scanner: 'Document Scanner',
      },
      form: {
        licence: 'Licencia',
        licence_hint: 'Selecciona una licencia',
        contract: 'Contrato',
        contract_hint: 'Contrato asociado a la licencia',
        start_date: 'Fecha de inicio',
        start_date_hint: 'dd-mm-yyyy',
        price: 'Precio',
        price_hint: 'Añade el precio',
        periodity: 'Periodicidad',
        periodity_hint: 'Selecciona la periodicidad',
        currency_hint: '-',
        currency_eur: '€',
        currency_usd: '$',
        invalidLicenceError: 'Campo vacío o licencia repetida en otro contrato',
      },
    },
    invoice: {
      title: 'Gestión de facturas',
      pending: 'Pendiente',
      paid: 'Pagado',
      overdue: 'Vencida',
      saveInvoiceMessageOK: 'La facturase ha creado correctamente',
      formHasErrors: 'El formulario contiene campos vacíos o incorrectos',
      deleteHotelInvoiceMessageOK: 'La factura se ha eliminado correctamente',
      editInvoiceMessageOK: 'La factura se ha actualizado correctamente',
      form: {
        uploadInvoice: 'Upload invoice',
        invoiceId: 'ID factura',
        invoiceId_hint: 'Añade el ID de la factura',
        invoiceInternalId: 'ID Holded',
        invoiceInternalId_hint: 'Añade el ID de Holded',
        date: 'Fecha de factura',
        date_hint: 'dd-mm-aaaa',
        contractId: 'Contrato',
        contractId_hint: 'Contrato asociado a la factura',
        amount: 'Importe',
        amount_hint: 'Añade el importe',
        currency_hint: '-',
        currency_eur: '€',
        currency_usd: '$',
        status: 'Estado de la factura',
        status_hint: 'Estado actual de la factura',
        invalidInvoiceIdError: 'Campo vacío o ID repetida en otra factura',
        invalidInvoiceInternalIdError: 'Campo vacío o ID repetida en otra factura',
      },
      table: {
        id: 'ID factura',
        date: 'Fecha',
        contractId: 'ID contrato',
        hotelName: 'Hotel',
        status: 'Estado',
        amount: 'Importe',
        actions: 'Acciones',
        empty_invoice: 'No tienes ninguna factura',
        invoice_status_pending: 'Pendiente',
        invoice_status_paid: 'Pagado',
        invoice_status_overdue: 'Vencido',
      },
      modal: {
        title: 'Factura',
        cancel: 'Cancelar',
        save: 'Guardar',
        delete: 'Borrar',
      },
      fileUpload: {
        title: 'Selecciona un archivo',
        hint: 'JPG, PNG o PDF, tamaño del archivo no más de 10MB',
        invalid: 'El archivo no es válido',
        OK: 'Archivo subido correctamente',
        KO: 'Error al subir, por favor inténtalo de nuevo',
      },
    },
    countryCodes: {
      CODE_AF: 'Afganistán',
      CODE_AX: 'Åland',
      CODE_AL: 'Albania',
      CODE_DZ: 'Argelia',
      CODE_AS: 'Samoa Americana',
      CODE_AD: 'Andorra',
      CODE_AO: 'Angola',
      CODE_AI: 'Anguilla',
      CODE_AQ: 'Antártida',
      CODE_AG: 'Antigua y Barbuda',
      CODE_AR: 'Argentina',
      CODE_AM: 'Armenia',
      CODE_AW: 'Aruba',
      CODE_AU: 'Australia',
      CODE_AT: 'Austria',
      CODE_AZ: 'Azerbaiyán',
      CODE_BS: 'Bahamas',
      CODE_BH: 'Bahrein',
      CODE_BD: 'Bangladesh',
      CODE_BB: 'Barbados',
      CODE_BY: 'Bielorrusia',
      CODE_BE: 'Bélgica',
      CODE_BZ: 'Belice',
      CODE_BJ: 'Benin',
      CODE_BM: 'Bermudas',
      CODE_BT: 'Bután',
      CODE_BO: 'Bolivia',
      CODE_BQ: 'Bonaire',
      CODE_BA: 'Bosnia y Herzegovina',
      CODE_BW: 'Botswana',
      CODE_BV: 'Isla Bouvet',
      CODE_BR: 'Brasil',
      CODE_IO: 'Raj Brítanico',
      CODE_BN: 'Brunei',
      CODE_BG: 'Bulgaria',
      CODE_BF: 'Burkina Faso',
      CODE_BI: 'Burundi',
      CODE_KH: 'Camboya',
      CODE_CM: 'Camerún',
      CODE_CA: 'Canadá',
      CODE_CV: 'Cabo Verde',
      CODE_KY: 'Islas Caimán',
      CODE_CF: 'República Centroafricana',
      CODE_TD: 'Chad',
      CODE_CL: 'Chile',
      CODE_CN: 'China',
      CODE_CX: 'Isla de Christmas',
      CODE_CC: 'Islas de Cocos o Keeling',
      CODE_CO: 'Colombia',
      CODE_KM: 'Comores',
      CODE_CG: 'Congo',
      CODE_CD: 'Congo, República Democrática del',
      CODE_CK: 'Islas Cook',
      CODE_CR: 'Costa Rica',
      CODE_CI: 'Costa de Marfíl',
      CODE_HR: 'Croacia (Hrvatska)',
      CODE_CU: 'Cuba',
      CODE_CW: 'Curaçao',
      CODE_CY: 'Chipre',
      CODE_CZ: 'República Checa',
      CODE_DK: 'Dinamarca',
      CODE_DJ: 'Djibouti',
      CODE_DM: 'Dominica',
      CODE_DO: 'República Dominicana',
      CODE_EC: 'Ecuador',
      CODE_EG: 'Egipto',
      CODE_SV: 'El Salvador',
      CODE_GQ: 'Guinea Ecuatorial',
      CODE_ER: 'Eritrea',
      CODE_EE: 'Estonia',
      CODE_ET: 'Etiopía',
      CODE_FK: 'Islas Malvinas',
      CODE_FO: 'Islas Faroe',
      CODE_FJ: 'Fiji',
      CODE_FI: 'Finlandia',
      CODE_FR: 'Francia',
      CODE_GF: 'Guayana Francesa',
      CODE_PF: 'Polinesia Francesa',
      CODE_TF: 'Territorios franceses del Sur',
      CODE_GA: 'Gabón',
      CODE_GM: 'Gambia',
      CODE_GE: 'Georgia',
      CODE_DE: 'Alemania',
      CODE_GH: 'Ghana',
      CODE_GI: 'Gibraltar',
      CODE_GR: 'Grecia',
      CODE_GL: 'Groenlandia',
      CODE_GD: 'Granada',
      CODE_GP: 'Guadalupe',
      CODE_GU: 'Guam',
      CODE_GT: 'Guatemala',
      CODE_GG: 'Guernsey',
      CODE_GN: 'Guinea',
      CODE_GW: 'Guinea-Bissau',
      CODE_GY: 'Guayana',
      CODE_HT: 'Haití',
      CODE_HM: 'Islas Heard y McDonald',
      CODE_VA: 'Ciudad del Vaticano (Santa Sede)',
      CODE_HN: 'Honduras',
      CODE_HK: 'Hong Kong',
      CODE_HU: 'Hungría',
      CODE_IS: 'Islandia',
      CODE_IN: 'India',
      CODE_ID: 'Indonesia',
      CODE_IR: 'Irán',
      CODE_IQ: 'Irak',
      CODE_IE: 'Irlanda',
      CODE_IM: 'Isla de Man',
      CODE_IL: 'Israel',
      CODE_IT: 'Italia',
      CODE_JM: 'Jamaica',
      CODE_JP: 'Japón',
      CODE_JE: 'Jersey',
      CODE_JO: 'Jordania',
      CODE_KZ: 'Kazajistán',
      CODE_KE: 'Kenia',
      CODE_KI: 'Kiribati',
      CODE_KP: 'Corea del Norte',
      CODE_KR: 'Corea',
      CODE_KW: 'Kuwait',
      CODE_KG: 'Kirguizistán',
      CODE_LA: 'Laos',
      CODE_LV: 'Letonia',
      CODE_LB: 'Líbano',
      CODE_LS: 'Lesotho',
      CODE_LR: 'Liberia',
      CODE_LY: 'Libia',
      CODE_LI: 'Liechtenstein',
      CODE_LT: 'Lituania',
      CODE_LU: 'Luxemburgo',
      CODE_MO: 'Macao',
      CODE_MK: 'Macedonia, Ex-República Yugoslava de',
      CODE_MG: 'Madagascar',
      CODE_MW: 'Malawi',
      CODE_MY: 'Malasia',
      CODE_MV: 'Maldivas',
      CODE_ML: 'Malí',
      CODE_MT: 'Malta',
      CODE_MH: 'Islas Marshall',
      CODE_MQ: 'Martinica',
      CODE_MR: 'Mauritania',
      CODE_MU: 'Mauricio',
      CODE_YT: 'Mayotte',
      CODE_MX: 'México',
      CODE_FM: 'Micronesia',
      CODE_MD: 'Moldavia',
      CODE_MC: 'Mónaco',
      CODE_MN: 'Mongolia',
      CODE_ME: 'Montenegro',
      CODE_MS: 'Montserrat',
      CODE_MA: 'Marruecos',
      CODE_MZ: 'Mozambique',
      CODE_MM: 'Birmania',
      CODE_NA: 'Namibia',
      CODE_NR: 'Nauru',
      CODE_NP: 'Nepal',
      CODE_NL: 'Países Bajos',
      CODE_NC: 'Nueva Caledonia',
      CODE_NZ: 'Nueva Zelanda',
      CODE_NI: 'Nicaragua',
      CODE_NE: 'Níger',
      CODE_NG: 'Nigeria',
      CODE_NU: 'Niue',
      CODE_NF: 'Norfolk',
      CODE_MP: 'Islas Marianas del Norte',
      CODE_NO: 'Noruega',
      CODE_OM: 'Omán',
      CODE_PK: 'Paquistán',
      CODE_PW: 'Islas Palau',
      CODE_PS: 'Territorios palestinos ocupado',
      CODE_PA: 'Panamá',
      CODE_PG: 'Papúa Nueva Guinea',
      CODE_PY: 'Paraguay',
      CODE_PE: 'Perú',
      CODE_PH: 'Filipinas',
      CODE_PN: 'Pitcairn',
      CODE_PL: 'Polonia',
      CODE_PT: 'Portugal',
      CODE_PR: 'Puerto Rico',
      CODE_QA: 'Qatar',
      CODE_RE: 'Reunión',
      CODE_RO: 'Rumania',
      CODE_RU: 'Rusia',
      CODE_RW: 'Ruanda',
      CODE_BL: 'San Bartolomé',
      CODE_SH: 'Santa Helena',
      CODE_KN: 'Saint Kitts y Nevis',
      CODE_LC: 'Santa Lucía',
      CODE_MF: 'San Martin',
      CODE_PM: 'St. Pierre y Miquelon',
      CODE_VC: 'San Vicente y Granadinas',
      CODE_WS: 'Samoa',
      CODE_SM: 'San Marino',
      CODE_ST: 'Santo Tomé y Príncipe',
      CODE_SA: 'Arabia Saudí',
      CODE_SN: 'Senegal',
      CODE_RS: 'Serbia',
      CODE_SC: 'Seychelles',
      CODE_SL: 'Sierra Leona',
      CODE_SG: 'Singapur',
      CODE_SX: 'Sint Maarten',
      CODE_SK: 'República Eslovaca',
      CODE_SI: 'Eslovenia',
      CODE_SB: 'Islas Salomón',
      CODE_SO: 'Somalia',
      CODE_ZA: 'República de Sudáfrica',
      CODE_GS: 'Islas Georgias del Sur y Sandwich del Sur',
      CODE_SS: 'Sudán del sur',
      CODE_ES: 'España',
      CODE_ES_CANARY: 'España - Islas Canarias',
      CODE_LK: 'Sri Lanka',
      CODE_SD: 'Sudán',
      CODE_SR: 'Surinam',
      CODE_SJ: 'Islas Svalbard y Jan Mayen',
      CODE_SZ: 'Suazilandia',
      CODE_SE: 'Suecia',
      CODE_CH: 'Suiza',
      CODE_SY: 'Siria',
      CODE_TW: 'Taiwán',
      CODE_TJ: 'Tayikistán',
      CODE_TZ: 'Tanzania',
      CODE_TH: 'Tailandia',
      CODE_TL: 'Timor Oriental',
      CODE_TG: 'Togo',
      CODE_TK: 'Islas Tokelau',
      CODE_TO: 'Tonga',
      CODE_TT: 'Trinidad y Tobago',
      CODE_TN: 'Túnez',
      CODE_TR: 'Turquía',
      CODE_TM: 'Turkmenistán',
      CODE_TC: 'Islas Turks y Caicos',
      CODE_TV: 'Tuvalu',
      CODE_UG: 'Uganda',
      CODE_UA: 'Ucrania',
      CODE_AE: 'Emiratos Árabes Unidos',
      CODE_GB: 'Reino Unido',
      CODE_US: 'Estados Unidos',
      CODE_UM: 'Islas menores de Estados Unidos',
      CODE_UY: 'Uruguay',
      CODE_UZ: 'Uzbekistán',
      CODE_VU: 'Vanuatu',
      CODE_VE: 'Venezuela',
      CODE_VN: 'Vietnam',
      CODE_VG: 'Islas Vírgenes (Reino Unido)',
      CODE_VI: 'Islas Vírgenes (EE.UU.)',
      CODE_WF: 'Islas Wallis y Futuna',
      CODE_EH: 'Sahara Occidental',
      CODE_YE: 'Yemen',
      CODE_ZM: 'Zambia',
      CODE_ZW: 'Zimbabue',
      CODE_XI: 'Irlanda del Norte',
    },
  },
  onboarding: {
    pms: {
      title: 'Conéctate con tu PMS',
      subtitle: '¡Selecciona tu PMS, configúralo y prueba tu flujo de check-in en minutos!',
      pmsConfiguration: 'Configuración del PMS',
      checkinForm: 'Formulario de check-in',
      bookingSearch: 'Búsqueda de reservas',
      selectConfPms: 'Selecciona y configura tu PMS',
      selectConfPmsDescription:
        'Elige tu PMS para conectar de la lista a continuación y configúralo.',
      continue: 'continuar',
    },
  },
  validation: {
    guest: {
      description:
        'Verifica que los datos del huésped se guarden correctamente en el PMS y puedan recuperarse para el módulo Paperless.',
      bookingIdentifier: 'Identificador de Reserva',
      activateCustomMock: 'Personalizar los datos del huesped',
      button: 'Test',
      response: {
        variableName: 'Campo',
        civitfunData: 'Civitfun',
        pmsData: 'PMS',
      },
    },
    payment: {
      paymentTitle: 'Validación de pagos',
      description: 'Verifica la conexión con la pasarela de pagos:',
      bookingIdentifier: 'Identificador de la reserva:',
      testPayment: 'Comprobar conexión',
      url: 'URL para poder acceder a la pasarela de pagos:',
    },
  },
  formFieldsInference: {
    button: 'Autocompletar selección de campos',
    description:
      'Comprobaremos en tu PMS los campos que recoges en el check-in de tus huéspedes y te los recomendaremos automáticamente, seleccionándolos en el formulario. Esta acción eliminará la selección de campos actual.',
  },
  selector: {
    tabs: {
      onboardingTitle: 'Validación de Check-in',
      onboardingDescription:
        'Valida el proceso completo de check-in, garantizando la conexión con el PMS, la correcta búsqueda de reservas y el envío de datos entre PMS y Civitfun.',
      validationConection: 'Conexión',
      validationSearches: 'Búsqueda de reserva',
      validationSendData: 'Envío de datos a PMS',
      validationPayments: 'Pagos',
      validationTestAll: 'Flujo de check-in completo',
      description:
        'Valida el proceso completo de check-in, garantizando la conexión con el PMS, la correcta búsqueda de reservas y el envío de datos entre PMS y Civitfun.',
      results: 'Resultado de las búsquedas',
      warning: 'Campos incompletos',
      warningDescription:
        'Por favor, ingrese el valor faltante en Localizador reserva y Fecha de entrada.',
    },
  },
  searchesResults: {
    searchByCodeResult: 'Búsqueda por Código',
    searchByEntranceDateResult: 'Búsqueda por Fecha de Entrada',
    searchByHolderNameResult: 'Búsqueda por nombre del titular',
    searchByIdResult: 'Búsqueda por Id',
  },
  alertChangeModule: {
    title: '¿Seguro que deseas {action} el módulo {module} para todos los hoteles?',
    content: 'Esta acción va a {action} el módulo {module} para todos los hoteles de la cadena.',
    cancelMessage: 'Acción cancelada',
  },
  pmsUdf: {
    title: 'Gestión de UDFs',
    form: {
      addUdf: 'Añadir UDF',
      editUdf: 'Editar UDF',
      name: 'Nombre UDF',
      field: 'Campo en Civitfun',
      add: 'Añadir',
      edit: 'Guardar cambios',
      cancel: 'Cancelar',
    },
    table: {
      title: 'UDFs',
      id: 'ID',
      name: 'Nombre',
      referenceField: 'Campo referencia',
      edit: 'Editar',
    },
    deleteWarning: {
      title: '¿Quieres eliminar el UDF?',
      description:
        'Esta acción no se puede deshacer. Se perderán todos los datos del UDF seleccionado',
      remove: 'Eliminar UDF',
      cancel: 'Cancelar',
    },
    removeError: 'Error al eliminar el UDF',
    removeSuccess: 'El UDF ha sido eliminado',
    updateSuccess: 'El UDF ha sido actualizado',
    updateError: 'Error al actualizar el UDF',
    createSuccess: 'El UDF ha sido creado',
    createError: 'Error al crear el UDF',
  },
  createBooking: {
    create: 'Crear reserva',
    steps: {
      selectDate: {
        title: 'Nueva reserva',
        description: 'Crea una reserva de forma sencilla con una configuración personalizada',
        startDate: 'Fecha de entrada',
        startDatePlaceholder: 'Seleccionar fecha',
        endDate: 'Fecha de salida',
        endDatePlaceholder: 'Seleccionar fecha',
        button: 'Buscar',
      },
      selectRooms: {
        title: 'Seleccionar habitaciones',
        description: 'Escoge las habitaciones necesarias para la reserva',
        bookingDate: 'Fecha de reserva',
        room: 'Habitación',
        price: 'Precio',
        available: 'Disponible',
        adults: 'Adultos',
        children: 'Niños',
        rooms: 'Habitaciones',
        add: 'Añadir',
        select: 'Seleccionar',
        roomTypes: {
          single: 'Individual',
          standardDouble: 'Doble Estándar',
          doubleDelux: 'Doble Delux',
          suite: 'Suite',
        },
      },
      guestDetails: {
        title: 'Información de los huéspedes',
        description: 'Introduce los datos del titular de la reserva',
        holderData: 'Datos del titular',
        name: 'Nombre',
        surname: 'Apellidos',
        phone: 'Teléfono',
        email: 'Email',
      },
      bookingSummary: {
        title: 'Información de la reserva',
        description: 'Desglose de la reserva y los datos para su confirmación',
        guestDetails: {
          title: 'Datos del titular',
          name: 'Nombre',
          email: 'Email',
          phone: 'Teléfono',
        },
        booking: {
          title: 'Datos de la reserva',
          adult: 'adulto',
          adults: 'adultos',
          child: 'niño',
          children: 'niños',
        },
        payment: {
          title: 'Detalles del pago',
          concept: 'Concepto',
          price: 'Precio',
          subtotal: 'Subtotal',
          total: 'Total',
        },
      },
    },
    continue: 'Continuar',
    finalize: 'Finalizar Reserva',
    createBookingError: 'Error al crear la reserva',
    createBookingSuccess: 'Reserva creada con éxito',
  },
  team: {
    newUser: 'AÑADIR UN NUEVO USUARIO',
    newUserDetail: {
      name: 'Nombre',
      email: 'Email',
      invite: 'Invitar',
    },
    users: 'USUARIOS',
    usersDetail: {
      name: 'Nombre',
      email: 'E-mail',
      permission: 'Permiso',
      actions: 'Acción',
      deletePop: 'Eliminar usuario',
      editPop: 'Editar usuario',
      deleteConfirmTitle: 'Are you sure you want to delete the user?',
      deleteConfirmDescription:
        'This action cannot be undone. All user data and permissions will be lost.',
      cancel: 'Cancelar',
      delete: 'Eliminar usuario',
      deleteSuccess: 'Usuario eliminado correctamente',
      deleteError: 'Error al eliminar el usuario',
    },
  },
  team: {
    newUser: 'AÑADIR UN NUEVO USUARIO',
    newUserDetail: {
      name: 'Nombre',
      email: 'Email',
      hotel: 'Hoteles',
      permission: 'Tipo de permiso',
      permissions: {
        admin: 'Administrador',
        adminDescription: 'Administra la cuenta, configura productos y gestiona datos, solicitudes y errores en los dashboards.',
        user: 'Usuario',
        userDescription: 'Gestiona datos, solicitudes y errores en los dashboards.',
        guest: 'Invitado',
        guestDescription: 'Visualiza datos, solicitudes y errores en los dashboards.',
      },
      invite: 'Invitar',
    },
    users: 'USUARIOS',
    usersDetail: {
      name: 'Nombre',
      email: 'E-mail',
      permission: 'Permiso',
      status: 'Estado',
      activeStatus: 'Creado',
      pendingStatus: 'Pendiente',
      hotel: 'Hoteles',
      actions: 'Acción',
      deletePop: 'Eliminar usuario',
      editPop: 'Editar usuario',
      deleteConfirmTitle: '¿Está seguro de que desea eliminar el usuario?',
      deleteConfirmDescription: 'Esta acción no se puede deshacer. Se perderán todos los datos y permisos del usuario.',
      cancel: 'Cancelar',
      delete: 'Eliminar usuario',
      deleteSuccess: 'Usuario eliminado correctamente',
      deleteError: 'Error al eliminar el usuario',
      edit: 'Editar usuario',
      editDescription: 'Puedes editar la información de usuario, así como su configuración y sus permisos.',
      editSuccess: 'Usuario editado correctamente',
      editError: 'Error al editar el usuario',
      save: 'Guardar cambios',
    },
  },
};

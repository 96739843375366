import React from 'react';
import styles from './CustomButton.module.css';

export default function CustomButton({
  text,
  leftComponent,
  rightComponent,
  background,
  color,
  onClick,
  fontSize,
  children,
  disabled,
  noShadow,
  ...props
}) {
  let className = styles.button;
  if (disabled) {
    className = `${className} ${styles.buttonDisabled}`;
  } else if (noShadow) {
    className = `${className} ${styles.buttonNoShadow}`;
  }
  
  return (
    <div
      className={className}
      style={!disabled ? { background, color } : {}}
      onClick={!disabled && onClick ? onClick : null}
      {...props}
    >
      {leftComponent && leftComponent}
      <b style={{ fontSize }}>{text || children}</b>
      {rightComponent && rightComponent}
    </div>
  );
}

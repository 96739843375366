import React, { useContext, useRef } from 'react';
import { Button, Layout, Menu, Modal, Row } from 'antd';
import styles from './DefaultLayout.module.css';
import { AppContext } from '../../App.context';
import { Link } from 'react-router-dom';
import DefaultHeader from './DefaultHeader/DefaultHeader';
import useDefaultLayoutHook from './DefaultLayout.hook';
import LayoutScreens from './LayoutScreens';
import constants from 'utils/constants';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

export default function DefaultLayout({
  children,
  contentHeader,
  singleBox,
  showHotelSelector,
  enableAllHotels = false,
  ...moreProps
}) {
  const { appState, appActions } = useContext(AppContext);
  const { state, actions } = useDefaultLayoutHook();
  const layoutDiv = useRef(null);

  const defaultOpenKeys = ['configuration', 'dashboard', 'account'];
  const isInProduction = process.env.REACT_APP_ENV === 'production';
  const isSuperUser = appState?.email.includes('@civitfun') || appState.superUser;
  const isAdminRole = appState.role == 'owner' || appState.role == 'admin';

  return (
    <div ref={layoutDiv} className={styles.container} {...moreProps}>
      {/* {appState.showQuickguide && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
            width: '100%',
            height: document.documentElement.scrollHeight,
            minHeight: 100,
            position: 'absolute',
            zIndex: 9,
          }}
        />
      )} */}

      <Layout style={{ minHeight: '100vh' }}>
        <Header className={styles.layoutHeader}>
          <DefaultHeader
            breadCrumb={appState.breadCrumb}
            sliderCollapsed={state.sliderCollapsed}
            setSliderCollapsed={actions.setSliderCollapsed}
            showHotelSelector={showHotelSelector}
            enableAllHotels={enableAllHotels}
          />
        </Header>
        <Layout>
          <Sider
            width={240}
            collapsible
            collapsed={state.sliderCollapsed}
            onCollapse={() => actions.setSliderCollapsed(!state.sliderCollapsed)}
            className={styles.layoutSider}
            trigger={null}
          >
            <Menu mode="inline" defaultOpenKeys={defaultOpenKeys} className={styles.layoutSider}>
              {LayoutScreens.map(route => {
                const showMenu =
                  // !isInProduction ||
                  //(
                    // isInProduction &&
                    // route?.showInProduction &&
                    // (!route?.onlySuperUser || isSuperUser)) ||
                  (isAdminRole ||
                    (!isAdminRole && (route.name === 'dashboard' || route.name === 'account')));

                return showMenu ? (
                  <SubMenu
                    key={route.name}
                    title={appActions.translate(`layout.${route.name}`)}
                    className={styles.menuItem}
                  >
                    {route.name === 'account' && !isAdminRole
                      ? route.children
                          ?.filter(child => child.name === 'support')
                          .map((subMenu, key) => getItem(subMenu, key, route))
                      : route.children?.map((subMenu, key) => getItem(subMenu, key, route))}
                  </SubMenu>
                ) : null;
              })}
            </Menu>
          </Sider>
          <Content className={styles.layoutContent}>
            {appState.currentScreen && (
              <div className={styles.pageTitleContainer}>
                {appState?.currentScreen?.icon && (
                  <img
                    style={{ height: 14, aspectRatio: 1, marginRight: 4 }}
                    src={require(`../../assets/images/icons/blue/${appState.currentScreen.icon}.png`)}
                  />
                )}
                {appState?.currentScreen?.name && (
                  <div className={styles.pageTitle}>
                    {appActions.translate(`layout.${appState?.currentScreen?.name}`)}
                  </div>
                )}
              </div>
            )}
            {contentHeader}
            {singleBox ? (
              children
            ) : (
              <Row gutter={[32, 32]} style={{ padding: 32 }}>
                {children}
              </Row>
            )}
          </Content>
        </Layout>
      </Layout>
      <Modal
        open={appState.sessionTimeLeft === 0 
          || appState.sessionTimeLeft <= constants.INACTIVITY_MESSAGE_APPEARS_AT_MINUTES_LEFT && !appState.inactivityMessageClosed}
        onCancel={actions.closeInactivityMessage}
        footer={null}
      >
        <div className={styles.centeredText}>
          {
            appState.sessionTimeLeft === 0
            ? appActions.translate("layout.sessionExpired")
            : appActions.translate("layout.sessionWillExpire").replace('#', appState.sessionTimeLeft)
          }
        </div>
        {
          <div className={styles.modalButton}>
            <Button key="close" type="primary" onClick={actions.closeInactivityMessage} className={styles.closeButton}>
              {
                appState.sessionTimeLeft === 0
                ? appActions.translate('layout.login')
                : appActions.translate('layout.closeInactivityMessageButton')
              }
            </Button>
          </div>
        }
      </Modal>
    </div>
  );

  function getItem(subMenu, key, route) {
    const hasSubItems = subMenu.children && subMenu.children.length;
    const showItem =
      !isInProduction ||
      (isInProduction && subMenu?.showInProduction && (!subMenu?.onlySuperUser || isSuperUser));

    return showItem ? (
      <div>
        <Link to={subMenu.path} key={key} onClick={() => appActions.setBreadCrumb(route, subMenu)}>
          <div
            className={`${styles.siderItem} ${
              subMenu.path === actions.getLocation().pathname ? styles.siderItemActive : ''
            }`}
          >
            {subMenu.icon && (
              <img
                className={styles.icon}
                src={require(`../../assets/images/icons/white/${subMenu.icon}.png`)}
              />
            )}
            {appActions.translate(`layout.${subMenu.name}`)}
          </div>
        </Link>
        {hasSubItems &&
          subMenu.children.map(subItem => {
            return !isInProduction || (isInProduction && subItem?.showInProduction) ? (
              <Link
                to={subItem.path}
                key={key}
                onClick={() => appActions.setBreadCrumb(route, subItem)}
              >
                <div
                  className={`${styles.subSiderItem} ${
                    subItem.path === actions.getLocation().pathname ? styles.siderItemActive : ''
                  }`}
                >
                  {appActions.translate(`layout.${subItem.name}`)}
                </div>
              </Link>
            ) : null;
          })}
      </div>
    ) : null;
  }
}

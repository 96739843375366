import { AppContext } from 'App.context';
import { useContext, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { notification } from 'antd';

export default function useChangePasswordFormHook() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    password: null,
    repeatPassword: null,
    newPassword: null,
    isLoading: false,
  });

  const actions = {
    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    validateDataAndChangePassword: () => {
      if (!state.password || !state.repeatPassword || !state.newPassword) {
        notification.error({ message: translate('user.emptyFields') });
      } else {
        if (state.newPassword !== state.repeatPassword) {
          notification.error({ message: translate('register.matchPasswords') });
        } else {
          actions.doChangePassword();
        }
      }
    },

    doChangePassword: () => {
      updateState({ isLoading: true });
      API.validateUserPassword({
        userId: appState.ownerId,
        password: state.newPassword,
        lang: appState.currentLanguage.id,
      })
      .then(result => {
        if ( result.valid ) {
          API.loggedChangePasswordRequest({
            userId: appState.ownerId,
            password: state.password,
            newPassword: state.newPassword,
            lang: appState.currentLanguage.id,
          })
          .then(_ => {
            setState({
              password: null,
              newPassword: null,
              repeatPassword: null,
            });

            notification.success({ message: translate('user.passwordSuccess') });
          })
          .catch(err => {
            notification.error({ message: err?.message });
          });
        } else {
          notification.error({ message: result.message });
        }
      })
      .catch(err => {
        notification.error({ message: err?.message });
      });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

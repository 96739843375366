import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Routes from 'Sell/CrossSell/Router/routes';
import ItemList from 'Sell/CrossSell/Item/List/ItemList';
import UpsertItem from 'Sell/CrossSell/Item/Upsert/UpsertItem';
import CrossSellList from 'Sell/CrossSell/CrossSell/List/CrossSellList';
import Reports from 'Sell/CrossSell/Reports/Reports';
import ProtectedRoute from './ProtectedRoute';
import { AppContext } from 'App.context';

export default function NestedRoutes() {
  const { appState } = useContext(AppContext);
  let { path } = useRouteMatch();

  const defaultRoute = 
    appState.role === 'owner' || appState.role === 'admin' || appState.superUser
      ? `${path}${Routes.itemsList}`
      : `${path}${Routes.crossSellsList}`;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}${Routes.itemsList}`}>
        <ItemList />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}${Routes.itemCreate}`}>
        <UpsertItem />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}${Routes.itemUpsert}`}>
        <UpsertItem />
      </ProtectedRoute>
      <Route exact path={`${path}${Routes.crossSellsList}`}>
        <CrossSellList />
      </Route>
      <Route exact path={`${path}${Routes.reports}`}>
        <Reports />
      </Route>
      <Redirect to={defaultRoute} />
    </Switch>
  );
}

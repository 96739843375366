import React, { useContext } from 'react';
import { AppContext } from 'App.context';
import { Card, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './EmptyTable.styles.js';
import { ReactComponent as Icon } from 'Sell/assets/icon/emptyRoomList.svg';

const { Title, Paragraph } = Typography;

export default function EmptyTable({ onButtonClick }) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  return (
    <Card style={styles.tableCard}>
      <div style={styles.tableBox}>
        <div style={styles.emptyIcon}>{<Icon />}</div>
        <Title style={styles.tableTitle} level={4}>
          {translate('upsell.createRoomType')}
        </Title>
        <Paragraph style={styles.tableText}>{translate('upsell.createRoomDescription')}</Paragraph>
        <Button
          style={styles.tableButton}
          type="primary"
          icon={<PlusOutlined />}
          onClick={onButtonClick}
        >
          {translate('upsell.createRoomType')}
        </Button>
      </div>
    </Card>
  );
}

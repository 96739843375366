import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Routes from 'Sell/Upsell/Router/routes';
import RoomTypeList from 'Sell/Upsell/RoomType/List/RoomTypeList';
import UpsertRoomType from 'Sell/Upsell/RoomType/Upsert/UpsertRoomType';
import UpsellList from 'Sell/Upsell/Upsell/List/UpsellList';
import Reports from 'Sell/Upsell/Reports/Reports';
import ProtectedRoute from 'Sell/Upsell/Shell/ProtectedRoute';
import { AppContext } from 'App.context';

export default function NestedRoutes() {
  const { appState } = useContext(AppContext);
  let { path } = useRouteMatch();

  const defaultRoute = 
    appState.role === 'owner' || appState.role === 'admin' || appState.superUser
      ? `${path}${Routes.roomsList}`
      : `${path}${Routes.upsellsList}`;

  return (
    <Switch>
      <ProtectedRoute exact path={`${path}${Routes.roomsList}`}>
        <RoomTypeList />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}${Routes.roomCreate}`}>
        <UpsertRoomType />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}${Routes.roomUpsert}`}>
        <UpsertRoomType />
      </ProtectedRoute>
      <Route exact path={`${path}${Routes.upsellsList}`}>
        <UpsellList />
      </Route>
      <Route exact path={`${path}${Routes.reports}`}>
        <Reports />
      </Route>
      <Redirect to={defaultRoute} />
    </Switch>
  );
}

import React from 'react';
import { Col } from 'antd';
import styles from './ContentBox.module.css';

export default function ContentBox({ title, children, noPadding, header, singleBox, customMinSize }) {
  const contentPadding = {
    paddingLeft: '48px',
    paddingRight: '48px',
    paddingTop: '16px',
    paddingBottom: '16px',
  };

  const boxMinSize = customMinSize ? customMinSize : singleBox ? 24 : 12;

  return (
    <Col xxl={boxMinSize} xl={boxMinSize} lg={24} xs={24}>
      <div>
        <div className={styles.header}>
          {title}
          {header ? <div>{header}</div> : null}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content} style={noPadding ? null : contentPadding}>
          {children}
        </div>
      </div>
    </Col>
  );
}

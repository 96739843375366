export default {
  opera: {
    checkin_module_url: 'URL/IP API',
    adults_code: 'Código adultos',
    children_code: 'Código niños',
    origin_entity_id: 'Id entidad origen',
    origin_system_type: 'Tipo sistema',
    destination_entity_id: 'Id entidad destino',
    destination_system_type: 'Tipo sistema destino',
    user_name: 'Nombre',
    user_password: 'Contraseña',
    domain: 'Dominio',
    hotel_code: 'Código hotel',
    chain_code: 'Código cadena',
    cloud: 'Nube',
    cloud_user_token: 'Token nube',
    cloud_user_name: 'Usuario nube ',
    cloud_user_password: 'Contraseña nube',
    register_payment_ws: 'WS Registro pago',
    station_id: 'Id estación',
    passport_code: 'Código pasaporte',
    dni_code: 'Código DNI',
    idcard_code: 'Código documento identificación',
    credit_card_code: 'Tarjeta crédito',
    currency_code: 'Código moneda',
    nationality_code: 'Código nacionalidad',
    country_code: 'Código país',
    i_checkin_module_url: 'URL/IP API',
    i_adults_code: 'Códigos de adultos',
    i_children_code: 'Códigos de niños',
    i_cloud: 'Integración cloud',
    i_cloud_user_token: 'Cloud user token',
    i_cloud_user_name: 'Interface ID',
    i_cloud_user_password: 'Interface Key',
    i_register_payment_ws: 'Define que WS se usa para registrar el pago',
    i_station_id: 'Define que "estación" se utiliza para realizar el pago',
    i_passport_code: 'Código para el pasaporte',
    i_dni_code: 'Código para el DNI',
    i_idcard_code: 'Código para el documento de identidad',
    i_credit_card_code: 'Código para la tarjerta de crédito',
    i_currency_code: 'Código para la divisa',
    i_nationality_code: 'Formato para la nacionalidad',
    i_country_code: 'Formato para el país (de residencia)',
  },
  apaleo: {
    custom_pms_id: 'Identificador de la propiedad en el PMS',
    checkin_module_http_user: 'HTTP user',
    checkin_module_http_password: 'HTTP password',
    oauth_access_token: 'Oauth access token',
    oauth_refresh_token: 'Oauth refresh token',
    hard_checkin: 'Hard checkin',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_hard_checkin:
      'Si es 1 => Se asignará habitación y se hará hard check-in. Si es 0 => Se hará soft check-in (se escribe comentario indicando que se ha hecho un pre check-in)',
  },
  avalon: {
    checkin_module_url: 'URL/IP API',
    custom_pms_id: 'Identificador de la propiedad en el PMS',
    document_type_code: 'Tipo de documento',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_document_type_code:
      'Usamos un string de pares "key=value" separados por ",". Donde key es alguno de los siguientes valores: idCard, passport, dni, residencePermit, drivingLicense, other, y value es el valor correspondiente a cada key en dicha propiedad. Por ejemplo: passport=PAS,idCard=CARTA,dni=DNI,residencePermit=NIE. Puede que no existe en la propiedad un value correspondiente a una key, en ese caso no se completa el par.',
  },
  avalon2: {
    checkin_module_url: 'URL/IP API',
    custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  catalonia: {
    checkin_booking_url: 'Checkin booking url',
    checkin_data_url: 'Checkin data url',
    checkin_confirm_url: 'Checkin confirm url',
    checkin_payment_balance_url: 'Checkin payment balance url',
    checkin_payment_register_url: 'Checkin payment register url',
    checkin_find_booking_url: 'Checkin find booking url',
    checkin_contract_url: 'Checkin contract url',
    checkin_module_format: 'Formato API',
    checkin_module_http_user: 'Usuario API',
    checkin_module_http_password: 'Password API',
    custom_pms_id: 'Identificador PMS',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_contract_url: 'URL/IP API',
    i_checkin_module_format: 'Formato API',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Password API',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  guestportal: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'Username',
    checkin_module_http_password: 'Password',
    nationality_code: 'NationalityCode',
    custom_pms_id: 'Provider Hotel Code',
    i_checkin_module_url: 'URL/IP API (Deben finalizar con la /)',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Password API',
    i_nationality_code: 'Formato de la nacionalidad, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  mews: {
    custom_pms_id: 'AccessToken',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  navision: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'Username',
    checkin_module_http_password: 'Password',
    i_checkin_module_url: 'URL/IP API',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Contraseña API',
  },
  noray: {
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_module_http_user: 'Http user',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    pms_url: 'Pms url',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Password API',
    i_pms_key: 'Clave de firma',
    i_pms_url:
      'Url with all params (important that starts with "https://api.businesscentral.dynamics.com"): https://api.businesscentral.dynamics.com/v2.0/{{tenant}}/{{environment}}/ODataV4/{{webService}}_{{method}}?company={{hotelName}}',
  },
  ohip: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Usuario',
    password: 'Contraseña',
    cashier_id: 'Id cajero',
    credit_card_code: 'Tarjeta de crédito',
    guarantee_code: 'Código garantía',
    i_pms_key:
      'Concatenación del Username(Interface Id) y Password(Interface Key) separados por "/civitfun/". Quedaría de la siguiente manera: Username/civitfun/Password',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generado al crear el environment de la cadena',
    i_checkin_module_http_password: 'Client Secret generado al crear el environment de la cadena',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
    i_credit_card_code: 'Solo necesario si se activa el módulo de pagos',
    i_guarantee_code: 'Solo necesario si se activa el módulo de pagos',
  },
  porthotels: {
    pms_url: 'URL/IP API',
    pms_key: 'Clave de firma',
    pms_format: 'Formato API',
    custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_pms_url: 'URL/IP API',
    i_pms_key: 'Clave de firma',
    i_pms_format: 'Formato API',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  poseidon: {
    checkin_module_url: 'Module url',
    i_checkin_module_url: 'URL/IP API',
  },
  protel: {
    checkin_module_url: 'URL/IP API',
    payment_code: 'PMSRevenueCode',
    document_type_code: 'Tipo de documento',
    custom_pms_id: 'RequestorID / @ID',
    pms_key: 'Auth Token',
    passport: 'Pasaporte',
    idCard: 'Id card',
    drivingLicense: 'Permiso conducir',
    residencePermit: 'Permiso residencia',
    dni: 'DNI',
    visa: 'Visa',
    mastercard: 'Mastercard',
    diners: 'Diners',
    amex: 'Amex',
    jcb: 'Jcb',
    upi: 'Upi',
    cartesbancaires: 'Cartes Bancaires',
    discover: 'Discover',
    unionpay: 'Unionpay',
    default: 'Default',
    i_checkin_module_url: 'URL/IP API',
    i_payment_code:
      'Método de pago, Valores soportados: visa, mastercard, diners, amex, jcb, upi, cartesbancaires, discover, unionpay, default*. Tendrá el siguiente formato (los números son los códigos que deberan ser proporcionados por el cliente): visa=2,mastercard=3,default=4',
    i_document_type_code:
      'Si usan los valores de la API por defecto este campo debe ser NULL. Sino hay que incluir la relación entre nuestro identificador de tipo de documento y el del PMS. Por ejemplo: "passport=422,idCard=432,drivingLicense=433,residencePermit=434,dni=435"',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_pms_key: 'Token de acceso',
    i_passport: 'Código del tipo de pasaporte',
  },
  roomcloud: {
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Pms id',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Password API',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
  sihot: {
    checkin_module_url: 'Module url',
    checkin_module_http_user: 'Usuario',
    checkin_module_http_password: 'Contraseña',
    adults_code: 'Código adultos',
    children_code: 'Código niños',
    pms_key: 'Pms key',
    checkout_module: 'Módulo de check-out del hotel',
    checkout_module_url: 'Checkout module url',
    i_checkin_module_url: 'URL/IP API',
    i_adults_code: 'Códigos del PMS que definen a un huésped como adulto',
    i_children_code: 'Códigos del PMS que definen a un huésped como niño',
    i_pms_key: 'Api key',
    i_checkout_module: 'Sihot (coincide con el checkin_module)',
    i_checkout_module_url: 'URL/IP API',
  },
  standard3: {
    checkin_module_url: 'Checkin module url',
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_bookings_of_day_url: 'Bookings of day url',
    checkin_contract_url: 'Contract url',
    checkin_module_format: 'Checkout module format',
    checkout_module_url: 'Module url',
    checkout_booking_url: 'Booking url',
    checkout_find_booking_url: 'Find booking url',
    checkout_payment_balance_url: 'Payment balance url',
    checkout_payment_register_url: 'Payment register url',
    checkout_confirm_url: 'Confirm url',
    checkout_module_format: 'Module format',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    custom_pms_id: 'Pms id',
    i_checkin_module_url: 'URL/IP API (Un endpoint para todos los servicios)',
    i_checkin_booking_url: 'URL/IP API (Endpoint específico por servicio)',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_bookings_of_day_url: 'URL/IP API',
    i_checkin_contract_url: 'URL/IP API',
    i_checkin_module_format: 'Formato de mensaje, soap/json',
    i_checkout_module_url: 'URL/IP API (Un endpoint para todos los servicios)',
    i_checkout_booking_url: 'URL/IP API',
    i_checkout_find_booking_url: 'URL/IP API',
    i_checkout_payment_balance_url: 'URL/IP API',
    i_checkout_payment_register_url: 'URL/IP API',
    i_checkout_confirm_url: 'URL/IP API',
    i_checkout_module_format: 'Formato de mensaje, soap/json',
    i_checkin_module_http_password:
      'API Secret Key, 32 length. Include: symbols, numbers, lowercase and uppercase. Exclude: similar, ambiguous and Generate On Your Device. https://passwordsgenerator.net/',
    i_pms_key: 'API Public Key, Version 4 UUID, https://www.uuidgenerator.net/version4',
    i_custom_pms_id: 'Identificador de la propiedad, Version 4 UUID',
  },
  timon: {
    checkin_module_url: 'Checkin module url',
    checkin_module_http_user: 'User',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Establecimiento',
    payment_code: 'Códigos para los pagos',
    i_payment_code:
      'Tiene que seguir el siguiente patrón: diario_anticipo=X,tesoreria_modo_anticipo=Y',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id:
      'Identificador de la propiedad en el PMS. Si un hotel en Civitfun agrupa más de un establecimiento de TimónHotel no se debe completar este campo con ningún dato.',
  },
  totvs: {
    checkin_module_http_password: 'Password',
    i_checkin_module_http_password: 'Token client',
  },
  winhotel: {
    checkin_module_url: 'Checkin module url',
    adults_code: 'Código adultos',
    children_code: 'Código niños',
    custom_pms_id: 'Identificador de la propiedad en el PMS.',
    hotelSourceCode: 'Hotel Source Code',
    hotelTargetCode: 'Hotel Target Code',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'HotelSourceCode-TRINCHERA-HotelTargetCode',
  },
  bookipro: {
    checkin_module_http_user: 'API Url',
    checkin_module_http_password: 'Token',
    i_checkin_module_http_user: 'API Url',
    i_checkin_module_http_password: 'API Secret Key',
  },
  hoteliga: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    token: 'API Auth token',
    custom_pms_id: 'API Domain',
    i_checkin_module_http_user: 'API User',
    i_checkin_module_http_password: 'API Password',
    i_token: 'API Auth token',
    i_custom_pms_id: 'API Domain',
  },
  guestline: {
    checkin_module_http_user: 'Operator Code',
    checkin_module_http_password: 'Password',
    custom_pms_id: 'Site ID',
    pms_key: 'Interface ID',
  },
  stelle: {
    checkin_module_http_user: 'Hotel code',
    checkin_module_http_password: 'Client token',
  },
  tecsoft: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Identificador de la propiedad en el PMS.',
    pms_key: 'Clave PMS',
    pms_format: 'Formato PMS',
    i_checkin_module_http_user: 'CIVITFUN',
    i_checkin_module_http_password: 'CIVITFUN',
    i_custom_pms_id: 'hotel.id (Identificador del hotel en Civitfun)',
  },
  ulysescloud: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'Identificador en el PMS',
  },
  rms: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
  },
  tms: {
    checkin_module_url: 'URL/IP API',
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel en el PMS',
  },
  minihotel: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel en el PMS',
  },
  guestpro: {
    checkin_module_http_user: 'API User',
    checkin_module_http_password: 'API Password',
    custom_pms_id: 'ID hotel en el PMS',
  },
  octorate: {
    checkin_module_http_user: 'Usuario API (público)',
    checkin_module_http_password: 'Contraseña API (secreta)',
    custom_pms_id: 'ID hotel en el PMS',
  },
  elektraweb: {
    token: 'Token',
    custom_pms_id: 'ID hotel en el PMS',
  },
  standard2: {
    checkin_module_url: 'Checkin url',
    checkin_booking_url: 'Booking url ',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_bookings_of_day_url: 'Bookings of day url',
    checkin_contract_url: 'Contract url',
    checkin_module_format: 'Module format',
    checkout_module_url: 'Checkout Module url',
    checkout_booking_url: 'Checkout Booking url',
    checkout_find_booking_url: 'Checkout Find booking url',
    checkout_payment_balance_url: 'Checkout Payment balance url',
    checkout_payment_register_url: 'Checkout Payment register url',
    checkout_confirm_url: 'Checkout Confirm',
    checkout_module_format: 'Checkout Module format',
    pms_key: 'PMS Key',
  },
  host: {
    checkin_booking_url: 'API Url',
    checkin_module_http_user: 'Subscription key',
    payment_code: 'Payment code',
    document_type_code: 'Document type code',
  },
  verial: {
    checkin_module_url: 'URL/IP',
    checkin_module_http_user: 'Usuario API',
  },
  ericsoft: {
    checkin_module_http_user: 'Usuario API',
    checkin_module_http_password: 'Contraseña API',
    custom_pms_id: 'Id del hotel en el PMS',
  },
  avirato: {
    checkin_module_http_user: 'Usuario API',
    checkin_module_http_password: 'Contraseña API',
    custom_pms_id: 'Id del hotel en el PMS',
  },
  noray_new: {
    checkin_booking_url: 'Booking url',
    checkin_data_url: 'Data url',
    checkin_confirm_url: 'Confirm url',
    checkin_payment_balance_url: 'Payment balance url',
    checkin_payment_register_url: 'Payment register url',
    checkin_find_booking_url: 'Find booking url',
    checkin_module_http_user: 'Http user',
    checkin_module_http_password: 'Http password',
    pms_key: 'Pms key',
    pms_url: 'Pms url',
    i_checkin_booking_url: 'URL/IP API',
    i_checkin_data_url: 'URL/IP API',
    i_checkin_confirm_url: 'URL/IP API',
    i_checkin_payment_balance_url: 'URL/IP API',
    i_checkin_payment_register_url: 'URL/IP API',
    i_checkin_find_booking_url: 'URL/IP API',
    i_checkin_module_http_user: 'Usuario API',
    i_checkin_module_http_password: 'Password API',
    i_pms_key: 'Clave de firma',
    i_pms_url:
      'Url with all params (important that starts with "https://api.businesscentral.dynamics.com"): https://api.businesscentral.dynamics.com/v2.0/{{tenant}}/{{environment}}/ODataV4/{{webService}}_{{method}}?company={{hotelName}}',
  },
  hestia: {
    checkin_module_http_user: 'Usuario',
    checkin_module_http_password: 'Contraseña',
  },
  winhotel_new: {
    checkin_module_url: 'Checkin module url',
    adults_code: 'Código adultos',
    children_code: 'Código niños',
    custom_pms_id: 'Identificador de la propiedad en el PMS.',
    hotelSourceCode: 'Hotel Source Code',
    hotelTargetCode: 'Hotel Target Code',
    i_checkin_module_url: 'URL/IP API',
    i_custom_pms_id: 'HotelSourceCode-TRINCHERA-HotelTargetCode',
  },
  ohipdynamic: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Usuario',
    password: 'Contraseña',
    i_pms_key:
      'Concatenación del Username(Interface Id) y Password(Interface Key) separados por "/civitfun/". Quedaría de la siguiente manera: Username/civitfun/Password',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generado al crear el environment de la cadena',
    i_checkin_module_http_password: 'Client Secret generado al crear el environment de la cadena',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
  },
  ohip_new: {
    pms_key: 'Username and Password',
    custom_pms_id: 'Custom pms id',
    checkin_module_url: 'HostName/Gateway URL',
    checkin_module_http_user: 'Client ID',
    checkin_module_http_password: 'Client Secret',
    nationality_code: 'Country code format',
    document_type_code: 'Custom document type codes',
    language_code: 'Custom language codes',
    username: 'Usuario',
    password: 'Contraseña',
    cashier_id: 'Id cajero',
    credit_card_code: 'Tarjeta de crédito',
    guarantee_code: 'Código garantía',
    i_pms_key:
      'Concatenación del Username(Interface Id) y Password(Interface Key) separados por "/civitfun/". Quedaría de la siguiente manera: Username/civitfun/Password',
    i_custom_pms_id: 'Identificador de la propiedad en el PMS',
    i_checkin_module_url: 'URL API',
    i_checkin_module_http_user: 'Client ID generado al crear el environment de la cadena',
    i_checkin_module_http_password: 'Client Secret generado al crear el environment de la cadena',
    i_nationality_code:
      'Nationality / IssuedCountry / BirthCountry codes, ISO3166-1_Alpha-2 / ISO3166-1_Alpha-3',
    i_document_type_code: 'Null/Empty -> Standard values',
    i_language_code: 'Null/Empty -> Standard values',
    i_credit_card_code: 'Solo necesario si se activa el módulo de pagos',
    i_guarantee_code: 'Solo necesario si se activa el módulo de pagos',
  },
  sihot360: {
    checkin_module_url: 'Enlace API',
    checkin_module_http_user: 'Usuario',
    checkin_module_http_password: 'Contraseña',
    payment_code: 'Payment code',
    custom_pms_id: 'ID del hotel en el PMS',
  },
  ohtels: {
    checkin_module_url: 'Enlace API',
    checkin_module_http_user: 'Usuario',
    checkin_module_http_password: 'Contraseña',
    custom_pms_id: 'ID del hotel en el PMS',
  },
  othello: {
    chain_custom_pms_id: 'Identificador de la cadena en PMS',
    custom_pms_id: 'Identificador del hotel en PMS',
    pms_key: 'Llave de autorización',
  },
  passepartout: {
    checkin_module_url: 'URL',
    custom_pms_id: 'Azienda',
    pms_key: 'Api Key',
  },
  noray_htl: {
    checkin_module_url: 'URL Completa',
    checkin_confirm_url: 'Enviroment Name',
    custom_pms_id: 'Company ID',
    checkin_module_http_user: 'Usuario',
    checkin_module_http_password: 'Contraseña',
    pms_key: 'Tenant',
    pms_url: 'Scope',
  },
  selentagroup: {
    checkin_module_url: 'API Url',
    document_type_code: 'Tipo de documento',
    custom_pms_id: 'Identificador de la propiedad en el PMS',
  },
};

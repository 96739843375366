import React, { useContext } from 'react';
import { Button, DatePicker, Modal, Popover } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import { AppContext } from '../../../App.context';
import useBookingListHook from './BookingList.hook';
import BaseTable from '../../common/BaseTable/BaseTable';
import styles from './BookingList.styles';
import CustomButton from '../../common/CustomButton/CustomButton';
import Colors from '../../../constants/Colors';

export default function BookingList() {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const { state, actions } = useBookingListHook();

  const columns = [
    {
      title: translate('bookingList.locatorCol'),
      dataIndex: 'code',
      key: 'code',
      searchable: true,
      sorter: false,
    },
    {
      title: translate('bookingList.nameAndSurnameCol'),
      dataIndex: 'guest',
      key: 'guest',
    },
    {
      title: translate('hotel'),
      dataIndex: ['hotel', 'name'],
      key: 'hotel',
      width: '12%',
      sorter: false,
    },
    {
      title: translate('bookingList.checkinCol'),
      dataIndex: 'entrance',
      key: 'entrance',
      width: '10%',
      sorter: true,
    },
    {
      title: translate('bookingList.checkoutCol'),
      dataIndex: 'departure',
      key: 'departure',
      width: '10%',
      sorter: true,
    },
    {
      title: translate('bookingList.adultCol'),
      dataIndex: 'adults',
      key: 'adults',
      align: 'center',
      render: (_, row) => {
        return <div>{`${row.completedAdults}/${row.adults}`}</div>;
      },
    },
    {
      title: translate('bookingList.childrenCol'),
      dataIndex: 'children',
      key: 'children',
      align: 'center',
      render: (_, row) => {
        return <div>{`${row.completedChildren}/${row.children}`}</div>;
      },
    },
    {
      title: translate('stats.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      sorter: false,
      render: (_, row) => {
        const completed = row.completedChildren + row.completedAdults;
        const total = row.children + row.adults + row.babies;

        return (
          <div style={styles.totalContainer}>
            {`${completed || 0}/${total || 0}`}
            <div
              style={{
                ...styles.statusIndicator,
                backgroundColor: actions.getCheckinStatusColor(total, completed),
              }}
            ></div>
          </div>
        );
      },
    },
    {
      title: translate('checkInList.actions'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (_, object) => {
        const content = <div>{translate('bookingList.actionPop')}</div>;
        return (
          <Popover content={content} trigger="hover">
            <Button
              type="secondary"
              icon={<EyeOutlined />}
              onClick={() => actions.onRowClicked(object)}
            />
          </Popover>
        );
      },
    },
  ];

  const { RangePicker } = DatePicker;

  return (
    <DefaultLayout singleBox title={translate('bookingList.title')} enableAllHotels={true}>
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          {/* <div style={styles.title}>{translate('checkInList.title')}</div> */}
          <div style={styles.buttonsContainer}>
            <RangePicker
              defaultValue={state.datesRange}
              onChange={actions.onDateChange}
              disabled={state.loading}
            />
            <CustomButton
              text={translate('bookingList.export')}
              background={Colors.exportButton}
              color={'white'}
              disabled={appState.role === 'guest'}
              onClick={() => {
                actions.export();
              }}
            ></CustomButton>
          </div>
        </div>
      </div>

      <div style={styles.layoutContainer}>
        <div style={styles.tableContainer}>
          <BaseTable
            columns={columns}
            data={state.dataSource}
            loading={state.isLoading}
            onChange={filters => actions.onChange(filters)}
          />
        </div>
      </div>
    </DefaultLayout>
  );
}

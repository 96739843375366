import FIELDS from './PoliceReportFields';
import moment from 'moment';

function getParsedReport(item) {
  const contract = item?.contract;
  const guest = item?.guest;

  const parsedReport = {
    id: item.id,
    status: item.report?.status,
    error: guest?.error,
    [FIELDS.REFERENCE_KEY]: contract?.reference,
    batch: guest?.batch,
    [FIELDS.CONTRACTDATE_KEY]: contract?.contractDate,
    [FIELDS.ENTRANCE_KEY]: contract?.entrance,
    [FIELDS.DEPARTURE_KEY]: contract?.departure,
    [FIELDS.NUMGUESTS_KEY]: contract?.paxes,
    [FIELDS.PAYMENTTYPE_KEY]: item?.payment?.type,
    [FIELDS.ROLETYPE_KEY]: guest?.holder ? 'TI' : 'VI',
    [FIELDS.NAME_KEY]: guest?.name,
    [FIELDS.SURNAME_KEY]: guest?.surname,
    [FIELDS.SECONDSURNAME_KEY]: guest?.secondSurname,
    [FIELDS.CONTACTTYPE_KEY]: guest?.customFields?.email ? 'email' : 'telefono',
    [FIELDS.CONTACTVALUE_KEY]: guest?.customFields?.email
      ? guest?.customFields?.email
      : guest?.customFields?.phone,
    [FIELDS.DOCUMENTTYPE_KEY]: guest?.documentType,
    [FIELDS.DOCUMENTNUMBER_KEY]: guest?.documentNumber,
    [FIELDS.DOCUMENTSUPPORT_KEY]: guest?.customFields?.document_support_number,
    [FIELDS.BIRTHDATE_KEY]: guest?.birthDate,
    [FIELDS.COUNTRY_KEY]: guest?.customFields?.country,
    [FIELDS.MUNICIPALITY_KEY]: guest?.customFields?.municipalityCode,
    [FIELDS.MUNICIPALITYNAME_KEY]: guest?.customFields?.municipality,
    [FIELDS.ZIPCODE_KEY]: guest?.customFields?.zipCode,
    [FIELDS.ADDRESS_KEY]: guest?.customFields?.address,
    [FIELDS.FAMILYRELATIONSHIP_KEY]: guest?.customFields?.relationship,
    [FIELDS.CHILDREN_KEY]: contract?.children || 0,
  };

  return parsedReport;
}

function createReportToApiUpdate(parsedReport, hotelId) {
  const item = {
    id: parsedReport?.contract.id,
    report: {
      status: parsedReport?.report?.status,
      message: parsedReport?.report?.error,
    },
    contract: {
      reference: parsedReport?.contract[FIELDS.REFERENCE_KEY],
      contractDate: getDateInApiFormat(parsedReport?.contract[FIELDS.CONTRACTDATE_KEY]),
      entrance: getDateInApiFormat(parsedReport?.contract[FIELDS.ENTRANCE_KEY]),
      departure: getDateInApiFormat(parsedReport?.contract[FIELDS.DEPARTURE_KEY]),
      paxes: parsedReport?.contract[FIELDS.NUMGUESTS_KEY],
      adults: parsedReport?.contract[FIELDS.ADULTS_KEY] || parsedReport?.contract[FIELDS.NUMGUESTS_KEY],
      hotelId,
    },
    payment: {
      type: parsedReport?.contract[FIELDS.PAYMENTTYPE_KEY],
    },
    guests: parsedReport?.guests?.map(guest => {
        return {
          id: guest.id,
          batch: guest.batch,
          holder: guest[FIELDS.ROLETYPE_KEY] === 'TI',
          name: guest[FIELDS.NAME_KEY],
          surname: guest[FIELDS.SURNAME_KEY],
          secondSurname: guest[FIELDS.SECONDSURNAME_KEY],
          documentType: guest[FIELDS.DOCUMENTTYPE_KEY],
          documentNumber: guest[FIELDS.DOCUMENTNUMBER_KEY],
          nationality: guest[FIELDS.NATIONALITY_KEY] || "",
          birthDate: getDateInApiFormat(guest[FIELDS.BIRTHDATE_KEY]),
          customFields: buildGuestCustomFields(guest),
        }
      })
  };

  return item;

  function buildGuestCustomFields(guest) {
    const customFields = {
      email: getParsedContactValue('email', guest),
      phone: getParsedContactValue('telefono', guest),
      document_support_number: guest[FIELDS.DOCUMENTSUPPORT_KEY],
      supportDocument: guest[FIELDS.DOCUMENTSUPPORT_KEY],
      country: guest[FIELDS.COUNTRY_KEY],
      zipCode: guest[FIELDS.ZIPCODE_KEY],
      address: guest[FIELDS.ADDRESS_KEY],
      relationship: guest[FIELDS.FAMILYRELATIONSHIP_KEY],
    };
  
    if (guest.country === 'ESP') {
      customFields.municipalityCode = guest[FIELDS.MUNICIPALITY_KEY];
    } else {
      customFields.municipality = guest[FIELDS.MUNICIPALITYNAME_KEY];
    }
    return customFields;
  }

  function getParsedContactValue(type, report) {
    return report.contactType === type ? report.contactValue : null;
  }
}

function getParsedReports(data) {
  const guestsList = data.items.map(item => {
    return getParsedReport(item);
  });

  data.items = guestsList;
  return data;
}

function getDateInApiFormat(input) {
  const inputDate = moment(input, ['DD-MM-YYYY', 'YYYY-MM-DD']);
  if (inputDate.isValid) {
    const formattedDate = moment(inputDate).format('YYYY-MM-DD');
    return formattedDate;
  } else {
    console.error('Invalid date format');
    return inputDate;
  }
}

export { getParsedReports, createReportToApiUpdate };

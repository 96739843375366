import React from 'react';
import { Button, Dropdown, Space, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './TableActions.styles.js';

export default function TableActions({ items = [], onItemClick = () => {}, title = 'Action', disabled = false }) {
  const handleButtonClick = e => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
  };

  const handleMenuClick = ({ domEvent, key }) => {
    if (!disabled) {
      domEvent.stopPropagation();
      onItemClick(key);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} disabled={disabled} onClick={handleButtonClick}>
      <Button style={styles.button} disabled={disabled}>
        <Space>
          {title}
          <DownOutlined style={styles.icon} />
        </Space>
      </Button>
    </Dropdown>
  );
}
import Axios from 'axios';
import { useEffect } from 'react';
import checkinsRequest from './api/checkins.services';
import checkinsExportRequest from './api/checkinsExport.services';
import constants from '../constants';
import storage from '../storage';
import checkinDetailRequest from './api/checkinDetail.services';
import hotelsRequest from './api/hotels.service';
import updateHotelsRequest from './api/updateHotel.services';
import deleteHotelRequest from './api/deleteHotel.services';
import addHotelsRequest from './api/addHotel.services';
import salesChannelsRequest from './api/salesChannels.service';
import updateSalesChannelRequest from './api/updateSalesChannel.service';
import apiConfig from './api.config';
import addIhClientHotelRequest from './api/addIHClientHotel.services';
import appearenceRequest from './api/appearence.services';
import updateAppearenceRequest from './api/updateAppearence.service';
import languagesRequest from './api/languages.service';
import allHotelsRequest from './api/allHotels.service';
import uploadImageRequest from './api/uploadImage.services';
import checkinStats from './api/checkinStats.services';
import pmsHotelsRequest from './api/pmsHotels.service';
import pmsListRequest from './api/pmsList.service';
import pmsConfigHotelRequest from './api/pmsConfigHotel.service';
import pmsOauthHotelRequest from './api/pmsOauthHotel.service';
import createPremiumSuscription from './api/createPremiumSuscription.service';
import checkinConfigRequest from './api/checkinConfig.services';
import updateCheckinConfigRequest from './api/updateConfig.services';
import bookingSearchConfigRequest from './api/bookingSearchConfig.services';
import updateBookingSearchConfigRequest from './api/updateBookingSearch.services';
import bookingsRequest from './api/bookings.service';
import guestFormsRequest from './api/guestForms.service';
import updateGuestFormRequest from './api/updateGuestForms.service';
import legalTextsRequest from './api/legalTexts.service';
import updateLegalTextRequest from './api/updateLegalTexts.service';
import communicationChannelsRequest from './api/communicationChannels.services';
import updateCommunicationEmails from './api/communicationEmails.service';
import checkinsStatsExportRequest from './api/checkinStatsExport.service';
import userRequest from './api/user.service';
import updateOwnerRequest from './api/updateOwner.service';
import updateUserRequest from './api/updateUser.service';
import loggedChangePasswordRequest from './api/auth/loggedChangePassword.service';
import userListRequest from './api/userList.services';
import sendUserInvitationRequest from './api/sendUserInvitation.service';
import deleteUserRequest from './api/deleteUser.service';
import updateChainAppearenceRequest from './api/updateChainAppearence.service';
import pmsPublicListRequest from './api/pmsPublicList.service';
import addOtaMarketing from './api/addOtaMarketing.service';
import getQuickguideImages from './api/getQuickguideImages.service';
import finishQuickguide from './api/finishQuickguide.service';
import registerPaperlessRequest from './api/auth/registerPaperless.service';
import pmsConfigRequest from './api/pmsConfig.service';
import pmsConfigDynamicFormRequest from './api/pmsConfigDynamicForm.service';
import updatePmsConfigRequest from './api/updatePmsConfig.service';
import doorsOpeningByHotelListRequest from './api/doorsOpeningByHotelList.service';
import doorOpeningAddToHotelRequest from './api/doorOpeningAddToHotel.service';
import doorOpeningUpdateToHotelRequest from './api/doorOpeningUpdateToHotel.service';
import doorOpeningDeleteToHotelRequest from './api/doorOpeningDeleteToHotel.service';
import doorOpeningImportToHotelRequest from './api/doorOpeningImportToHotel.service';
import getPaymentsList from './api/getPaymentsList.service';
import getHotelPaymentConfig from './api/getHotelPaymentConfig.service';
import updateHotelPaymentConfig from './api/updateHotelPaymentConfig.service';
import getHotelsPaymentSelected from './api/getHotelsPaymentsSelected.service';
import doPmsConnectionCheck from './api/pmsConnectionCheck.service';
import doorOpeningDownloadTemplateRequest from './api/doorOpeningDownloadTemplate.service';
import doHealthCheckBooking from './api/doHealthCheckBooking.service';
import getDoorOpeningList from './api/doorOpeningList.services';
import getDoorOpeningHotels from './api/getHotelsSelectedDoorOpening.service';
import getHotelDoorOpeningConfig from './api/getHotelDoorOpeningConfig.service';
import updateHotelDoorOpeningConfig from './api/updateHotelDoorOpeningConfig.service';
import getAccountFunctionalities from './api/getAccountFunctionalities.service';
import requestFunctionalityContract from './api/requestFunctionalityContract.service';
import sendRequestFuncionalityEmail from './api/sendRequestFunctionalityEmail.service';
import getMailTemplates from './api/getMailTemplates.service';
import updateMailTemplateState from './api/updateMailTemplateState.service';
import getMailTemplateDetail from './api/getMailTemplateDetail.service';
import getTags from './api/getTags.service';
import sendTestEmail from './api/sendTestEmail.service';
import uploadMailAttachment from './api/uploadMailAttachment.service';
import updateMailTemplate from './api/updateMailTemplate.service';
import getGuestlinkOptions from './api/getGuestlinkOptions.service';
import createGuestlink from './api/createGuestlink.service';
import deleteGuestlink from './api/deleteGuestlink.service';
import purchasesList from './api/getPurchasesList.services';
import paymentsExportRequest from './api/paymentsExport.services';
import getEstablishmentTypes from './api/getEstablishmentTypes.services';
import getPaperlessPushConfig from './api/getPaperlessPushConfig.service';
import updatePaperlessPushConfig from './api/updatePaperlessPushConfig.service';
import postSendPoliceReport from './api/postSendPoliceReport.service';
import getPoliceReportList from './api/getPoliceReportList.service';
import getPoliceReportDetail from './api/getPoliceReportDetail.service';
import downloadPoliceReports from './api/downloadPoliceReport.services';
import getPoliceConfig from './api/getPoliceConfig.service';
import updatePoliceSFTP from './api/updatePoliceSFTP.service';
import updatePoliceSES from './api/updatePoliceSES.service';
import getGuestlinkSmtpConfig from './api/getGuestlinkSmtpConfig.service';
import updateGuestlinkSmtpConfig from './api/updateGuestlinkSmtpConfig.service';
import checkSmtpConnection from './api/checkSmtpConnection.service';
import bookingsExportRequest from './api/bookingsExport.services';
import getBillingInformation from './api/getBillingInformation.service';
import saveBillingInformation from './api/saveBillingInformation.service';
import handleSuscriptionRequest from './api/handleSuscription.service';
import getPaymentSuscriptionStatus from './api/getPaymentSuscriptionStatus.service';
import getDoorOpeningSuscriptionStatus from './api/getDoorOpeningSuscriptionStatus.service';
import getGuestlinkSuscriptionStatus from './api/getGuestlinkSuscriptionStatus.service';
import getHotelContractList from './api/getHotelContractList.service';
import getHotelContractFileURL from './api/getHotelContractFileURL.service';
import saveHotelContract from './api/saveHotelContract.service';
import deleteHotelContract from './api/deleteHotelContract.service';
import editHotelContract from './api/editHotelContract.service';
import getHotelLicencesList from './api/getHotelLicencesList.service.js';
import getHotelBillingConfiguration from './api/getHotelBillingConfiguration.service';
import deleteHotelLicence from './api/deleteHotelLicence.service';
import editHotelLicence from './api/editHotelLicence.service.js';
import saveBulkHotelLicence from './api/saveBulkHotelLicence.service.js';
import getHotelInvoiceFileURL from './api/getHotelInvoiceFileURL.service';
import getHotelInvoicesList from './api/getHotelInvoicesList.service.js';
import deleteHotelInvoice from './api/deleteHotelInvoice.service';
import saveHotelInvoice from './api/saveHotelInvoice.service';
import editHotelInvoice from './api/editHotelInvoice.service.js';
import findHotelInvoiceByInvoiceId from './api/findHotelInvoiceByInvoiceId.service';
import findHotelInvoiceByInvoiceInternalId from './api/findHotelInvoiceByInvoiceInternalId.service';
import updateHotelStatusId from './api/updateHotelStatusId.service';
import getOneHotel from './api/getOneHotel.service';
import getCheckOuts from './api/check-out/getCheckOuts.service';
import getCheckOutSubscriptionStatus from './api/check-out/getCheckOutSubscriptionStatus';
import getCheckInSubscriptionStatus from './api/getCheckInSubscriptionStatus.service';
import policeReportCheckSFTPConnection from './api/policeReportCheckSFTPConnection.service';
import policeReportValidateFileExport from './api/policeReportValidateFileExport.service';
import policeReportCheckSESConnection from './api/policeReportCheckSESConnection.service';
import validationRequests from './api/validations';
import setDoorOpeningOauth from './api/doorOpening/setDoorOpeningOauth.service';
import getOauth from './api/oauth/getOauth.service';
import forcePoliceReportDownloadTrigger from './api/forcePoliceReportDownloadTrigger';
import uploadSliderImage from './api/uploadSliderImage.service';
import removeSliderImage from './api/removeSliderImage.service';
import getGuestLinkTemplatesAndSubjectsFromLang from './api/guestlink/getTemplatesAndSubjectsFromLang.service';
import pmsGetUdfConfigRequest from './api/pmsGetUdfConfig.service';
import pmsCreateUdfConfigRequest from './api/pmsCreateUdfConfig.service';
import pmsUpdateUdfConfigRequest from './api/pmsUpdateUdfConfig.service';
import pmsDeleteUdfConfigRequest from './api/pmsDeleteUdfConfig.service';
import getActiveDashboardAlerts from './api/getActiveDashboardAlerts.service.js';
import validateUserPassword from './api/validateUserPassword.service.js';
import registerDashboardAction from './api/registerDashboardAction.service.js';

export default function useApi({ appState, appActions }) {
  useEffect(() => {
    if (appState && appActions) {
      setUpInterceptors();
      if (appState?.accessToken) {
        loadAllHotels();
        loadQuickguideImages();
      }
    }
  }, [, appState?.accessToken]);

  function setUpInterceptors() {
    console.log('#API: Setting up interceptor');
    Axios.interceptors.request.use(
      request => {
        if (request.url !== apiConfig.bookingOauth && request.url !== apiConfig.cloudbedsOauth) {
          const storedCredentials = storage.get(constants.AUTH.USER_KEY);
          if (storedCredentials) {
            const { accessToken } = JSON.parse(storedCredentials);
            request.headers.Authorization = `Bearer ${accessToken}`;
          }
        }

        appActions.refreshSessionTimeLeft();

        return request;
      },
      error => {
        return error;
      }
    );

    const responseInterceptor = Axios.interceptors.response.use(
      response => {
        if (
          response.config.url !== apiConfig.bookingOauth ||
          !response.config.url.includes(apiConfig.validationsHost)
        ) {
          if (isUnauthorizedResponse(response)) {
            Axios.interceptors.response.eject(responseInterceptor);
            console.warn('401 - Unauthorized');
            deleteUserToken();
            return Promise.reject();
          } else {
            return response;
          }
        }
      },
      error => {
        if (error && error.response && isUnauthorizedResponse(error.response)) {
          console.warn('401 - Unauthorized');
          deleteUserToken();
          return Promise.reject();
        } else return rejectError(error);
      }
    );

    function isUnauthorizedResponse(response) {
      return (
        response &&
        response.status === 401 &&
        !response.config.url.includes(apiConfig.URIs.allHotels)
      );
    }

    function rejectError(error) {
      return Promise.reject(error);
    }
  }

  function deleteUserToken() {
    appActions.logout();
  }

  function loadAllHotels() {
    console.log('#API: Loading hotels list');
    allHotelsRequest()
      .then(res => {
        appActions.setHotelList(res);
        if (!appActions.getSelectedHotel()) {
          const hotel = { ...res[0], key: res[0].id, value: res[0].name, slug: res[0].slug };
          appActions.setSelectedHotel(hotel);
        }
      })
      .catch(err => {
        console.warn(err);
      });
  }

  function loadQuickguideImages() {
    console.log('#API: Loading quickguide images');
    getQuickguideImages()
      .then(res => {
        appActions.setQuickguideImages(res);
      })
      .catch(err => {
        console.warn(err);
      });
  }

  return {
    API: {
      getCheckOuts,
      checkinsRequest,
      checkinsExportRequest,
      checkinDetailRequest,
      hotelsRequest,
      updateHotelsRequest,
      deleteHotelRequest,
      addHotelsRequest,
      salesChannelsRequest,
      updateSalesChannelRequest,
      addIhClientHotelRequest,
      appearenceRequest,
      updateAppearenceRequest,
      languagesRequest,
      allHotelsRequest,
      uploadImageRequest,
      checkinStats,
      pmsHotelsRequest,
      pmsListRequest,
      pmsConfigHotelRequest,
      pmsOauthHotelRequest,
      createPremiumSuscription,
      checkinConfigRequest,
      updateCheckinConfigRequest,
      bookingSearchConfigRequest,
      updateBookingSearchConfigRequest,
      bookingsRequest,
      guestFormsRequest,
      updateGuestFormRequest,
      legalTextsRequest,
      updateLegalTextRequest,
      communicationChannelsRequest,
      updateCommunicationEmails,
      checkinsStatsExportRequest,
      userRequest,
      updateOwnerRequest: updateOwnerRequest,
      updateUserRequest: updateUserRequest,
      loggedChangePasswordRequest,
      userListRequest,
      sendUserInvitationRequest,
      deleteUserRequest,
      updateChainAppearenceRequest,
      pmsPublicListRequest,
      addOtaMarketing,
      getQuickguideImages,
      finishQuickguide,
      registerPaperlessRequest,
      pmsConfigRequest,
      pmsConfigDynamicFormRequest,
      updatePmsConfigRequest,
      pmsGetUdfConfigRequest,
      pmsCreateUdfConfigRequest,
      pmsUpdateUdfConfigRequest,
      pmsDeleteUdfConfigRequest,
      doorsOpeningByHotelListRequest,
      doorOpeningAddToHotelRequest,
      doorOpeningUpdateToHotelRequest,
      doorOpeningDeleteToHotelRequest,
      doorOpeningImportToHotelRequest,
      getPaymentsList,
      getHotelPaymentConfig,
      updateHotelPaymentConfig,
      getHotelsPaymentSelected,
      doPmsConnectionCheck,
      doorOpeningDownloadTemplateRequest,
      doHealthCheckBooking,
      getDoorOpeningList,
      getDoorOpeningHotels,
      getHotelDoorOpeningConfig,
      updateHotelDoorOpeningConfig,
      getAccountFunctionalities,
      requestFunctionalityContract,
      sendRequestFuncionalityEmail,
      getMailTemplates,
      updateMailTemplateState,
      getMailTemplateDetail,
      getTags,
      sendTestEmail,
      uploadMailAttachment,
      updateMailTemplate,
      getGuestlinkOptions,
      createGuestlink,
      deleteGuestlink,
      purchasesList,
      paymentsExportRequest,
      getEstablishmentTypes,
      getPaperlessPushConfig,
      updatePaperlessPushConfig,
      getPoliceReportList,
      getPoliceReportDetail,
      postSendPoliceReport,
      downloadPoliceReports,
      getPoliceConfig,
      updatePoliceSFTP,
      updatePoliceSES,
      getGuestlinkSmtpConfig,
      updateGuestlinkSmtpConfig,
      checkSmtpConnection,
      bookingsExportRequest,
      getBillingInformation,
      saveBillingInformation,
      handleSuscriptionRequest,
      getPaymentSuscriptionStatus,
      getDoorOpeningSuscriptionStatus,
      getGuestlinkSuscriptionStatus,
      getCheckOutSubscriptionStatus,
      getCheckInSubscriptionStatus,
      getHotelContractList,
      getHotelContractFileURL,
      saveHotelContract,
      deleteHotelContract,
      editHotelContract,
      getHotelLicencesList,
      getHotelBillingConfiguration,
      deleteHotelLicence,
      saveBulkHotelLicence,
      editHotelLicence,
      getHotelInvoiceFileURL,
      getHotelInvoicesList,
      deleteHotelInvoice,
      saveHotelInvoice,
      editHotelInvoice,
      findHotelInvoiceByInvoiceId,
      findHotelInvoiceByInvoiceInternalId,
      updateHotelStatusId,
      getOneHotel,
      policeReportValidateFileExport,
      policeReportCheckSFTPConnection,
      policeReportCheckSESConnection,
      setDoorOpeningOauth,
      getOauth,
      forcePoliceReportDownloadTrigger,
      uploadSliderImage,
      removeSliderImage,
      getGuestLinkTemplatesAndSubjectsFromLang,
      ...validationRequests,
      forcePoliceReportDownloadTrigger,
      getActiveDashboardAlerts,
      validateUserPassword,
      registerDashboardAction,
    },
  };
}

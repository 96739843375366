import React, { useContext } from 'react';
import styles from './PaymentList.styles.js';
import usePaymentListHook from './PaymentList.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import BaseTable from 'Components/common/BaseTable/BaseTable.jsx';
import { Button, DatePicker, Modal } from 'antd';
import Colors from 'constants/Colors.js';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import { capitalize } from 'utils/TextUtils.js';
import { EyeOutlined } from '@ant-design/icons';
import { getFormattedDate } from 'utils/DateUtils.js';

const { RangePicker } = DatePicker;

export default function PaymentList() {
  const { state, actions } = usePaymentListHook();
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('paymentList.bookingLocator'),
      dataIndex: 'code',
      key: 'code',
      searchable: true,
      sorter: false,
    },
    {
      title: translate('paymentList.locatorCol'),
      dataIndex: 'purchaseLocator',
      key: 'purchaseLocator',
      searchable: true,
    },
    {
      title: translate('paymentList.tokenCol'),
      dataIndex: 'purchaseToken',
      key: 'purchaseToken',
      searchable: true,
    },

    {
      title: translate('paymentList.amountCol'),
      dataIndex: 'amount',
      key: 'amount',
      sorter: false,
      render: (_, object) => {
        return <div>{`${object.amount} ${object.currency}`}</div>;
      },
    },
    {
      title: translate('paymentList.dateCol'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '15%',
      render: (date, object) => {
        console.log(date);
        return <div>{getFormattedDate(date)}</div>;
      },
    },
    {
      title: translate('checkInList.actions'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (_, object) => {
        return (
          <Button
            type="secondary"
            icon={<EyeOutlined />}
            onClick={() => actions.onRowClicked(object)}
          />
        );
      },
    },
  ];

  return (
    <DefaultLayout singleBox title={translate('bookingList.title')} enableAllHotels={false}>
      <div style={styles.container}>
        <div style={styles.buttonContainer}>
          <RangePicker
            defaultValue={state.datesRange}
            onChange={actions.onDateChange}
            disabled={state.isLoading}
          />
          <CustomButton
            text={translate('checkInList.export')}
            background={Colors.exportButton}
            color={'white'}
            disabled={appState.role === 'guest'}
            onClick={() => {
              actions.export();
            }}
          />
        </div>
        <div style={styles.whiteBackground}>
          <BaseTable
            columns={columns}
            data={state.dataSource}
            loading={state.isLoading}
            onChange={filters => actions.onChange(filters)}
            //onRowClicked={actions.onRowClicked}
          />
        </div>
      </div>
    </DefaultLayout>
  );
}

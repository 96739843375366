import axios from 'axios';
import apiConfig from '../../api.config';

export default function loggedChangePasswordRequest({ userId, password, newPassword, lang }) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.loggedChangePassword}`;

  return axios
    .post(uri, JSON.stringify({ userId, password, newPassword, lang }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}

import axios from 'axios';
import apiConfig from '../api.config';

export default function validateUserPassword({ userId, email, password, lang }) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.validatePassword}`;

  return axios
    .post(uri, JSON.stringify({ email, password, userId, lang }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    })
}

import React, { useContext } from 'react';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import Colors from 'constants/Colors';
import { Button } from 'antd';
import { DownloadOutlined, EditFilled } from '@ant-design/icons';
import styles from './BillingLicence.styles';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import { BillingListingContext } from '../BillingListing.context';
import moment from 'moment';
import { Tag } from 'antd';
import Icon from "@ant-design/icons";
import { ReactComponent as activeDot } from "assets/images/billing/activeDot.svg";
import { ReactComponent as inactiveDot } from "assets/images/billing/inactiveDot.svg";
import { ReactComponent as coinIcon } from "assets/images/billing/currency_icon.svg";

export default function BillingLicence() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  
  const { state, actions } = useContext(BillingListingContext);

  const checkinIcon = require("assets/images/billing/licence_type_checkin.svg");
  const checkoutIcon = require("assets/images/billing/licence_type_checkout.svg");
  const crossIcon = require("assets/images/billing/licence_type_crossselling.svg");
  const doorOpIcon = require("assets/images/billing/licence_type_door_opening.svg");
  const guestRegIcon = require("assets/images/billing/licence_type_guest_registration.svg");
  const guestLinkIcon = require("assets/images/billing/licence_type_guestlink.svg");
  const integCostIcon = require("assets/images/billing/licence_type_integration_cost.svg");
  const payProtIcon = require("assets/images/billing/licence_type_payment_protection.svg");
  const upsellIcon = require("assets/images/billing/licence_type_upselling.svg");
  const setupIcon = require("assets/images/billing/licence_type_setup.svg");
  const pushIcon = require("assets/images/billing/licence_type_push.svg");
  const paperlessIcon = require("assets/images/billing/licence_type_paperless.svg");
  const ftpIcon = require("assets/images/billing/licence_type_ftp.svg");
  const multitabletOptionIcon = require("assets/images/billing/licence_type_multitablet_option.svg");
  const grsIcon = require("assets/images/billing/licence_type_grs.svg");
  const grsBookingEngineIcon = require("assets/images/billing/licence_type_grs_booking_engine.svg");
  const hcnIcon = require("assets/images/billing/licence_type_hcn.svg");
  const documentScannerIcon = require("assets/images/billing/licence_type_document_scanner.svg");

  const licenceIcons = {
    setup: setupIcon,
    integration_cost: integCostIcon,
    checkin: checkinIcon,
    upselling: upsellIcon,
    crossselling: crossIcon,
    payment_protection: payProtIcon,
    door_opening: doorOpIcon,
    guestlink: guestLinkIcon,
    guest_registration: guestRegIcon,
    checkout: checkoutIcon,
    push: pushIcon,
    paperless: paperlessIcon,
    ftp: ftpIcon,
    multitablet_option: multitabletOptionIcon,
    grs: grsIcon,
    hcn: hcnIcon,
    document_scanner: documentScannerIcon,
    // booking_engine: grsBookingEngineIcon,
  };

  const licenceColumns = [
    {
      title: translate('billingConfig.licence.table.id'),
      dataIndex: 'id',
      key: 'id',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>L{row.id}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.name'),
      dataIndex: 'name',
      key: 'name',
      searchable: false,
      render: (_, row) => {
        const icon = licenceIcons[row.name] ? <img src={licenceIcons[row.name]} alt="licence-selector" /> : <></>;
        return <div style={{ height: 20 }}>{icon} {translate('billingConfig.licence.types.'+row.name)}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.contractId'),
      dataIndex: 'contractId',
      key: 'contractId',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>C{row.hotelContract.id}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.hotelName'),
      dataIndex: 'hotelName',
      key: 'hotelName',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>{state.hotelName}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.type'),
      dataIndex: 'type',
      key: 'type',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20 }}>{translate('billingConfig.licence.table.type_'+row.type)}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20, textAlign: 'center' }}>{moment(row.startDate).utc().format('DD-MM-YYYY')}</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.renewalDate'),
      dataIndex: 'renewalDate',
      key: 'renewalDate',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20, textAlign: 'center' }}>{ row.renewalDate && (
          <Tag style={styles.tagRenewal} icon={null}>{moment(row.renewalDate).utc().format('DD-MM-YYYY')}</Tag>
        ) || '-' }</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20, textAlign: 'center' }}>{ row.endDate && moment(row.endDate).utc().format('DD-MM-YYYY') || '-' }</div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.status'),
      dataIndex: 'status',
      key: 'status',
      searchable: false,
      render: (_, row) => {
        return <div style={{ height: 20, textAlign: 'center' }}>
          {row.active ? (
            <Tag style={styles.tagActive} icon={<Icon component={activeDot} />}>{translate('billingConfig.licence.active')}</Tag>
          ) : (
            <Tag style={styles.tagInactive} icon={<Icon component={inactiveDot} />}>{translate('billingConfig.licence.inactive')}</Tag>
          )}
        </div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.price'),
      dataIndex: 'price',
      key: 'price',
      searchable: false,
      render: (_, row) => {
        var format;
        switch (state.currentLanguage?.id) {
          case 'es':
            format = 'es-ES';
            break;
          case 'en':
          default:
            format = 'en-US';
            break;
        }

        let currency = new Intl.NumberFormat(format, {
          style: 'currency',
          currency: row.currency?.toUpperCase() || 'EUR',
        })
        return <div style={{ height: 20 }}>
          <Icon component={coinIcon} /> {currency.format(row.price)}
          </div>;
      },
    },
    {
      title: translate('billingConfig.licence.table.actions'),
      dataIndex: 'actions',
      key: 'actions',
      searchable: false,
      render: (_, row) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
            }}
          >
            {state.isUserAdmin &&
              <Button
                style={{ width: 32, height: 32, color: Colors.actionColor }}
                type="text"
                icon={<EditFilled />}
                onClick={() => actions.onEditLicenceClicked(row)}
              />
            }
          </div>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={licenceColumns}
      data={state.licenceList}
      loading={state.isLoading}
      pagination={false}
      locale={{ emptyText: (
        <div class="ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
          </div>
          <div class="ant-empty-description" style={styles.noDataDiv}><div style={styles.noDataText}>{translate('billingConfig.licence.table.empty_licence')}. <a href="mailto:support-team@civitfun.com">{translate('billingConfig.licence.table.contact_support')}</a>.</div></div>
        </div>)}}
      defaultLimit={5}
      showSizeChanger={false}
      onChange={filters => actions.onChangePageLicence(filters)}
    />
);
}

import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import validateLoginRequest from 'utils/networking/api/auth/validateLogin.service';
import storage from '../../../../utils/storage';

export default function useValidateLoginHook() {
  const { appState, appActions } = useContext(AppContext);
  const [state, setState] = useState({ code: null, loading: false });
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  const actions = {
    validateLogin: async ({ code }) => {
      try {
        const data = await validateLoginRequest({
          code,
          sessionId: params.sessionId,
          lang: appState.currentLanguage?.id,
        });

        if (data.registerPaymentMethodUrl) {
          window.location.href = data.registerPaymentMethodUrl;
        } else if (data.superUser) {
          history.push({
            pathname: '/superuser',
            state: {
              token: data.token,
              userId: data?.userId,
              email: data.email,
              ownerId: data?.ownerId,
              userType: data?.type,
              role: data?.role,
            },
          });
        } else {
          appActions.saveAuthToken({
            email: data.email,
            accessToken: data.token,
            superUser: false,
            ownerId: data?.ownerId,
            userId: data?.userId,
            hasSeenQuickguide: data?.hasSeenQuickguide,
            userType: data?.type,
            role: data?.role,
          });
          appActions.setChain(data?.chain);
          storage.add('slug', JSON.stringify(data?.chain));

          if (urlParams.get('ota') && urlParams.get('newHotel') === 'true') {
            history.push({
              pathname: '/select-pms',
              state: {
                ota: urlParams.get('ota'),
                hotelSlug: urlParams.get('hotelSlug'),
              },
            });
          } else if (urlParams.get('ota')) {
            history.push({
              pathname: '/channels',
              state: {
                ota: urlParams.get('ota'),
                hotelSlug: urlParams.get('hotelSlug'),
              },
            });
          } else {
            history.push('/hotels');
          }
        }
      } catch (error) {
        notification.error({
          message: appActions.translate('validateLogin.error'),
        });
      }
    },
    onCodeChange: code => {
      updateState({ code });
      actions.validateLogin({ code });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

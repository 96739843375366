import React, { useContext } from 'react';
import { AppContext } from 'App.context.js';
import { BillingListingContext } from '../BillingListing.context';
import styles from '../BillingModal/BillingModal.styles';
import Loading from 'Components/common/Loader/Loading';
import { Button, Select } from 'antd';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';

export default function BillingLicenceForm() {
    const {
        appActions: { translate },
    } = useContext(AppContext);

    const { state, actions } = useContext(BillingListingContext);
    
    const icon = require('assets/images/billing/license-modal-icon.png');
    const trashIcon = require('assets/images/billing/trash.png');
    const checkinIcon = require("assets/images/billing/licence_type_checkin.svg");
    const checkoutIcon = require("assets/images/billing/licence_type_checkout.svg");
    const crossIcon = require("assets/images/billing/licence_type_crossselling.svg");
    const doorOpIcon = require("assets/images/billing/licence_type_door_opening.svg");
    const guestRegIcon = require("assets/images/billing/licence_type_guest_registration.svg");
    const guestLinkIcon = require("assets/images/billing/licence_type_guestlink.svg");
    const integCostIcon = require("assets/images/billing/licence_type_integration_cost.svg");
    const payProtIcon = require("assets/images/billing/licence_type_payment_protection.svg");
    const upsellIcon = require("assets/images/billing/licence_type_upselling.svg");
    const setupIcon = require("assets/images/billing/licence_type_setup.svg");
    const pushIcon = require("assets/images/billing/licence_type_push.svg");
    const paperlessIcon = require("assets/images/billing/licence_type_paperless.svg");
    const ftpIcon = require("assets/images/billing/licence_type_ftp.svg");
    const multitabletOptionIcon = require("assets/images/billing/licence_type_multitablet_option.svg");
    const grsIcon = require("assets/images/billing/licence_type_grs.svg");
    const grsBookingEngineIcon = require("assets/images/billing/licence_type_grs_booking_engine.svg");
    const hcnIcon = require("assets/images/billing/licence_type_hcn.svg");
    const documentScannerIcon = require("assets/images/billing/licence_type_document_scanner.svg");

    const licenceIcons = {
        setup: setupIcon,
        integration_cost: integCostIcon,
        checkin: checkinIcon,
        upselling: upsellIcon,
        crossselling: crossIcon,
        payment_protection: payProtIcon,
        door_opening: doorOpIcon,
        guestlink: guestLinkIcon,
        guest_registration: guestRegIcon,
        checkout: checkoutIcon,
        push: pushIcon,
        paperless: paperlessIcon,
        ftp: ftpIcon,
        multitablet_option: multitabletOptionIcon,
        grs: grsIcon,
        hcn: hcnIcon,
        document_scanner: documentScannerIcon,
        // booking_engine: grsBookingEngineIcon,
      };

    const rows = [];
    const selectablePeriodityTypes = ['annual','oneshot'];

    for (var key in state.licenceForms) {
        const item = state.licenceForms[key];
        const errorItem = state.licenceErrors?.find(element => item.key == element.key);
        const priceCurrencyError = errorItem?.errors.includes('currency') || errorItem?.errors.includes('price');
        
        const currencySelector = (
            <Select value={item.currency} options={actions.getCurrencyOptions()} placeholder={translate('billingConfig.licence.form.currency_hint')} hasError={errorItem?.errors.includes('currency')} onChange={value => { actions.onChangeLicenceFormField('currency', value, item.key) }} />
        );

        let licenceTypeOptions = [];
        const rawLicenceTypeOptions = actions.getLicenceTypesOptions();
        for (let index = 0; index < rawLicenceTypeOptions.length; index++) {
            const option = rawLicenceTypeOptions[index];
            licenceTypeOptions.push({
                value: option.value,
                label: <><img src={licenceIcons[option.value]} alt="licence-selector" /> {option.label}</>,
            })
        }

        rows.push(
            <div style={styles.licenceElement}>
                <div style={styles.formData}>
                    <div style={styles.formSection}>
                        <div style={{...styles.sectionTitle,...errorItem?.errors.includes('name') && styles.titleWithError}}>{translate('billingConfig.licence.form.licence')}</div>
                        <div style={styles.inputContainer}>
                            <AppSelector
                                style={styles.selector}
                                name={translate('billingConfig.licence.form.licence')}
                                hint={translate('billingConfig.licence.form.licence_hint')}
                                value={item.name}
                                options={licenceTypeOptions}
                                onOptionSelected={value => actions.onChangeLicenceFormField('name', value, item.key)}
                                disabled={state.isLoading}
                                hasError={errorItem?.errors.includes('name')}
                            />
                        </div>
                        {errorItem?.errors.includes('name')? (<div style={styles.errorSubmessage}>*{translate('billingConfig.licence.form.invalidLicenceError')}</div>) : (<></>)}
                    </div>
                </div>
                
                <div style={styles.formData}>
                    <div style={styles.formSection}>
                        <div style={{...styles.sectionTitle,...errorItem?.errors.includes('contractId') && styles.titleWithError}}>{translate('billingConfig.licence.form.contract')}</div>
                        <div style={styles.inputContainer}>
                            <AppSelector
                                style={styles.selector}
                                name={translate('billingConfig.licence.form.contract')}
                                hint={translate('billingConfig.licence.form.contract_hint')}
                                value={item.contractId}
                                options={actions.getContractOptions()}
                                onOptionSelected={value => actions.onChangeLicenceFormField('contractId', value, item.key)}
                                disabled={state.isLoading}
                                hasError={errorItem?.errors.includes('contractId')}
                            />
                        </div>
                    </div>
                    <div style={styles.formSection}>
                        <div style={styles.sectionTitle}>{translate('billingConfig.licence.form.start_date')}</div>
                        <div style={styles.inputContainer}>
                            <AppInput
                                forceValue={item.startDate}
                                name={translate('billingConfig.licence.form.start_date')}
                                hint={translate('billingConfig.licence.form.start_date_hint')}
                                type={AppInputTypes.DATE}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div style={styles.formData}>
                    <div style={styles.formSection}>
                        <div style={{...styles.sectionTitle,...errorItem?.errors.includes('type') && styles.titleWithError}}>{translate('billingConfig.licence.form.periodity')}</div>
                        <div style={styles.inputContainer}>
                            <AppSelector
                                style={styles.selector}
                                name={translate('billingConfig.licence.form.periodity')}
                                hint={translate('billingConfig.licence.form.periodity_hint')}
                                value={item.type}
                                options={actions.getPeriodityOptions(item, selectablePeriodityTypes)}
                                onOptionSelected={value => actions.onChangeLicenceFormField('type', value, item.key)}
                                disabled={state.isLoading || (item.type && !selectablePeriodityTypes.includes(item.type))}
                                hasError={errorItem?.errors.includes('type')}
                            />
                        </div>
                    </div>
                    <div style={styles.formSection}>
                        <div style={{...styles.sectionTitle,...priceCurrencyError && styles.titleWithError}}>{translate('billingConfig.licence.form.price')}</div>
                        <div style={styles.inputContainer}>
                            <style>{`.ant-input-group-addon {border: 0px !important;}`}</style>
                            <AppInput
                                value={item.price}
                                name={translate('billingConfig.licence.form.price')}
                                hint={translate('billingConfig.licence.form.price_hint')}
                                onChange={value => { actions.onChangeLicenceFormField('price', value, item.key) }}
                                disabled={state.isLoading}
                                addonAfter={currencySelector}
                                hasError={priceCurrencyError}
                            />
                        </div>
                    </div>
                </div>

                <div hidden={state.isEditLicence} style={styles.trashContainer}>
                    <Button style={styles.trashIcon} onClick={() => actions.removeLicenceFormBlock(item)}><img src={trashIcon} alt="modal-trash" /></Button>
                </div>
            </div>
        );
    }
    
    return (
        <div>
            {state.isLoading ? (
              <Loading />
            ) : (
                <>
                    <div style={styles.header}>
                        <div>
                            <img src={icon} alt="modal-licence" />
                        </div>
                        <div style={styles.headerTitle}>{translate('billingConfig.licence.modal.title')}</div>
                        <div hidden={state.isEditLicence} style={styles.addButtonDiv}>
                            <Button type="primary" style={styles.addButton} size={'large'} onClick={() => actions.addLicenceFormBlock()}>+</Button>
                        </div>
                    </div>

                    <div style={styles.licenceBody}>
                        {rows}
                    </div>

                    <div style={styles.footer}>
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                secondary={true}
                                action={() => actions.closeBillingModal()}
                                text={translate('billingConfig.licence.modal.cancel')}
                            />
                        </div>
                        {state.isEditLicence && <>
                            <div style={styles.modalFooterButtonRed}>
                                <AppButton
                                    redButton={true}
                                    action={() => actions.deleteHotelLicence()}
                                    text={translate('billingConfig.licence.modal.delete')}
                                />
                            </div>
                        </>}
                        <div style={styles.modalFooterButton}>
                            <AppButton
                                action={state.isEditLicence ? () => actions.editLicenceForm() : () => actions.saveLicenceForms()}
                                text={translate('billingConfig.licence.modal.save')}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

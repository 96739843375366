import axios from 'axios';
import apiConfig from '../../api.config';

export default function validateLoginRequest({ code, sessionId, lang = 'es' }) {
  const {
    host,
    URIs: { auth },
  } = apiConfig;

  const uri = `${host}${auth.validateLogin}`;

  return axios
    .post(uri, JSON.stringify({ code, sessionId, lang }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.data);
}

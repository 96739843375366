import axios from 'axios';
import apiConfig from '../api.config';

export default function sendUserInvitationRequest({ name, email, ownerId, hotel, role }) {
  const {
    host,
    URIs: { sendUserInvitation },
  } = apiConfig;

  const uri = `${host}${sendUserInvitation}`;

  return axios
    .post(uri, JSON.stringify({ name, email, ownerUserId: ownerId, hotel, role }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}

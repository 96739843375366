import React, { useContext } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Login from '../Components/common/Welcome/Login/Login';
import RecoverPassword from '../Components/common/Welcome/RecoverPassword/RecoverPassword.jsx';
import Register from '../Components/common/Welcome/Register/Register';
import SuperUserAccounts from 'Components/common/Welcome/SuperUser/SuperUserAccounts';
import Appearence from '../Components/Dashboard/Appearence/Appearence';
import BookingList from '../Components/Dashboard/BookingList/BookingList';
import CheckIn from '../Components/Dashboard/CheckIn/CheckIn.jsx';
import CheckInList from '../Components/Dashboard/CheckInList/CheckInList';
import CheckinStats from '../Components/Dashboard/CheckinStats/CheckinStats';
import Hotels from '../Components/Dashboard/Hotels/Hotels';
import LegalDocuments from '../Components/Dashboard/LegalDocuments/LegalDocuments';
import PMS from '../Components/Dashboard/PMS/PMS';
import SalesChannels from '../Components/Dashboard/SalesChannels/SalesChannels';
import Users from '../Components/Dashboard/Users/Users';
import history from './history';
import PrivateRoute from './PrivateRoute';
import ChangePassword from 'Components/common/Welcome/ChangePassword/ChangePassword';
import CommunicationChannels from 'Components/Dashboard/CommunicationChannels/CommunicationChannels';
import AccountDetail from 'Components/Dashboard/AccountDetail/AccountDetail';
import Marketing from 'Components/Dashboard/Marketing/Marketing';
import SelectPMS from 'Components/common/Welcome/SelectPMS/SelectPMS';
import Support from 'Components/Dashboard/Support/Support';
import RegisterPaperless from '../Components/common/Welcome/RegisterPaperless/RegisterPaperless';
import { AppContext } from 'App.context';
import DoorOpening from 'Components/Dashboard/DoorOpening/DoorOpening';
import PaymentConfiguration from 'Components/Dashboard/PaymentConfiguration/PaymentConfiguration';
import DoorOpeningMaintenance from 'Components/Dashboard/DoorOpening/DoorOpeningMaintenance/DoorOpeningMaintenance';
import MailTemplates from 'Components/Dashboard/Guestlink/MailTemplates/MailTemplates';
import MailTemplateDetail from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateDetail';
import PaymentList from 'Components/Dashboard/PaymentList/PaymentList';
import PaperlessPush from 'Components/Dashboard/PaperlessPush/PaperlessPush';
import Forms from 'Components/Dashboard/Forms/Forms';
import PoliceReportList from 'Components/Dashboard/PoliceReport/PoliceReportList/PoliceReportList';
import PoliceReportDetail from 'Components/Dashboard/PoliceReport/PoliceReportDetail/PoliceReportDetail';
import PoliceReportConfig from 'Components/Dashboard/PoliceReport/PoliceReportConfig/PoliceReportConfig';
import UpsellShell from 'Sell/Upsell/Shell/UpsellShell';
import CrossSellShell from 'Sell/CrossSell/Shell/Shell';
import SmtpConfig from 'Components/Dashboard/Guestlink/GuestlinkConfig/GuestlinkConfig';
import BillingConfig from 'Components/Dashboard/Billing/BillingConfig/BillingConfig';
import CheckOutList from 'Components/Dashboard/CheckOutList/CheckOutList';
import ProtectedRoute from './ProtectedRoute';
import ValidateLogin from 'Components/common/Welcome/ValidateLogin/ValidateLogin';

export default function Routes({ isShowingQuickguide }) {
  const { appState, appActions } = useContext(AppContext);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/validate-login/:sessionId" component={ValidateLogin} />
        <Route exact path="/recover" component={RecoverPassword} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/superuser" component={SuperUserAccounts} />
        <Route exact path="/change-password" component={ChangePassword} />
        <PrivateRoute exact path="/register-paperless" component={RegisterPaperless} />
        <ProtectedRoute exact path="/select-pms">
          <SelectPMS />
        </ProtectedRoute>
        <ProtectedRoute exact path="/users">
          <Users />
        </ProtectedRoute>
        <ProtectedRoute exact path="/hotels">
          <Hotels />
        </ProtectedRoute>
        <PrivateRoute exact path="/">
          <Redirect to="/checkin" />
        </PrivateRoute>
        <ProtectedRoute exact path="/pms">
          <PMS />
        </ProtectedRoute>
        <ProtectedRoute exact path="/dooropening">
          <DoorOpening />
        </ProtectedRoute>
        <ProtectedRoute exact path="/door-maintenance">
          <DoorOpeningMaintenance />
        </ProtectedRoute>
        <ProtectedRoute exact path="/payment">
          <PaymentConfiguration />
        </ProtectedRoute>
        <ProtectedRoute path="/channels">
          <SalesChannels />
        </ProtectedRoute>
        <ProtectedRoute exact path={'/appearence'}>
          <Appearence />
        </ProtectedRoute>
        <ProtectedRoute exact path={'/checkin-config'}>
          <CheckIn />
        </ProtectedRoute>
        <ProtectedRoute
          key={document.location.href}
          exact
          path={[
            '/legal',
            '/legal/cardex',
            '/legal/custom_cardex_pdf',
            '/legal/terms',
            '/legal/data-privacy-policy',
            '/legal/loyalty-program',
            '/legal/data-commercial-use',
          ]}
        >
          <LegalDocuments />
        </ProtectedRoute>
        <PrivateRoute exact path={'/checkin'}>
          <CheckInList />
        </PrivateRoute>
        <PrivateRoute exact path={'/booking'}>
          <BookingList />
        </PrivateRoute>
        <PrivateRoute exact path={'/payments-list'}>
          <PaymentList />
        </PrivateRoute>
        <ProtectedRoute exact path={'/guest-forms'}>
          <Forms />
        </ProtectedRoute>
        <PrivateRoute exact path={'/stats'}>
          <CheckinStats />
        </PrivateRoute>
        <PrivateRoute exact path={'/communication-channels'}>
          <CommunicationChannels />
        </PrivateRoute>
        <ProtectedRoute exact path={'/guestlink'}>
          <MailTemplates />
        </ProtectedRoute>
        <ProtectedRoute path={'/guestlink/:postId'}>
          <MailTemplateDetail />
        </ProtectedRoute>
        <ProtectedRoute path={'/guestlink-config'}>
          <SmtpConfig />
        </ProtectedRoute>
        <ProtectedRoute exact path={'/account-detail'}>
          <AccountDetail />
        </ProtectedRoute>
        <PrivateRoute exact path={'/marketing'}>
          <Marketing />
        </PrivateRoute>
        <ProtectedRoute exact path={'/paperless-push'}>
          <PaperlessPush />
        </ProtectedRoute>
        <PrivateRoute exact path={'/police-report'}>
          <PoliceReportList />
        </PrivateRoute>
        <PrivateRoute path={'/police-report/:guestId'}>
          <PoliceReportDetail />
        </PrivateRoute>
        <ProtectedRoute path={'/police-report-config'}>
          <PoliceReportConfig />
        </ProtectedRoute>
        <ProtectedRoute path={'/billing'}>
          <BillingConfig />
        </ProtectedRoute>
        <PrivateRoute path={'/upsell'}>
          <UpsellShell />
        </PrivateRoute>
        <PrivateRoute path={'/cross-sell'}>
          <CrossSellShell />
        </PrivateRoute>
        <PrivateRoute children={<CheckOutList />} exact path={'/checkout'} />
        {appState.showQuickguide ? (
          <PrivateRoute exact path={'/support'}>
            <Support />
          </PrivateRoute>
        ) : (
          <PrivateRoute exact path={'/support'} component={() => openSupportScreen()} />
        )}
      </Switch>
      {/* <PrivateRoute exact path={'/quickguide'} component={() => showQuickguide(appActions)} /> */}
    </Router>
  );

  function openSupportScreen() {
    window.open(
      appState.currentLanguage && appState.currentLanguage?.id === 'es'
        ? 'https://academy.civitfun.com/hc/es'
        : 'https://academy.civitfun.com/hc/en-gb',
      '_blank'
    );
    history.goBack();
    return null;
  }
}

function showQuickguide(appActions) {
  appActions.setShowQuickguide(true, true);
  history.push('hotels');
  return null;
}

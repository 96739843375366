import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import FIELDS from '../civitfun-police-report-utils/PoliceReportFields';
import PoliceReportConstants from '../civitfun-police-report-utils/police.constants';
import { createReportToApiUpdate } from '../civitfun-police-report-utils/PoliceReportParse.utils';
import PoliceReportValidations from '../civitfun-police-report-utils/validations/PoliceReportValidations';
import moment from 'moment';

const {
  MUNICIPALITIES,
  PAYMENT_TYPE,
  ROLE_TYPE,
  CONTACT_TYPE,
  DOCUMENT_TYPE,
  FAMILY_RELATIONSHIP,
  COUNTRIES,
  ADULT_AGE,
} = PoliceReportConstants;

const { isMandatoryFieldEmpty, getFieldValidations } = PoliceReportValidations;

export default function usePoliceReportDetailHook(initialReport) {
  const {
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const history = useHistory();

  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    bookingId: `${getSelectedHotel().id}-${initialReport.reference}`,
    report: {},
    showSendWarning: false,
    disableForm: false,
    errorFields: {
      contract: [],
      guests: Array.from({ length: initialReport?.numGuests }, () => ([]))
    },
    checkinCount: initialReport[FIELDS.NUMGUESTS_KEY],
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    actions.getPoliceReportDetail();

    if (initialReport.status !== 'error') {
      updateState({ disableForm: true });
    }
  }, []);

  const actions = {
    createOptionsFromType: (typeArray, translationPrefix) =>
      typeArray.map(type => ({
        value: translationPrefix ? type : type.value,
        label: translationPrefix
          ? translate(`policeReport.${translationPrefix}.${type}`)
          : type.label,
      })),

    getPaymentTypeOptions: () => actions.createOptionsFromType(PAYMENT_TYPE, 'paymentType'),
    getRoleTypeOptions: () => actions.createOptionsFromType(ROLE_TYPE, 'role'),
    getContactTypeOptions: () => actions.createOptionsFromType(CONTACT_TYPE, 'contactType'),
    getDocumentTypeOptions: () => actions.createOptionsFromType(DOCUMENT_TYPE, 'documentType'),
    getMunicipalityOptions: () => actions.createOptionsFromType(MUNICIPALITIES),
    getFamilyRelationshipOptions: () => actions.createOptionsFromType(FAMILY_RELATIONSHIP),
    getCountryOptions: () => actions.createOptionsFromType(COUNTRIES),
    disableSupportNumber: () => actions.createOptionsFromType(),

    onSendClicked: () => {
      actions.setShowSendWarning(true);
    },

    validateForm: ({ showErrorMsg, report }) => {
      const reportRef = report;
      const errors = {
        contract: [],
        guests: Array.from({ length: report.guests.length }, () => ([]))
      };

      const fieldValidations = getFieldValidations(reportRef, translate);

      for (const [_, field] of Object.entries(FIELDS)) {
        
        // validate by field section & guest
        if ([
          FIELDS.REFERENCE_KEY, 
          FIELDS.CONTRACTDATE_KEY, 
          FIELDS.ENTRANCE_KEY, 
          FIELDS.DEPARTURE_KEY, 
          FIELDS.NUMGUESTS_KEY, 
          FIELDS.PAXES_KEY, 
          FIELDS.ADULTS_KEY, 
          FIELDS.CHILDREN_KEY,
          FIELDS.PAYMENTTYPE_KEY
        ].includes(field)) {
          if (isMandatoryFieldEmpty(reportRef.contract, field)) {
            errors.contract.push(field);
          }
        } else {
          for (let index = 0; index < reportRef.guests.length; index++) {
            const guest = reportRef.guests[index];
            if (isMandatoryFieldEmpty(guest, field)) {
              errors.guests[index].push(field);
            }
          }
        }

        if (field in fieldValidations) {
          // validate by field section & guest
          if ([
            FIELDS.REFERENCE_KEY, 
            FIELDS.CONTRACTDATE_KEY, 
            FIELDS.ENTRANCE_KEY, 
            FIELDS.DEPARTURE_KEY, 
            FIELDS.NUMGUESTS_KEY, 
            FIELDS.PAXES_KEY, 
            FIELDS.ADULTS_KEY, 
            FIELDS.CHILDREN_KEY,
            FIELDS.PAYMENTTYPE_KEY
          ].includes(field)) {
            if (!fieldValidations[field].validate()  && !errors.contract.includes(field)) {
              errors.contract.push(field);
              notification.error({
                message: fieldValidations[field].error,
              });
            }
          } else {
            for (let index = 0; index < reportRef.guests.length; index++) {
              const guest = reportRef.guests[index];
              if (!fieldValidations[field].validate(guest) && !errors.guests[index].includes(field)) {
                errors.guests[index].push(field);
                notification.error({
                  message: fieldValidations[field].error,
                });
              }
            }
          }
        }
      }

      showErrorMsg &&
        hasErrors() &&
        notification.error({
          message: translate(`policeReport.mandatoryFieldsError`),
        });

      updateState({ showSendWarning: false, errorFields: errors });
      return hasErrors() <= 0;

      function hasErrors() {
        const contractHasErrors = errors.contract && errors.contract.length > 0;
        const guestsHasErrors = errors.guests && errors.guests.some(guestErrors => guestErrors.length > 0);
        return contractHasErrors || guestsHasErrors;
      }
    },

    getPoliceReportDetail: () => {
      updateState({ isLoading: true });
      API.getPoliceReportDetail({
        hotelId: getSelectedHotel().key || getSelectedHotel().id,
        bookingId: state.bookingId,
      })
        .then(res => {
          if (initialReport.status == 'error') {
            actions.validateForm({ showErrorMsg: false, report: res });
          }
          updateState({ isLoading: false, report: res });
        })
        .catch(err => {
          console.error(err);

          updateState({ isLoading: false });
        });
    },

    sendReport: () => {
      state.report.guests.forEach(guest => {
        // Check if the guest has a support number (document_support_number or supportDocument)
        const hasSupportNumber = guest?.document_support_number || guest?.supportDocument;
        // Check if the document is a Spanish DNI or NIE
        const isDNIorNIE =
          guest?.documentType === "dni" || guest?.documentType === "residencePermit";
        // If the guest has a support number and it's NOT a Spanish DNI/NIE
        if (hasSupportNumber && !isDNIorNIE) {
          if (guest?.document_support_number) {
            guest.document_support_number = ""; // Clear the document_support_number field
            updateState(state);
          }
          if (guest?.supportDocument) {
            guest.supportDocument = ""; // Clear the supportDocument field
            updateState(state);
          }
        }
      });
      if (!actions.validateForm({ showErrorMsg: true, report: state.report })) return;
      updateState({ isLoading: true, disableForm: true, showSendWarning: false });

      // Register action
      API.registerDashboardAction('police_report', 'send_report', { hotelId: getSelectedHotel().key || getSelectedHotel().id, report: state.report });

      API.postSendPoliceReport({
        data: createReportToApiUpdate(
          state.report,
          getSelectedHotel().key || getSelectedHotel().id
        ),
      })
        .then(_ => {
          notification.success({
            message: translate('policeReport.edit.success'),
          });
          updateState({ isLoading: false, disableForm: true });
        })
        .catch(_ => {
          notification.error({
            message: translate('policeReport.edit.error'),
          });
          updateState({ isLoading: false, disableForm: false });
        });
    },

    setShowSendWarning: show => {
      updateState({ showSendWarning: show });
    },

    showFamilyRelationship: (birthDate) => {
      // return state.report[FIELDS.CHILDREN_KEY] > 0;
      return actions.getIsAdult(birthDate)
    },

    getIsAdult: (birthDate) => {
      let birthDateFormatted = moment(birthDate);
      const today = moment();
      return birthDateFormatted.isValid() && today.diff(birthDateFormatted, 'years') > ADULT_AGE;
    },

    updateGuestField: (guestIndex, key, value) => {
      const report = JSON.parse(JSON.stringify(state.report));
      const guest = report.guests[guestIndex];
      guest[key] = value;
      if (key === FIELDS.DOCUMENTSUPPORT_KEY) {
        guest.document_support_number = value;
        guest.supportDocument = value;
      } else if (key === FIELDS.CONTACTTYPE_KEY) {
        if (value == 'email') guest.phone = null;
        else if (value == 'telefono') guest.email = null;
        guest.contactValue = null;
      }

      // if (key === 'contactType') {
      //   if (value != initialReport?.guests?.[guestIndex]?.contactType) {
      //     guest.contactValue = '';
      //   } else {
      //     if (guest.contactValue === '') {
      //       guest.contactValue = initialReport?.guests?.[guestIndex]?.contactValue;
      //     }
      //   }
      // }

      updateState({ report });
    },

    updateContractField: (key, value) => {
      const report = JSON.parse(JSON.stringify(state.report));
      report.contract[key] = value;
      updateState({ report });
    },

    onBackClicked: () => {
      history.goBack();
    },
    disableSupportNumber: (docType) => {

      if(!docType) return false;
      if(docType === "dni" || docType === "residencePermit") return false;

      return true;

    }

  };


  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
